export default {
  computed: {},
  methods: {
    fetchAllExhibitions() {
      return new Promise((resolve, reject) => {
        const path = `/accrochages?extend=children`;
        console.log(`ROOT / fetchAllExhibitions • fetching = ${path}`);
        if (this.$root.is_debug) {
          // this.$toast(`fetching exhibitions`);
        }

        this.fetchPath({
          path,
        })
          .then((response) => {
            let _content = {};

            const _exhibitions = Object.values(
              response.children
            ).map((exhibition) => this.prepareExhibition({ exhibition }));

            if (response.data?.slug) _content.slug = response.data.slug;
            if (response.content?.page_title)
              _content.title = response.content.page_title.value;
            if (response.content?.page_subtitle)
              _content.subtitle = response.content.page_subtitle.value;
            if (response.content?.main_text)
              _content.text = response.content.main_text.blocksToHtml;

            return resolve({ content: _content, exhibitions: _exhibitions });
          })
          .catch((err) => {
            console.log(
              `ROOT / fetchAllExhibitions • fetching failed with error ${err}`
            );
            return reject(err);
          });
      });
    },
    fetchCurrentExhibition() {
      return new Promise((resolve, reject) => {
        const path = `/accrochages/current`;
        console.log(`ROOT / fetchCurrentExhibition • fetching = ${path}`);
        if (this.$root.is_debug) {
          // this.$toast(`fetching current exhibition`);
        }

        this.fetchPath({
          path,
        })
          .then((response) => {
            const ex = this.prepareExhibition({ exhibition: response });
            return resolve(ex);
          })
          .catch((err) => {
            console.log(
              `ROOT / fetchCurrentExhibition • fetching failed with error ${err}`
            );
            return reject(err);
          });
      });
    },
    fetchSelectionInfos() {
      return new Promise((resolve, reject) => {
        const path = `/accrochages?content=active_pastille,pastille_text,pastille_url,selection_start_date,selection_end_date`;
        console.log(`ROOT / fetchSelectionInfos • fetching = ${path}`);
        if (this.$root.is_debug) {
          // this.$toast(`fetching limit date`);
        }

        this.fetchPath({
          path,
        })
          .then((response) => {
            const obj = {};

            if (response.content?.active_pastille)
              obj.active_pastille =
                response.content.active_pastille.boolean === "true";
            if (response.content?.pastille_text)
              obj.pastille_text = response.content.pastille_text.value;
            if (response.content?.pastille_url)
              obj.pastille_url = response.content.pastille_url.value;
            if (response.content?.selection_start_date)
              obj.selection_start_date =
                response.content.selection_start_date.toDate * 1000;
            if (response.content?.selection_end_date)
              obj.selection_end_date =
                response.content.selection_end_date.toDate * 1000;

            if (response.csrf) this.$root.csrf = response.csrf;

            return resolve(obj);
          })
          .catch((err) => {
            console.log(
              `ROOT / fetchAllExhibitions • fetching failed with error ${err}`
            );
            return reject(err);
          });
      });
    },
    prepareExhibition({ exhibition }) {
      const _exhibition = {};
      _exhibition.ogData = exhibition.data;

      if (exhibition.content?.title)
        _exhibition.title = exhibition.content.title.value;
      if (exhibition.content?.short_description)
        _exhibition.short_description =
          exhibition.content.short_description.blocksToHtml;
      if (exhibition.data?.slug) _exhibition.slug = exhibition.data.slug;
      if (exhibition.content?.main_text)
        _exhibition.main_text = exhibition.content.main_text.blocksToHtml;
      if (exhibition.content?.starts)
        _exhibition.starts = exhibition.content.starts.toDate * 1000;
      if (exhibition.content?.ends)
        _exhibition.ends = exhibition.content.ends.toDate * 1000;
      if (
        exhibition.content?.cover &&
        exhibition.content?.cover.yaml.length > 0
      ) {
        _exhibition.cover_url =
          exhibition.data.url + "/" + exhibition.content.cover.yaml[0];
      }

      if (exhibition.content?.artworks)
        _exhibition.artworks = exhibition.content.artworks.yaml.map((a) =>
          a.replace("artworks/", "")
        );

      return _exhibition;
    },
  },
};
