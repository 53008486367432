<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100px"
    height="62px"
    viewBox="0 0 96.5 59.5"
    style="overflow:visible;enable-background:new 0 0 96.5 59.5;"
    xml:space="preserve"
    fill="currentColor"
  >
    <polygon
      class="st0"
      points="10.9,16 10.9,14.2 3.6,14.2 2.9,14.3 3.5,13.9 5.4,12.2 5.4,11 4.1,11 0,15.1 4.1,19.3 5.3,19.3 
	5.4,18 3.5,16.3 2.9,15.9 3.6,16 "
    />
    <polygon
      class="st0"
      points="0.9,40.1 0.9,41.9 8.2,41.8 8.9,41.7 8.3,42.2 6.3,43.9 6.4,45.1 7.6,45.1 11.8,41 7.7,36.8 6.4,36.8 
	6.4,38.1 8.2,39.8 8.9,40.2 8.2,40.1 "
    />
    <polygon
      class="st0"
      points="43.8,7.1 45.1,8.3 50.2,3.2 50.7,2.6 50.5,3.4 50.4,5.9 51.2,6.8 52.1,5.9 52.1,0 46.3,0 45.4,0.9 
	46.3,1.8 48.8,1.7 49.6,1.5 49,2 "
    />
    <polygon
      class="st0"
      points="18.2,21.8 16.5,22.6 16.2,23.2 16.6,24 18.2,23.3 19.8,24 20.2,23.2 19.9,22.6 "
    />
    <path
      class="st0"
      d="M29.2,27.1L29,27.4l-0.4,0.2h-1.3c0,0-0.1,0-0.1,0L26.5,28v-0.7l0.5-1.1l0.6-0.2h0.7l0.4,0.2L29.2,27.1
	L29.2,27.1z M30,25.3c-0.1-0.2-0.2-0.3-0.4-0.4l-0.8-0.3c-0.1,0-0.2-0.1-0.4-0.1h-0.8c-0.1,0-0.2,0-0.3,0l-1,0.3
	c-0.3,0.1-0.5,0.2-0.6,0.5L25,26.8c-0.1,0.1-0.1,0.3-0.1,0.4v2c0,0.1,0,0.2,0.1,0.4l0.7,1.5c0.1,0.2,0.3,0.4,0.5,0.5l0.8,0.3
	c0.1,0,0.2,0.1,0.3,0.1h1.6c0.1,0,0.2,0,0.2,0l0.2-0.1l1.3-0.8L30,29.9l-1.3,0.6h-1.1l-0.5-0.2l-0.4-0.8v-0.1l0.7-0.3h1.5
	c0.2,0,0.3,0,0.5-0.1l0.9-0.6c0.1-0.1,0.2-0.1,0.2-0.2l0.3-0.5c0.1-0.1,0.2-0.3,0.2-0.4V27c0-0.1,0-0.2-0.1-0.4L30,25.3z"
    />
    <path
      class="st0"
      d="M49.3,27.1l-0.2,0.3l-0.4,0.2h-1.3c0,0-0.1,0-0.1,0l-0.7,0.3v-0.7l0.5-1.1l0.6-0.2h0.7l0.4,0.2l0.4,0.9
	L49.3,27.1z M50.2,25.3c-0.1-0.2-0.2-0.3-0.4-0.4L49,24.6c-0.1,0-0.2-0.1-0.4-0.1h-0.8c-0.1,0-0.2,0-0.3,0l-1,0.3
	c-0.3,0.1-0.5,0.2-0.6,0.5l-0.8,1.5C45,27,45,27.1,45,27.2v2c0,0.1,0,0.2,0.1,0.4l0.7,1.5c0.1,0.2,0.3,0.4,0.5,0.5l0.8,0.3
	c0.1,0,0.2,0.1,0.3,0.1H49c0.1,0,0.2,0,0.2,0l0.2-0.1l1.3-0.8l-0.6-1.2l-1.3,0.6h-1.1l-0.5-0.2l-0.4-0.8v-0.1l0.7-0.3H49
	c0.2,0,0.3,0,0.5-0.1l0.9-0.6c0.1-0.1,0.2-0.1,0.2-0.2l0.3-0.5c0.1-0.1,0.2-0.3,0.2-0.4V27c0-0.1,0-0.2-0.1-0.4L50.2,25.3z"
    />
    <path
      class="st0"
      d="M41.4,30l-0.9,0.4h-0.8l-0.3-0.1l-0.5-1v-2.1l0.4-0.9l0.3-0.1h1l0.8,0.4L41.4,30z M41.4,24.9l-0.5-0.3
	c-0.1-0.1-0.1-0.1-0.2-0.1h-1.2c-0.1,0-0.2,0-0.3,0l-0.8,0.3c-0.3,0.1-0.5,0.2-0.6,0.5l-0.7,1.5c-0.1,0.1-0.1,0.3-0.1,0.4v2
	c0,0.1,0,0.3,0.1,0.4l0.8,1.5c0.1,0.2,0.3,0.4,0.5,0.4l0.8,0.3c0.1,0,0.2,0.1,0.4,0h1c0.1,0,0.3,0,0.4-0.1l0.8-0.5l0.2,0.4h1.3V21.6
	h-1.9L41.4,24.9L41.4,24.9z"
    />
    <rect x="21.4" y="21.7" class="st0" width="1.8" height="10.2" />
    <path
      class="st0"
      d="M94.8,27.1l-0.2,0.3l-0.4,0.2h-1.3c0,0-0.1,0-0.1,0l-0.7,0.3v-0.7l0.5-1.1l0.6-0.2h0.7l0.4,0.2l0.4,0.8V27.1z
	 M96.3,26.5l-0.7-1.2c-0.1-0.2-0.2-0.3-0.4-0.4l-0.8-0.3c-0.1,0-0.2-0.1-0.4-0.1h-0.8c-0.1,0-0.2,0-0.3,0l-1,0.3
	c-0.3,0.1-0.5,0.2-0.6,0.5l-0.7,1.5c-0.1,0.1-0.1,0.3-0.1,0.4v2c0,0.1,0,0.2,0.1,0.4l0.7,1.5c0.1,0.2,0.3,0.4,0.5,0.5l0.8,0.3
	c0.1,0,0.2,0.1,0.3,0.1h1.6c0.1,0,0.2,0,0.2,0l0.2-0.1l1.3-0.8l-0.6-1.2l-1.3,0.6h-1.1l-0.5-0.2l-0.4-0.8v-0.1l0.7-0.3h1.5
	c0.2,0,0.3,0,0.5-0.1l0.9-0.6c0.1-0.1,0.2-0.1,0.2-0.2l0.3-0.5c0.1-0.1,0.2-0.3,0.2-0.4V27C96.5,26.8,96.4,26.7,96.3,26.5"
    />
    <rect x="17.2" y="24.6" class="st0" width="1.9" height="7.3" />
    <path
      class="st0"
      d="M66.5,24.5h-0.8c-0.1,0-0.3,0-0.4,0.1l-0.5,0.4l0,0c-0.1,0.1-0.2,0.1-0.3,0.2l-0.2,0.2l-0.1-0.8h-1.5v7.3h1.8
	v-4.6l0.4-0.8l0.8-0.4h0.4l0.8,0.4l0.8-1.2L67,24.6C66.9,24.5,66.7,24.5,66.5,24.5"
    />
    <path
      class="st0"
      d="M87.4,30.4h-1L86,30.2l-0.5-1.1v-1.8l0.5-1l0.4-0.2h0.9l1.1,0.4h0l0.7-1.2L88,24.6c-0.1-0.1-0.3-0.1-0.4-0.1
	h-1.5c-0.2,0-0.3,0-0.3,0l-0.8,0.4c-0.2,0.1-0.4,0.3-0.5,0.5l-0.8,1.4c-0.1,0.1-0.1,0.3-0.1,0.4v1.9c0,0.1,0,0.3,0.1,0.4l0.8,1.5
	c0.1,0.2,0.3,0.4,0.5,0.5l0.9,0.4c0,0,0.1,0,0.2,0h1.4c0.2,0,0.4-0.1,0.5-0.1l1.4-0.8l-0.7-1.3L87.4,30.4z"
    />
    <path
      class="st0"
      d="M81.6,25.7c-0.1-0.2-0.2-0.4-0.4-0.5l-1-0.6c-0.1-0.1-0.3-0.1-0.4-0.1h-0.8c-0.1,0-0.3,0-0.4,0.1L77.9,25l0,0
	c0,0-0.1,0-0.1,0.1l-0.2,0.2l-0.1-0.8h-1.6v7.3h1.8v-4.6l0.4-0.8l0.8-0.4h0.5l0.6,0.4l0.2,0.6v4.8h1.8v-4.7c0-0.2,0-0.3-0.1-0.4
	L81.6,25.7z"
    />
    <path
      class="st0"
      d="M72.4,30.3l-0.5,0.3h-1.6L70,30.1v-0.3l0.2-0.4l0.8-0.5H72c0.1,0,0.2,0,0.2,0l0.1-0.1L72.4,30.3L72.4,30.3z
	 M73.8,26.1l-0.5-0.9v0c-0.1-0.1-0.1-0.2-0.3-0.3l-0.8-0.3c-0.2-0.1-0.4-0.1-0.6-0.1h-1.3c-0.2,0-0.3,0-0.5,0.1l-1.1,0.7l0.7,1.2
	l1-0.5h1.2l0.4,0.2l0.3,0.5v0.6l-0.3,0.2h-1.4c-0.2,0-0.3,0-0.4,0.1l-1,0.6c-0.1,0-0.2,0.1-0.3,0.2l-0.4,0.8
	c-0.1,0.2-0.1,0.3-0.1,0.5v0.4c0,0.2,0.1,0.4,0.2,0.6l0.4,0.8c0.1,0.2,0.2,0.2,0.4,0.3l0.5,0.1l0,0c0.1,0,0.2,0,0.3,0h1.2
	c0.2,0,0.4,0,0.5-0.1l0.7-0.4l0.2,0.4H74v-5.2C74,26.5,73.9,26.3,73.8,26.1L73.8,26.1"
    />
    <path
      class="st0"
      d="M60.6,21.3h-0.8c-0.1,0-0.2,0-0.3,0l-0.7,0.3c-0.2,0.1-0.4,0.2-0.5,0.4l-0.6,1.2c-0.1,0.1-0.1,0.3-0.1,0.4v0.8
	h-0.9v1.6h0.9v5.6h1.8v-5.6h2.1v-1.6h-2.1v-0.8l0.3-0.5l0.3-0.2h0.5l1.1,0.4l0.7-1.4l-1.3-0.6C60.8,21.4,60.7,21.3,60.6,21.3"
    />
    <path
      class="st0"
      d="M26.6,11.2h-0.8c-0.1,0-0.3,0-0.4,0.1l-0.5,0.4l0,0c-0.1,0.1-0.2,0.1-0.3,0.2l-0.2,0.2l-0.2-0.8h-1.5v7.3h1.8
	v-4.6l0.4-0.8l0.8-0.4h0.4l0.8,0.4l0.8-1.2L27,11.3C26.9,11.2,26.7,11.2,26.6,11.2"
    />
    <path
      class="st0"
      d="M20.6,8h-0.8c-0.1,0-0.2,0-0.3,0l-0.7,0.3c-0.2,0.1-0.4,0.2-0.5,0.4L17.6,10c-0.1,0.1-0.1,0.3-0.1,0.4v0.8h-0.9
	v1.6h0.9v5.6h1.8v-5.6h2.1v-1.6h-2.1v-0.8l0.3-0.5l0.3-0.2h0.5l1.1,0.4l0.7-1.4l-1.3-0.6C20.8,8.1,20.7,8,20.6,8"
    />
    <path
      class="st0"
      d="M32.4,17l-0.5,0.3h-1.6L30,16.8v-0.3l0.2-0.4l0.8-0.5H32c0.1,0,0.2,0,0.2,0l0.1-0.1L32.4,17z M33.8,12.8
	l-0.5-0.9c-0.1-0.1-0.1-0.2-0.3-0.3l-0.8-0.3c-0.2-0.1-0.4-0.1-0.6-0.1h-1.3c-0.2,0-0.3,0-0.5,0.1l-1.1,0.7l0.7,1.2l1-0.5h1.2
	l0.4,0.2l0.3,0.5v0.6l-0.3,0.2h-1.4c-0.2,0-0.3,0-0.4,0.1l-1,0.6c-0.1,0-0.2,0.1-0.3,0.2L28.6,16c-0.1,0.2-0.1,0.3-0.1,0.5v0.4
	c0,0.2,0.1,0.4,0.2,0.6l0.4,0.8c0.1,0.2,0.2,0.2,0.4,0.3l0.5,0.1l0,0c0.1,0,0.2,0,0.3,0h1.2c0.2,0,0.4,0,0.5-0.1l0.7-0.4l0.2,0.4H34
	v-5.2C34,13.2,34,13,33.8,12.8C33.8,12.8,33.8,12.8,33.8,12.8C33.8,12.8,33.8,12.8,33.8,12.8"
    />
    <path
      class="st0"
      d="M40.8,16.5l-1.3,0.6h-1l-0.4-0.2l-0.5-1.1V14l0.5-1l0.4-0.2h0.9l1.1,0.4h0l0.7-1.2l-1.2-0.6
	c-0.1-0.1-0.3-0.1-0.4-0.1h-1.5c-0.2,0-0.3,0-0.3,0l-0.8,0.4c-0.2,0.1-0.4,0.3-0.5,0.5l-0.8,1.4c-0.1,0.1-0.1,0.3-0.1,0.4v1.9
	c0,0.1,0,0.3,0.1,0.4l0.8,1.5c0.1,0.2,0.3,0.4,0.5,0.5l0.9,0.4c0,0,0.1,0,0.2,0h1.4c0.2,0,0.4-0.1,0.5-0.1l1.4-0.7h0L40.8,16.5z"
    />
    <rect x="31.8" y="27.6" class="st0" width="3.9" height="1.4" />
    <rect x="51.9" y="27.6" class="st0" width="3.9" height="1.4" />
    <path d="M17.5,36.2h1.1v10.1h-1.1V36.2z" />
    <path
      d="M24.9,42.6c0,0.1-0.1,0.1-0.1,0.2l-0.6,0.4c-0.1,0-0.2,0.1-0.3,0.1h-1.7l-1.1,0.5v0.2l0.6,1.2l0.8,0.4h1.1l1.1-0.5l0.4,0.8
	l-1.1,0.6c-0.1,0-0.1,0-0.2,0h-1.4c-0.1,0-0.1,0-0.2,0L21.2,46c-0.1-0.1-0.2-0.1-0.3-0.3l-0.7-1.5c0-0.1-0.1-0.2-0.1-0.3v-2.4
	c0-0.1,0-0.2,0.1-0.3l0.7-1.5c0.1-0.1,0.1-0.2,0.3-0.3l1-0.4c0.2-0.1,0.2-0.1,0.3-0.1h0.8c0.2,0,0.3,0,0.4,0.1l0.7,0.3
	c0,0,0.2,0.1,0.3,0.2l0.8,1.5c0,0.1,0,0.1,0,0.3v0.2c0,0.1,0,0.1,0,0.2L24.9,42.6z M21.1,42.6l0.7-0.3c0.1,0,0.2-0.1,0.3-0.1h1.6
	l0.4-0.3l0.2-0.3v-0.2l-0.5-1l-0.6-0.3h-0.7l-0.8,0.3l-0.6,1.2V42.6z"
    />
    <path
      d="M27,44.9l1.2,0.5h0.7l0.6-0.3l0.4-0.8v-0.5l-0.3-0.5l-0.5-0.2h-0.8c-0.1,0-0.2,0-0.3-0.1l-0.8-0.3c-0.1,0-0.2-0.1-0.3-0.3
	l-0.4-0.9c0-0.1-0.1-0.2-0.1-0.3v-0.6c0-0.1,0-0.2,0.1-0.3l0.4-0.8c0.1-0.1,0.1-0.2,0.3-0.3l0.9-0.4c0.1,0,0.1-0.1,0.2-0.1h0.9
	c0.1,0,0.1,0,0.2,0l1.2,0.7l-0.4,0.8L29.1,40h-0.6l-0.6,0.3l-0.3,0.6v0.5l0.3,0.6l0.5,0.2h0.8c0.2,0,0.2,0,0.3,0.1l0.7,0.3
	c0.1,0,0.2,0.1,0.3,0.3l0.4,0.8c0,0.1,0.1,0.2,0.1,0.3v0.6c0,0.1,0,0.2-0.1,0.3l-0.5,1c-0.1,0.1-0.1,0.2-0.3,0.3l-0.9,0.4
	c-0.1,0-0.1,0.1-0.2,0.1h-0.8c-0.1,0-0.1,0-0.2,0l-1.4-0.7L27,44.9z"
    />
    <path
      d="M37,39.1c0.2-0.1,0.3-0.1,0.4-0.1h0.5c0.2,0,0.3,0,0.4,0.1l0.7,0.6l-0.5,0.7l-0.7-0.4h-0.5l-1.1,0.5l-0.4,0.7v5h-1.1v-7.1
	h0.8l0.2,0.9l0.2-0.3c0.1-0.1,0.1-0.2,0.3-0.3L37,39.1z"
    />
    <path
      d="M44.2,42.6c0,0.1-0.1,0.1-0.1,0.2l-0.6,0.4c-0.1,0-0.2,0.1-0.3,0.1h-1.7l-1.1,0.5v0.2L41,45l0.8,0.4h1.1l1.1-0.5l0.4,0.8
	l-1.1,0.6c-0.1,0-0.1,0-0.2,0h-1.4c-0.1,0-0.1,0-0.2,0L40.5,46c-0.1-0.1-0.2-0.1-0.3-0.3l-0.7-1.5c0-0.1-0.1-0.2-0.1-0.3v-2.4
	c0-0.1,0-0.2,0.1-0.3l0.7-1.5c0.1-0.1,0.1-0.2,0.3-0.3l1-0.4c0.2-0.1,0.2-0.1,0.3-0.1h0.8c0.2,0,0.3,0,0.4,0.1l0.7,0.3
	c0,0,0.2,0.1,0.3,0.2l0.8,1.5c0,0.1,0,0.1,0,0.3v0.2c0,0.1,0,0.1,0,0.2L44.2,42.6z M40.4,42.6l0.7-0.3c0.1,0,0.2-0.1,0.3-0.1H43
	l0.4-0.3l0.2-0.3v-0.2l-0.5-1l-0.6-0.3h-0.7L41,40.4l-0.6,1.2V42.6z M41,37.7l0.3-0.6l2.1-0.8l0.3,0.6l-0.3,0.6l-2.1,0.8L41,37.7z"
    />
    <path
      d="M46.3,44.9l1.2,0.5h0.7l0.6-0.3l0.4-0.8v-0.5L49,43.4l-0.5-0.2h-0.8c-0.1,0-0.2,0-0.3-0.1l-0.8-0.3c-0.1,0-0.2-0.1-0.3-0.3
	l-0.4-0.9c0-0.1-0.1-0.2-0.1-0.3v-0.6c0-0.1,0-0.2,0.1-0.3l0.4-0.8c0.1-0.1,0.1-0.2,0.3-0.3l0.9-0.4c0.1,0,0.1-0.1,0.2-0.1h0.9
	c0.1,0,0.1,0,0.2,0l1.2,0.7l-0.4,0.8L48.5,40h-0.6l-0.6,0.3l-0.3,0.6v0.5l0.3,0.6l0.5,0.2h0.8c0.2,0,0.2,0,0.3,0.1l0.7,0.3
	c0.1,0,0.2,0.1,0.3,0.3l0.4,0.8c0,0.1,0.1,0.2,0.1,0.3v0.6c0,0.1,0,0.2-0.1,0.3l-0.5,1c-0.1,0.1-0.1,0.2-0.3,0.3l-0.9,0.4
	c-0.1,0-0.1,0.1-0.2,0.1h-0.8c-0.1,0-0.1,0-0.2,0l-1.4-0.7L46.3,44.9z"
    />
    <path
      d="M56.4,42.6c0,0.1-0.1,0.1-0.1,0.2l-0.6,0.4c-0.1,0-0.2,0.1-0.3,0.1h-1.7l-1.1,0.5v0.2l0.6,1.2l0.8,0.4h1.1l1.1-0.5l0.4,0.8
	l-1.1,0.6c-0.1,0-0.1,0-0.2,0h-1.4c-0.1,0-0.1,0-0.2,0L52.7,46c-0.1-0.1-0.2-0.1-0.3-0.3l-0.7-1.5c0-0.1-0.1-0.2-0.1-0.3v-2.4
	c0-0.1,0-0.2,0.1-0.3l0.7-1.5c0.1-0.1,0.1-0.2,0.3-0.3l1-0.4C53.8,39,53.9,39,54,39h0.8c0.2,0,0.3,0,0.4,0.1l0.7,0.3
	c0,0,0.2,0.1,0.3,0.2l0.8,1.5c0,0.1,0,0.1,0,0.3v0.2c0,0.1,0,0.1,0,0.2L56.4,42.6z M52.6,42.6l0.7-0.3c0.1,0,0.2-0.1,0.3-0.1h1.6
	l0.4-0.3l0.2-0.3v-0.2l-0.5-1l-0.6-0.3H54l-0.8,0.3l-0.6,1.2V42.6z"
    />
    <path
      d="M60.4,39.1c0.2-0.1,0.3-0.1,0.4-0.1h0.5c0.2,0,0.3,0,0.4,0.1l0.7,0.6L62,40.4l-0.7-0.4h-0.5l-1.1,0.5l-0.4,0.7v5h-1.1v-7.1
	H59l0.2,0.9l0.2-0.3c0.1-0.1,0.1-0.2,0.3-0.3L60.4,39.1z"
    />
    <path d="M65.9,45.4l2-6.2H69l-2.4,7.1h-1.4l-2.4-7.1h1.1L65.9,45.4z" />
    <path
      d="M74.4,42.6c0,0.1-0.1,0.1-0.1,0.2l-0.6,0.4c-0.1,0-0.2,0.1-0.3,0.1h-1.7l-1.1,0.5v0.2l0.6,1.2l0.8,0.4h1.1l1.1-0.5l0.4,0.8
	l-1.1,0.6c-0.1,0-0.1,0-0.2,0h-1.4c-0.1,0-0.1,0-0.2,0L70.7,46c-0.1-0.1-0.2-0.1-0.3-0.3l-0.7-1.5c0-0.1-0.1-0.2-0.1-0.3v-2.4
	c0-0.1,0-0.2,0.1-0.3l0.7-1.5c0.1-0.1,0.1-0.2,0.3-0.3l1-0.4C71.8,39,71.9,39,72,39h0.8c0.2,0,0.3,0,0.4,0.1l0.7,0.3
	c0,0,0.2,0.1,0.3,0.2l0.8,1.5c0,0.1,0,0.1,0,0.3v0.2c0,0.1,0,0.1,0,0.2L74.4,42.6z M70.7,42.6l0.7-0.3c0.1,0,0.2-0.1,0.3-0.1h1.6
	l0.4-0.3l0.2-0.3v-0.2l-0.5-1l-0.6-0.3H72l-0.8,0.3l-0.6,1.2V42.6z"
    />
    <path
      d="M76.5,44.9l1.2,0.5h0.7l0.6-0.3l0.4-0.8v-0.5l-0.3-0.5l-0.5-0.2h-0.8c-0.1,0-0.2,0-0.3-0.1l-0.8-0.3c-0.1,0-0.2-0.1-0.3-0.3
	l-0.4-0.9c0-0.1-0.1-0.2-0.1-0.3v-0.6c0-0.1,0-0.2,0.1-0.3l0.4-0.8c0.1-0.1,0.1-0.2,0.3-0.3l0.9-0.4c0.1,0,0.1-0.1,0.2-0.1h0.9
	c0.1,0,0.1,0,0.2,0l1.2,0.7l-0.4,0.8L78.7,40H78l-0.6,0.3l-0.3,0.6v0.5l0.3,0.6l0.5,0.2h0.8c0.2,0,0.2,0,0.3,0.1l0.7,0.3
	c0.1,0,0.2,0.1,0.3,0.3l0.4,0.8c0,0.1,0.1,0.2,0.1,0.3v0.6c0,0.1,0,0.2-0.1,0.3l-0.5,1c-0.1,0.1-0.1,0.2-0.3,0.3l-0.9,0.4
	c-0.1,0-0.1,0.1-0.2,0.1h-0.8c-0.1,0-0.1,0-0.2,0l-1.4-0.7L76.5,44.9z"
    />
    <path
      d="M19.7,52.2c0.2-0.1,0.3-0.1,0.4-0.1h0.5c0.2,0,0.3,0,0.4,0.1l0.7,0.6l-0.5,0.7l-0.7-0.4H20l-1.1,0.5l-0.4,0.7v5h-1.1v-7.1
	h0.8l0.2,0.9l0.2-0.3c0.1-0.1,0.1-0.2,0.3-0.3L19.7,52.2z"
    />
    <path
      d="M27.6,54.3c0,0.1,0.1,0.2,0.1,0.3v2.5c0,0.1,0,0.2-0.1,0.3l-0.7,1.5c0,0.1-0.1,0.2-0.3,0.3l-0.9,0.4c-0.1,0-0.1,0-0.2,0
	h-1.1c-0.1,0-0.1,0-0.2-0.1l-0.9-0.4c-0.1,0-0.2-0.1-0.3-0.3l-0.7-1.5c0-0.1-0.1-0.1-0.1-0.3v-2.5c0-0.1,0-0.2,0.1-0.3l0.8-1.5
	c0.1-0.1,0.2-0.2,0.3-0.3l0.9-0.4c0.1,0,0.1,0,0.2,0h1.1c0.1,0,0.1,0,0.2,0l0.9,0.4c0.1,0,0.2,0.1,0.3,0.3L27.6,54.3z M26.6,57v-2.4
	l-0.6-1.2l-0.6-0.2h-0.9l-0.6,0.2l-0.6,1.2V57l0.6,1.2l0.6,0.3h0.9l0.6-0.3L26.6,57z"
    />
    <path
      d="M33.9,52.7l0.7-0.5c0.1-0.1,0.2-0.1,0.4-0.1h0.6c0.2,0,0.3,0,0.4,0.1l0.7,0.4c0.2,0.2,0.3,0.2,0.4,0.4l0.3,0.8
	c0,0.1,0,0.1,0,0.2l0,5.2h-1.1v-5.3l-0.2-0.5l-0.6-0.4H35l-0.8,0.4L34,54.1v5.3h-1.1v-5.3l-0.2-0.6l-0.4-0.3h-0.9l-0.6,0.3l-0.5,1.1
	v4.8h-1.1v-7.1H30l0.2,0.9l0.2-0.4c0-0.1,0.2-0.2,0.3-0.3l0.4-0.3c0.2-0.1,0.2-0.1,0.4-0.1h0.6c0.1,0,0.3,0,0.4,0.1l0.8,0.4
	c0.2,0.1,0.3,0.3,0.3,0.4C33.7,53.1,33.8,52.8,33.9,52.7z"
    />
    <path
      d="M42.2,55.3l0.7-0.3v-0.7l-0.4-0.9l-0.6-0.2h-1l-1.1,0.5l-0.4-0.8l1.2-0.7c0.1,0,0.1,0,0.2,0h1.3c0.1,0,0.1,0,0.2,0l0.9,0.4
	c0.1,0,0.2,0.1,0.3,0.3l0.6,1.1c0.1,0.1,0.1,0.2,0.1,0.3v5.1h-0.7l-0.1-0.5l-1,0.5c-0.1,0-0.1,0.1-0.2,0.1h-1.4c-0.1,0-0.2,0-0.3,0
	l-0.6-0.2c0,0-0.2-0.1-0.2-0.3l-0.6-1.2c0-0.1,0-0.1,0-0.3v-0.2c0-0.1,0-0.1,0-0.2l0.6-1.1c0-0.1,0.1-0.1,0.2-0.2l1-0.5
	c0.1,0,0.2-0.1,0.3-0.1H42.2z M41,56.3l-0.8,0.4l-0.4,0.8v0.1l0.4,0.8l0.2,0.1h1.4l1-0.4l0-2l-0.5,0.3c-0.1,0-0.2,0-0.2,0H41z"
    />
    <path
      d="M45.6,50.5c0-0.5,0.3-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8C46,51.4,45.6,51,45.6,50.5z M45.9,52.3H47v7.1
	h-1.1V52.3z"
    />
    <path
      d="M51.2,52.2c0.1,0,0.3-0.1,0.4-0.1h0.6c0.2,0,0.3,0,0.4,0.1l0.9,0.5c0.2,0.2,0.3,0.3,0.4,0.5l0.3,1.1c0,0.1,0,0.1,0,0.2v4.7
	h-1.1v-4.9l-0.3-0.9l-0.6-0.4h-0.6l-1,0.4l-0.5,1.1v4.8H49v-7.1h0.9l0.2,0.8l0.2-0.3c0.1-0.1,0.1-0.1,0.3-0.3L51.2,52.2z"
    />
    <path d="M58,58.5l2-6.2h1.1l-2.4,7.1h-1.4l-2.4-7.1h1.1L58,58.5z" />
    <path
      d="M62.1,50.5c0-0.5,0.3-0.8,0.8-0.8s0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8S62.1,51,62.1,50.5z M62.4,52.3h1.1v7.1h-1.1V52.3z"
    />
    <path d="M65.5,49.3h1.1v10.1h-1.1V49.3z" />
    <path d="M68.3,49.3h1.1v10.1h-1.1V49.3z" />
    <path
      d="M75.7,55.7c0,0.1-0.1,0.1-0.1,0.2L75,56.2c-0.1,0-0.2,0.1-0.3,0.1h-1.7L72,56.8V57l0.6,1.2l0.8,0.4h1.1l1.1-0.5l0.4,0.8
	l-1.1,0.6c-0.1,0-0.1,0-0.2,0h-1.4c-0.1,0-0.1,0-0.2,0L72,59.1c-0.1-0.1-0.2-0.1-0.3-0.3L71,57.3c0-0.1-0.1-0.2-0.1-0.3v-2.4
	c0-0.1,0-0.2,0.1-0.3l0.7-1.5c0.1-0.1,0.1-0.2,0.3-0.3l1-0.4c0.2-0.1,0.2-0.1,0.3-0.1h0.8c0.2,0,0.3,0,0.4,0.1l0.7,0.3
	c0,0,0.2,0.1,0.3,0.2l0.8,1.5c0,0.1,0,0.1,0,0.3v0.2c0,0.1,0,0.1,0,0.2L75.7,55.7z M72,55.7l0.7-0.3c0.1,0,0.2-0.1,0.3-0.1h1.6
	L75,55l0.2-0.3v-0.2l-0.5-1L74,53.2h-0.7l-0.8,0.3L72,54.7V55.7z"
    />
  </svg>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
