<template>
  <div class="m_allArtists" :class="{}">
    <!-- <pre
      >{{ $root.all_artists[0] }}
    </pre> -->
    <div class="titleBar">
      <div>
        <router-link to="/explorer">liste des artistes</router-link>
      </div>
    </div>

    <template v-if="is_loading">
      <div class="_loader" key="loader">
        Chargement…
      </div>
      <!-- <div class="_loaderPC">{{ loading_percent }}%</div> -->
    </template>
    <div class="_sideBySide" v-else>
      <section class="m_allArtists--letter" ref="letterScroll">
        <button
          type="button"
          v-for="ltr in alphabet"
          :key="ltr"
          :class="{
            'is--active': ltr === active_letter,
          }"
          :disabled="!getArtistsByLetter(ltr).length === 0"
          @click="active_letter = ltr"
          :ref="`letter-${ltr}`"
        >
          {{ ltr }}
          <sup v-if="getArtistsByLetter(ltr)" class="_artistCount">
            {{ getArtistsByLetter(ltr).length }}
          </sup>
        </button>
      </section>
      <transition-group
        name="slide-in-fast"
        tag="section"
        mode="out-in"
        class="m_allArtists--list"
        :style="{ '--total': filtered_artists.length }"
      >
        <small
          v-if="!filtered_artists || filtered_artists.length === 0"
          :key="`noArtist-${active_letter}`"
        >
          Aucun artiste ne correspond à cette lettre.
        </small>
        <div
          v-for="(artist, index) in filtered_artists"
          :key="artist.slug"
          :style="{ '--i': index }"
        >
          <router-link
            :to="`/artiste/${artist.slug}`"
            :key="artist.slug"
            :title="artist.name_list"
          >
            {{ artist.name_list }}
            <!-- {{ artist.name }} -->
          </router-link>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      is_loading: false,

      alphabet: "abcdefghijklmnopqrstuvwxyz",
      active_letter: "a",

      loading_percent: 0,
    };
  },
  created() {
    if (this.$root.all_artists.length === 0) {
      this.is_loading = true;

      this.fetchAllArtists()
        .then(({ all_artists }) => {
          this.$root.all_artists = all_artists;
          // .sort((a, b) => {
          //   return a.name_list.localeCompare(b.name_list);
          // });
          return;
        })
        .catch((err) => {
          err;
        })
        .then(() => {
          this.loading_percent = 100;
          this.is_loading = false;
          this.$nextTick(() => {
            this.scrollToLtr(this.active_letter);
          });
        });
    } else {
      this.$nextTick(() => {
        this.scrollToLtr(this.active_letter);
      });
    }

    const increment_progress = () => {
      const inc = 1;
      if (this.loading_percent + inc < 100) {
        this.loading_percent += inc;
        const nextInc = this.loading_percent * 2;
        setTimeout(increment_progress, nextInc);
      }
    };
    increment_progress();
  },
  mounted() {},
  beforeDestroy() {},
  watch: {
    active_letter: {
      handler() {
        if (
          this.active_letter &&
          this.$route.query?.lettre !== this.active_letter
        ) {
          this.$router.push({
            query: {
              mode: "artistes",
              lettre: this.active_letter,
            },
          });
          this.scrollToLtr(this.active_letter);
        }
      },
    },
    $route: {
      handler() {
        if (this.$route.query?.lettre)
          if (
            this.alphabet.includes(this.$route.query.lettre) &&
            this.active_letter !== this.$route.query.lettre
          )
            this.active_letter = this.$route.query.lettre;
      },
      immediate: true,
    },
  },
  computed: {
    filtered_artists() {
      return this.getArtistsByLetter(this.active_letter);
    },
  },
  methods: {
    getArtistsByLetter(ltr) {
      return this.$root.all_artists.filter(
        (a) => a.name_list && a.name_list.toLowerCase().startsWith(ltr)
      );
    },
    scrollToLtr(ltr) {
      const $ltr = this.$refs[`letter-${ltr}`];
      if ($ltr) {
        const ot = $ltr[0].offsetTop;
        const oh = $ltr[0].offsetHeight;
        const scroll_window_height = this.$refs.letterScroll.offsetHeight;

        this.$refs.letterScroll.scrollTo(
          0,
          ot + oh / 2 - scroll_window_height / 2
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.m_allArtists {
  overflow: hidden;
  min-height: calc(100vh - 70px);

  &.is--mobile {
  }
}

._sideBySide {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  height: calc(100vh - 70px);
  gap: calc(var(--spacing) * 2);

  #app.is--narrow & {
    height: auto;
    gap: calc(var(--spacing));
  }

  > * {
    // flex: 1 1 0%;
  }

  .m_allArtists--letter {
    flex: 0 1 320px;
    text-align: center;
    scroll-behavior: smooth;

    #app.is--narrow & {
      position: sticky;
      top: 0;
      flex: 240px;
      height: calc(100vh - 70px);
    }

    > * {
    }

    ._artistCount {
      display: inline-block;
      font-size: var(--fs-small);
      font-size: 1rem;
    }
    > * {
    }
  }
}

.m_allArtists--letter {
  font-size: 25vmin;
  line-height: 0.9;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  // justify-content: center;
  overflow: auto;
  background: var(--gray-background);

  > * {
    flex: 0 0 auto;
    background: transparent;
    line-height: 1;
    // color: inherit;
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

    &:hover,
    &:focus-visible,
    &:active {
      &:not([disabled]) {
        background-color: transparent;
        &.is--active {
          // background-color: var(--dark-gray);
        }
      }
    }

    &.is--active {
      color: var(--active-color);
      // -webkit-text-stroke: 2px #000;
    }
  }
}

.m_allArtists--list {
  flex: 1 1 auto;
  // columns: 20ch auto;
  // column-gap: calc(var(--spacing) * 2);
  // column-rule: 2px dotted var(--text-gray);
  // column-fill: auto;
  font-size: var(--fs-larger);
  line-height: 1.21;

  // width: 100%;

  // padding: calc(var(--spacing) * 4) calc(var(--spacing) * 2);
  padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4)
    calc(var(--spacing) * 4) calc(var(--spacing) * 2);
  // padding-bottom: calc(var(--spacing) * 2);
  // flex: 1 0 160px;
  overflow: auto;

  #app.is--narrow & {
    // columns: unset;
    padding: calc(var(--spacing) * 1) 0;
  }

  a {
    // text-decoration: none;
  }
}

._loaderPC {
  text-align: center;
}
</style>
