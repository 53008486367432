<template>
  <div class="m_userPage">
    <div>
      <h1 class="sectionTitle" :title="$root.user_login.id">
        {{ $root.user_login.name }}
      </h1>

      <div class="_buttonRow">
        <button type="button" @click="logout">
          Se déconnecter
        </button>
        <button
          type="button"
          :class="{
            'is--active': export_user_notice,
          }"
          @click="export_user_notice = !export_user_notice"
        >
          Exporter mes données
        </button>
        <button
          type="button"
          :class="{
            'is--active': sure_remove_user,
          }"
          @click="sure_remove_user = !sure_remove_user"
        >
          Supprimer mon compte
        </button>
        <router-link
          to="/suivi"
          class="button greenBtn"
          v-if="
            $root.user_login.email &&
              $root.user_login.email.includes('@fraciledefrance.com')
          "
        >
          Suivi de la participation</router-link
        >

        <span v-if="export_user_notice" class="_confirmNotice">
          Contactez-nous sur
          <a href="mailto:reserves@fraciledefrance.com">
            reserves@fraciledefrance.com
          </a>
          pour obtenir une copie des données stockées par le site (votre adresse
          mail, nom ou pseudo, l’œuvre choisie s’il y en a une et vos albums).
        </span>

        <span v-if="sure_remove_user" class="_confirmNotice">
          Es-tu certain de vouloir supprimer ton compte ? Toutes tes
          informations seront supprimées, à l’exception des albums. Pour
          supprimer les albums,
          <button type="button" @click="removeUser">
            Supprimer mon compte
          </button>
        </span>
      </div>

      <!-- <template v-if="$root.user_login.content.verified === false">
        <div class="_notice t-warning">
          Attention ! Ton compte doit être validé en cliquant sur le lien
          indiqué dans le mail qui a été envoyé lors de sa création pour que ton
          choix soit pris en compte.<br />Si tu n’as pas recu ce mail, merci de
          nous contacter pour résoudre ce soucis
          <a href="mailto:reserves@fraciledefrance.com">
            reserves@fraciledefrance.com</a
          >.
        </div>
      </template> -->
    </div>
    <div class="sectionSeparator" />
    <div class="">
      <h3 class="">
        Inscription à la newsletter
      </h3>
      <div class="mainText">
        <a href="https://app.mailjet.com/widget/iframe/4nvh/iUT" target="_blank"
          >Inscris-toi</a
        >
        pour être informé·e des accrochages à venir et recevoir des invitations
        aux vernissages !
      </div>
    </div>
    <div class="sectionSeparator" />
    <div class="_oeuvreSelected">
      <h3 class="">
        Œuvre choisie pour le prochain accrochage
      </h3>
      <div class="mainText" v-if="!$root.user_login.content.artwork_id">
        Tu n’as pas encore choisi d’oeuvre pour le prochain tirage au sort !
        <router-link to="/participer">Plus d’informations</router-link>
      </div>
      <div class="moreInfos" v-else>
        <!-- <div>
          Voici l’œuvre que tu as choisis pour le prochain tirage au sort :
        </div> -->

        <router-link :to="`/oeuvre/${$root.user_login.content.artwork_id}`">
          <div class="oeuvreCard">
            <PreviewOeuvre
              :id="$root.user_login.content.artwork_id"
              :show_quick_actions="false"
              :thumb_resolution="200"
            />
          </div>
        </router-link>
        <label>Les raisons de ton choix</label>
        <textarea
          id="ARTWORK_MSG"
          name="ARTWORK_MSG"
          class=""
          v-model="$root.user_login.content.artwork_message"
          disabled
        />

        <p>
          Un mail te sera envoyé après le tirage au sort pour te dire si cette
          œuvre est retenue ou pas.
        </p>

        <!-- <p class="">
          <label for="NEWSLETTER">
            <input
              type="checkbox"
              id="NEWSLETTER"
              name="NEWSLETTER"
              v-model="newsletter_subscribe"
            />
            M’envoyer un mail pour me dire si mon œuvre est retenue
          </label>

          <button
            type="button"
            v-if="propose_saving_newsletter_change"
            @click="saveNewsletterChange"
          >
            Enregistrer
          </button>
        </p> -->

        <button type="button" class="redBtn" @click="removeArtwork">
          Retirer ce choix
        </button>
      </div>
    </div>
    <div class="sectionSeparator" />
    <div class="_userAlbums" v-if="$root.user_login.albums">
      <h3 class="">
        Mes albums <small>({{ $root.user_login.albums.length }})</small>
      </h3>
      <AlbumsList
        v-if="$root.user_login.albums.length > 0"
        class=""
        :albums_to_list="$root.user_login.albums"
        :from_frac="false"
        :show_author="false"
      />
      <p class="sectionDesc" v-else>
        Pour créer un album et y ajouter des œuvres, rends toi sur la page d’une
        œuvre et appuie sur
        <svg
          width="1em"
          height="1em"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          style="overflow:visible;enable-background:new 0 0 101 101;"
          xml:space="preserve"
        >
          <path
            fill="currentColor"
            d="M90.4,35h-11v-9.5c0-2.9-2.4-5.3-5.3-5.3h-9.5v-8c0-2.9-2.4-5.3-5.3-5.3H10.9c-2.9,0-5.3,2.4-5.3,5.3v48.5
	c0,2.9,2.4,5.3,5.3,5.3h9.5v8c0,2.9,2.4,5.3,5.3,5.3h11v9.5c0,2.9,2.4,5.3,5.3,5.3h48.5c2.9,0,5.3-2.4,5.3-5.3V40.2
	C95.6,37.3,93.3,35,90.4,35z M20.4,25.5v36h-9.5c-0.5,0-0.8-0.4-0.8-0.8V12.2c0-0.5,0.4-0.8,0.8-0.8h48.5c0.5,0,0.8,0.4,0.8,0.8v8
	H25.7C22.8,20.2,20.4,22.6,20.4,25.5z M36.6,40.2v34.6h-11c-0.5,0-0.8-0.4-0.8-0.8V25.5c0-0.5,0.4-0.8,0.8-0.8h48.5
	c0.5,0,0.8,0.4,0.8,0.8V35H41.9C39,35,36.6,37.3,36.6,40.2z M91.2,88.7c0,0.5-0.4,0.8-0.8,0.8H41.9c-0.5,0-0.8-0.4-0.8-0.8V40.2
	c0-0.5,0.4-0.8,0.8-0.8h48.5c0.5,0,0.8,0.4,0.8,0.8V88.7z"
          />

          <path
            fill="currentColor"
            d="M82.2,62.3H68.3V48.5c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2v13.8H50.1c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2h13.8v13.8
	c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2V66.7h13.8c1.2,0,2.2-1,2.2-2.2S83.4,62.3,82.2,62.3z"
          /></svg
        >. Tu&nbsp;peux
        <router-link :to="`/rechercher/`">
          chercher une œuvre ou un artiste</router-link
        >, ou
        <router-link :to="`/explorer/`">explorer la collection</router-link> et
        trouver la perle rare !
      </p>
    </div>
  </div>
</template>
<script>
import AlbumsList from "../subcomponents/AlbumsList.vue";

export default {
  props: {},
  components: {
    AlbumsList,
  },
  data() {
    return {
      user_id: this.$root.user_login.id,
      user_infos: "",

      sure_remove_user: false,
      export_user_notice: false,

      // newsletter_subscribe: this.$root.user_login.content.artwork_newsletter,
      // propose_saving_newsletter_change: false,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {
    // newsletter_subscribe() {
    //   this.propose_saving_newsletter_change =
    //     this.newsletter_subscribe !==
    //     this.$root.user_login.content.artwork_newsletter;
    // },
    // "$root.user_login.content.artwork_newsletter"() {
    //   this.newsletter_subscribe = this.$root.user_login.content.artwork_newsletter;
    // },
  },
  computed: {},
  methods: {
    logout() {
      this.logoutOfUser();
    },
    removeUser() {
      this.deleteCurrentUser().then(() => {
        this.$toast(`Votre compte a bien été supprimé.`);
      });
    },
    saveNewsletterChange() {
      const data = {
        artwork_newsletter: this.newsletter_subscribe,
      };
      this.updateCurrentUserInfos({ data })
        .then(() => {
          this.propose_saving_newsletter_change = false;
        })
        .catch((err) => console.log(err));
    },
    removeArtwork() {
      const data = {
        artwork_id: "",
        artwork_message: "",
      };
      this.updateCurrentUserInfos({ data })
        .then(() => {})
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss" scoped>
.m_userPage {
  > * {
    // margin-bottom: calc(var(--spacing) * 4);
  }
}

._buttonRow {
  display: flex;
  flex-flow: row wrap;
  gap: calc(var(--spacing) / 4);
  justify-content: center;
}

._notice {
  text-align: center;
  margin: calc(var(--spacing) / 1) auto;
  // max-width: 43ch;

  a {
    text-decoration: underline;
  }
}

._oeuvreSelected {
  // max-width: 320px;
  // margin: 0 auto;
}

._userAlbums {
  padding: 0;
  text-align: center;
}

.sectionDesc {
  text-align: left;
  margin-top: 0;
}

._confirmNotice {
  padding: calc(var(--spacing) / 2);
  background: var(--red);
  border-radius: 6px;
}
</style>
