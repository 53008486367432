<template>
  <div class="m_album">
    <div v-if="is_loading" class="_loader">
      Chargement…
    </div>
    <transition name="fade" v-else>
      <div class="contentBloc">
        <div class="ta-center _topBloc">
          <h1 class="sectionTitle" v-html="content.title" />
          <h2 class="sectionSubtitle">
            Album par
            <router-link
              v-if="is_frac_album"
              :to="`/explorer?mode=frac_albums`"
            >
              le frac île-de-France
            </router-link>
            <router-link v-else :to="`/visiteur/${content.userid}`">
              {{ content.username }}
            </router-link>
          </h2>
          <RS :content="rs_text" prefix="Partage cet album !" />

          <p v-if="is_my_album" class="sectionDesc">
            Pour ajouter des œuvres à cet album, rends toi sur la page d’une
            œuvre et clique sur
            <svg
              width="1em"
              height="1em"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              style="overflow:visible;enable-background:new 0 0 101 101;"
              xml:space="preserve"
            >
              <path
                fill="currentColor"
                d="M90.4,35h-11v-9.5c0-2.9-2.4-5.3-5.3-5.3h-9.5v-8c0-2.9-2.4-5.3-5.3-5.3H10.9c-2.9,0-5.3,2.4-5.3,5.3v48.5
	c0,2.9,2.4,5.3,5.3,5.3h9.5v8c0,2.9,2.4,5.3,5.3,5.3h11v9.5c0,2.9,2.4,5.3,5.3,5.3h48.5c2.9,0,5.3-2.4,5.3-5.3V40.2
	C95.6,37.3,93.3,35,90.4,35z M20.4,25.5v36h-9.5c-0.5,0-0.8-0.4-0.8-0.8V12.2c0-0.5,0.4-0.8,0.8-0.8h48.5c0.5,0,0.8,0.4,0.8,0.8v8
	H25.7C22.8,20.2,20.4,22.6,20.4,25.5z M36.6,40.2v34.6h-11c-0.5,0-0.8-0.4-0.8-0.8V25.5c0-0.5,0.4-0.8,0.8-0.8h48.5
	c0.5,0,0.8,0.4,0.8,0.8V35H41.9C39,35,36.6,37.3,36.6,40.2z M91.2,88.7c0,0.5-0.4,0.8-0.8,0.8H41.9c-0.5,0-0.8-0.4-0.8-0.8V40.2
	c0-0.5,0.4-0.8,0.8-0.8h48.5c0.5,0,0.8,0.4,0.8,0.8V88.7z"
              />

              <path
                fill="currentColor"
                d="M82.2,62.3H68.3V48.5c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2v13.8H50.1c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2h13.8v13.8
	c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2V66.7h13.8c1.2,0,2.2-1,2.2-2.2S83.4,62.3,82.2,62.3z"
              /></svg
            >.Tu&nbsp;peux
            <router-link :to="`/rechercher/`">
              chercher une œuvre ou un artiste</router-link
            >, ou
            <router-link to="/explorer">explorer la collection</router-link>
            pour trouver la perle rare&nbsp;!
          </p>

          <div class="_albumOptions" v-if="is_my_album">
            <button
              type="button"
              @click="edit_artworks_list = !edit_artworks_list"
              :class="{
                'is--active': edit_artworks_list,
              }"
            >
              Modifier la liste d’œuvres
            </button>
            <button type="button" @click="removeAlbum">
              Supprimer l’album
            </button>
            <button
              type="button"
              v-if="!has_main_text"
              @click="edit_main_text = !edit_main_text"
            >
              Ajouter un commentaire
            </button>
          </div>
        </div>

        <div class="_sideBySide">
          <div class="_meta" v-if="has_main_text || edit_main_text">
            <div class="mainText">
              <template v-if="!edit_main_text">
                <p v-if="is_frac_album" v-html="content.main_text" />
                <p v-else v-text="content.main_text" />
              </template>
              <template v-else>
                <textarea v-model="main_text_temp_edit"></textarea>
                <button
                  type="button"
                  class=""
                  @click="edit_main_text = !edit_main_text"
                >
                  Annuler
                </button>
                <button type="button" class="greenBtn" @click="saveMainText">
                  Valider
                </button>
              </template>

              <template v-if="is_my_album && !edit_main_text">
                <button type="button" @click="edit_main_text = !edit_main_text">
                  Modifier ce commentaire
                </button>
              </template>
            </div>
          </div>

          <div
            class="_list"
            :class="{
              'has--noText': !has_main_text,
            }"
          >
            <!-- <transition name="fade-fast" mode="out-in"> -->
            <ListOfArtworks
              v-if="content.artworks"
              :key="content.artworks.join(',')"
              :ids="content.artworks"
              :thumb_resolution="400"
              :with_edit_buttons="edit_artworks_list"
              @changePos="changeArtworkPos"
              @removeArtwork="removeArtwork"
            />
            <!-- </transition> -->
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      is_loading: false,
      is_loading_artworks: false,

      edit_artworks_list: false,
      edit_main_text: false,
      main_text_temp_edit: "",

      content: {
        title: undefined,
        text: undefined,
        artworks_ids: [],
      },
      raw_content: {},
      artworks: [],
    };
  },
  created() {},
  mounted() {
    this.is_loading = true;
    this.is_loading_artworks = true;

    this.getPageContent();
  },
  beforeDestroy() {},
  watch: {},
  computed: {
    is_my_album() {
      return (
        this.$root.user_login.id &&
        this.$root.user_login.id === this.album_author_id
      );
    },
    has_main_text() {
      return this.content.main_text && this.content.main_text !== " ";
    },
    album_author_id() {
      return this.raw_content.userid ? this.raw_content.userid.value : false;
    },
    is_frac_album() {
      return this.$route.path.includes("album-du-frac");
    },
    rs_text() {
      if (this.is_frac_album)
        return this.content.title + " par le frac île-de-France";
      return this.content.title + " par " + this.content.username;
    },
  },
  methods: {
    getPageContent() {
      let path;
      if (this.is_frac_album) path = `/albums-du-frac/${this.id}`;
      else path = `/albums-des-visiteurs/${this.id}`;
      this.fetchPageContent({ path })
        .then((content) => {
          this.is_loading = false;

          this.raw_content = content;

          this.content = this.prepareAlbum({ album: { content } });

          if (this.content.main_text)
            this.main_text_temp_edit = this.content.main_text;
          // this.content.title = content.title.value;
          // this.content.text =
          //   (content.main_text && content.main_text.blocksToHtml) || "";
          // this.content.artworks_ids = content.artworks.yaml.map((path) =>
          //   path.replace("artworks/", "")
          // );
        })
        .catch((err) => {
          if (err === "not_found") {
            this.$toast("La page demandée n’a pas été trouvée.");
            this.$router.replace("/page-introuvable");
          }
          err;
        });
    },

    changeArtworkPos({ artwork_slug, $event }) {
      // $evt;
      const new_pos = Number.parseFloat($event.currentTarget.value) - 1;
      this.moveArtwork({ artwork_slug, index: new_pos });
    },
    moveArtwork({ artwork_slug, index }) {
      let artworks_slugs = this.content.artworks.slice();

      // check if existing and remove if thats the case
      artworks_slugs = artworks_slugs.filter((_as) => _as !== artwork_slug);

      if (index >= 0) artworks_slugs.splice(index, 0, artwork_slug);
      else artworks_slugs.push(artwork_slug);

      console.log(`artworks_slugs ${artworks_slugs.join(",")}`);

      this.editMyAlbum({
        album_id: this.id,
        data: {
          artworks: artworks_slugs.join(","),
        },
      }).then(() => {
        this.getPageContent();
      });
    },
    removeArtwork({ artwork_slug }) {
      let artworks_slugs = this.content.artworks.slice();

      artworks_slugs = artworks_slugs.filter((_as) => _as !== artwork_slug);

      this.editMyAlbum({
        album_id: this.id,
        data: {
          artworks: artworks_slugs.join(","),
        },
      }).then(() => {
        this.getPageContent();
      });
    },

    saveMainText() {
      this.editMyAlbum({
        album_id: this.id,
        data: {
          main_text: this.main_text_temp_edit,
        },
      }).then(() => {
        this.edit_main_text = false;
        this.getPageContent();
      });
    },

    removeAlbum() {
      this.removeMyAlbum({ album_id: this.id })
        .then(() => {
          this.$toast.success(`L’album a bien été supprimé.`);
          this.setCurrentUserAlbums().then(() => {
            this.$router.push({
              path: `/mon-compte`,
            });
          });
        })
        .catch((err) => {
          this.$toast.error(`Impossible de supprimer cet album : ${err}`);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
._sideBySide {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;

  position: relative;
  gap: calc(var(--spacing) * 2);
  margin-top: calc(var(--spacing) * 2);

  > * {
    flex: 1 1 50%;

    &:first-child {
      // flex-basis: 20%;
    }
  }

  #app.is--narrow & {
    flex-flow: row wrap;

    > * {
      flex: 1 1 100%;
    }
  }

  ._meta {
    #app:not(.is--narrow) & {
      position: sticky;
      top: 50px;
      margin-left: 0;
      > * {
        max-width: 50ch;
        margin-left: auto;
      }
    }

    .mainText {
      white-space: pre-wrap;
    }
  }

  ._list:not(.has--noText) {
    ::v-deep {
      ._artworks--list {
        justify-content: flex-start;
        padding-left: 0;
      }
    }
  }
}

._topBloc {
  margin-bottom: calc(var(--spacing));
}

._albumOptions {
  display: flex;
  justify-content: center;
  gap: calc(var(--spacing) / 1);
}
</style>
