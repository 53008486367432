<template>
  <div class="m_artiste contentBloc">
    <div v-if="is_loading || !artist" class="_loader">
      Chargement…
    </div>
    <transition name="fade" v-else>
      <div>
        <div class="contentBloc limitedWidth">
          <h1 class="sectionTitle">
            {{ artist.title }}
          </h1>

          <Notices
            :notes="artist.artist_notes"
            :main_text="artist.main_text"
            :collapsed_by_default="false"
          />
          <hr />
        </div>
        <ListOfArtworks
          v-if="artist.oeuvres && artist.oeuvres.length > 0"
          :ids="artist.oeuvres"
          :show_authors="false"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import Notices from "../components/subcomponents/Notices.vue";

export default {
  props: {},
  components: {
    Notices,
  },
  data() {
    return {
      id: this.$route.params.id,

      artist: {},

      is_loading: false,
    };
  },
  created() {},
  mounted() {
    this.is_loading = true;

    this.fetchArtist({ id: this.id }).then(({ artist = {} }) => {
      this.artist = artist;

      this.fetchArtistNotice({ artist })
        .then((artist_notes) => {
          if (artist_notes)
            this.$set(this.artist, "artist_notes", artist_notes);
        })
        .then(() => {
          this.is_loading = false;
        })
        .catch(() => {
          this.is_loading = false;
        });
    });
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_artiste {
  ::v-deep {
    .m_notice {
      text-align: center;
    }
    ._readFullNotice,
    hr {
      margin-inline: auto;
    }
  }
}
.m_notice {
}
</style>
