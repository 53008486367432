<template>
  <div class="m_albums">
    <div class="titleBar">
      <div>
        <router-link to="/explorer">les albums du frac</router-link>
      </div>
    </div>
    <AlbumsList :from_frac="from_frac" />
  </div>
</template>
<script>
import AlbumsList from "../subcomponents/AlbumsList.vue";

export default {
  props: {
    from_frac: Boolean,
  },
  components: {
    AlbumsList,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
