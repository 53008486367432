<template>
  <div class="m_trackingConsent" v-if="has_consent !== ''">
    <div class="m_trackingConsent--content">
      Ce site réalise un suivi anonymisé des visites et utilise des cookies pour
      améliorer son fonctionnement.
      <div class="_btns">
        <button @click="handleConsent()">Accepter</button>
        <button @click="$emit('close')">Refuser</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      has_consent: "",
    };
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.has_consent = this.$matomo.hasConsent();
      if (this.has_consent === true) this.$emit("close");
    }, 400);
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    handleConsent() {
      this.$matomo.rememberConsentGiven();
      this.$matomo.rememberCookieConsentGiven();

      this.has_consent = this.$matomo.hasConsent();
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.m_trackingConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 15000;

  display: flex;
  justify-content: center;
  align-items: center;
  // text-align: center;
  background-color: var(--active-color);
  color: white;
}

.m_trackingConsent--content {
  width: 100%;
  padding: calc(var(--spacing) * 1) calc(var(--spacing) * 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._btns {
  display: flex;
  flex-flow: row nowrap;
  gap: calc(var(--spacing) / 1);
  color: black;
}
</style>
