<template>
  <div class="m_mediums">
    Soucis : navigart donne un champ authors_nationality, mais qui n’est pas
    remplit.
    <br />
    J’ai un autre champ, authors_birth_death, mais qui contient trop
    d’informations (donc inutilisable). Exemple : 1967, Copenhague (Danemark).
    <br />
    Je laisse de côté du coup.
    <!-- <div v-for="artwork in artworks" :key="artwork.slug"></div> -->
  </div>
</template>
<script>
export default {
  props: {
    artworks_by_mode: Array,
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    // mediums() {
    //   return this.artworks.reduce((acc, a) => {
    //     acc.push(a.medium);
    //     return acc;
    //   }, {});
    // },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_mediums {
  display: flex;
  flex-flow: row wrap;

  overflow: auto;
  gap: calc(var(--spacing) / 2);
  min-height: calc(100vh - 70px);

  align-content: flex-start;

  padding: calc(var(--spacing) / 1);

  background-color: var(--green);

  > ._label {
    flex: 0 1 100%;
    display: flex;
    place-items: center;
  }

  ::v-deep {
    .m_oeuvre--content--image {
      img,
      .noimage {
        width: 50px;
        height: 50px;

        object-fit: cover;
        // max-height: none;
      }
    }
  }
}

._label {
  ::v-deep small {
    margin-left: calc(var(--spacing) / 4);
    span {
      color: white;
    }
  }
}
</style>
