export default {
  computed: {},
  methods: {
    createUser({ name, email, password }) {
      return new Promise((resolve, reject) => {
        console.log(`MIXIN / users • createUser`);

        const body = {
          name,
          email,
          password,
          role: "public",
          language: "fr",
          // content: {
          //   newsletter: true | false,
          //   artwork_id: "390000000018159",
          //   artwork_message: "hello world",
          // },
        };

        // console.log(`body = ${JSON.stringify(body, null, 4)}`);

        this.fetchPrivate({
          path: "/kirby/users",
          method: "POST",
          body,
          login_as_SU: true,
        })
          .then(({ data }) => {
            console.log(
              `MIXIN / users • createUser - success for fetchPrivate`
            );
            return resolve(data);
          })
          .catch((err) => {
            console.log(`MIXIN / users • createUser - failed for fetchPrivate`);
            return reject(err);
          });
      });
    },
    loginAs({ email, password, remember_login_details = false }) {
      return new Promise((resolve, reject) => {
        const body = {
          email,
          password,
          long: true,
        };
        console.log(`MIXIN / users • loginAs ${email}, ${password}`);

        new Promise((resolve) => setTimeout(resolve, 500))
          .then(() =>
            this.fetchPrivate({
              path: "/kirby/auth/login",
              method: "POST",
              body,
              login_as_SU: true,
              // csrf: this.$root.csrf,
            })
          )
          .then(({ user }) => {
            this.$root.user_login.email = email;
            this.$root.user_login.password = password;
            this.$root.user_login.name = user.name;
            this.$root.user_login.id = user.id;

            if (remember_login_details) {
              this.$localstore.set(
                "user_login_email",
                this.$root.user_login.email
              );
              this.$localstore.set(
                "user_login_password",
                this.$root.user_login.password
              );
            }
            return;
          })
          .then(() => this.setCurrentUserContent())
          .then(() => this.setCurrentUserAlbums())
          .then(() => resolve())
          .catch((err) => {
            if (err === "not_found")
              this.$toast.error(
                `Cette adresse email n’est associé à aucun compte.`
              );
            else this.$toast.error(`Login impossible : ${err}`);
            this.resetUserVar();
            return reject(err);
          });
      });
    },

    setCurrentUserContent() {
      return new Promise((resolve, reject) => {
        const { id } = this.$root.user_login;
        console.log(`MIXIN / users • setCurrentUserContent ${id}`);

        this.fetchPrivate({
          path: "/kirby/users/" + id,
          method: "GET",
          // csrf: this.$root.csrf,
        })
          .then(({ data }) => {
            this.$root.user_login.content = data.content;
            return resolve();
          })
          .catch((err) => reject(err));
      });
    },
    getUserInfos({ id }) {
      return new Promise((resolve, reject) => {
        console.log(`MIXIN / users • getUserInfos ${id}`);

        this.fetchPrivate({
          path: "/kirby/users/" + id,
          method: "GET",
          // csrf: this.$root.csrf,
        })
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((err) => reject(err));
      });
    },
    resetPassCurrentUser({ email }) {
      return new Promise((resolve, reject) => {
        console.log(`MIXIN / users • resetPassCurrentUser ${email}`);

        const body = {
          email,
        };

        this.fetchPath({
          path: "/users/forgot",
          method: "POST",
          body,
          // csrf: this.$root.csrf,
        })
          .then(({ data }) => {
            return resolve(data);
          })
          .catch((err) => {
            if (err === "not_found")
              this.$toast.error(
                `Cette adresse email n’est associé à aucun compte.`
              );
            else this.$toast.error(`Login impossible : ${err}`);
            reject(err);
          });
      });
    },
    setCurrentUserAlbums() {
      return new Promise((resolve, reject) => {
        const { id } = this.$root.user_login;
        console.log(`MIXIN / users • setCurrentUserAlbums ${id}`);

        this.fetchSpecificUserAlbums({ userid: id })
          .then((albums) => {
            if (albums) this.$root.user_login.albums = albums;
            return resolve();
          })
          .catch((err) => reject(err));
      });
    },
    updateCurrentUserInfos({ data }) {
      return new Promise((resolve, reject) => {
        const { id } = this.$root.user_login;

        this.fetchPrivate({
          path: "/kirby/users/" + id,
          method: "PATCH",
          // csrf: this.$root.csrf,
          body: data,
        })
          .then(({ data }) => {
            this.$root.user_login.content = data.content;
            return resolve();
          })
          .catch((err) => reject(err));
      });
    },

    logoutOfUser() {
      this.resetUserVar();
    },

    deleteCurrentUser() {
      return new Promise((resolve, reject) => {
        const { id } = this.$root.user_login;

        this.fetchPrivate({
          path: "/kirby/users/" + id,
          method: "DELETE",
        })
          .then(({ data }) => {
            this.resetUserVar();
            resolve(data);
          })
          .catch((err) => reject(err));
      });
    },
    resetUserVar() {
      this.$root.user_login = Object.assign(
        {},
        {
          email: "",
          password: "",
          name: "",
          id: "",
          content: {
            verified: "",
            banned: "",
            newsletter: "",
            artwork_message: "",
            artwork_id: "",
          },
          albums: [],
        }
      );
    },
  },
};
