<template>
  <aside>
    <div class="m_quickOeuvreActions" @click.stop>
      <button
        type="button"
        class="m_quickOeuvreActions--heart _quickButtons"
        :class="{
          'is--disabled':
            artwork.preventselection === true ||
            $root.selection_periode_is !== 'en cours',
          'is--chosen': $root.user_login.content.artwork_id === artwork.slug,
        }"
        :tabindex="!is_expanded ? '-1' : ''"
        @click.prevent="selectOeuvre"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
        >
          <g transform="translate(0,-952.36218)">
            <path
              d="m 31.755979,968.395 c -5.0746,0 -10.122,2.0055 -13.9687,6.03125 -7.6936,8.05134 -7.7157,20.97382 -0.031,29.03125 l 30.7812,32.2812 c 0.7452,0.7878 2.1611,0.7878 2.9063,0 10.2681,-10.7459 20.512996,-21.5039 30.781196,-32.25 7.6935,-8.05143 7.6935,-20.97972 0,-29.0312 -7.6935,-8.05145 -20.275196,-8.05163 -27.968696,0 l -4.25,4.40625 -4.25,-4.4375 c -4.1477,-4.35729 -9.2866,-6.05235 -14.0001,-6.03125 z m 0,3.9375 c 3.999,0 8.019,1.62452 11.125,4.875 l 5.6875,5.96875 c 0.7452,0.78779 2.1611,0.78779 2.9063,0 l 5.6562,-5.9375 c 6.212,-6.50109 16.006796,-6.50095 22.218796,0 6.2118,6.50096 6.2118,16.99904 0,23.49995 -9.779796,10.2349 -19.564096,20.4841 -29.343796,30.7188 l -29.3437,-30.75 c -6.2077,-6.50897 -6.2119,-16.9992 0,-23.5 3.1059,-3.25047 7.0947,-4.875 11.0937,-4.875 z"
              fill="currentColor"
            />
          </g>
        </svg>
        <template v-if="is_expanded && artwork.preventselection !== true">
          <template v-if="$root.user_login.content.artwork_id === artwork.slug">
            Tu as choisi cette œuvre !
          </template>
          <template v-else>
            Choisir pour un accrochage
          </template>
        </template>
      </button>
      <button
        type="button"
        class="m_quickOeuvreActions--collect _quickButtons"
        :class="{}"
        @click.prevent="addToAlbum"
        :tabindex="!is_expanded ? '-1' : ''"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          style="overflow:visible;enable-background:new 0 0 101 101;"
          xml:space="preserve"
        >
          <path
            fill="currentColor"
            d="M90.4,35h-11v-9.5c0-2.9-2.4-5.3-5.3-5.3h-9.5v-8c0-2.9-2.4-5.3-5.3-5.3H10.9c-2.9,0-5.3,2.4-5.3,5.3v48.5
	c0,2.9,2.4,5.3,5.3,5.3h9.5v8c0,2.9,2.4,5.3,5.3,5.3h11v9.5c0,2.9,2.4,5.3,5.3,5.3h48.5c2.9,0,5.3-2.4,5.3-5.3V40.2
	C95.6,37.3,93.3,35,90.4,35z M20.4,25.5v36h-9.5c-0.5,0-0.8-0.4-0.8-0.8V12.2c0-0.5,0.4-0.8,0.8-0.8h48.5c0.5,0,0.8,0.4,0.8,0.8v8
	H25.7C22.8,20.2,20.4,22.6,20.4,25.5z M36.6,40.2v34.6h-11c-0.5,0-0.8-0.4-0.8-0.8V25.5c0-0.5,0.4-0.8,0.8-0.8h48.5
	c0.5,0,0.8,0.4,0.8,0.8V35H41.9C39,35,36.6,37.3,36.6,40.2z M91.2,88.7c0,0.5-0.4,0.8-0.8,0.8H41.9c-0.5,0-0.8-0.4-0.8-0.8V40.2
	c0-0.5,0.4-0.8,0.8-0.8h48.5c0.5,0,0.8,0.4,0.8,0.8V88.7z"
          />

          <line
            v-if="
              $root.is_logged_in === false &&
                $root.artwork_temp_album.includes(artwork.slug)
            "
            x1="50"
            y1="64"
            x2="82"
            y2="64"
            stroke="currentColor"
            stroke-width="4"
          />
          <path
            v-else
            fill="currentColor"
            d="M82.2,62.3H68.3V48.5c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2v13.8H50.1c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2h13.8v13.8
	c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2V66.7h13.8c1.2,0,2.2-1,2.2-2.2S83.4,62.3,82.2,62.3z"
          />
        </svg>
        <template v-if="is_expanded">
          <template v-if="$root.is_logged_in === false">
            <template v-if="!$root.artwork_temp_album.includes(artwork.slug)">
              Mettre de côté
            </template>
            <template v-else>
              Retirer de l’album
            </template>
          </template>
          <template v-else>
            Ajouter à un album
          </template>
        </template>
      </button>
    </div>

    <Choisir
      v-if="show_selection_modal"
      :artwork="artwork"
      @close="show_selection_modal = false"
    />
    <AjouterAlbum
      v-if="show_album_modal"
      :artwork="artwork"
      @close="show_album_modal = false"
    />
  </aside>
</template>
<script>
import Choisir from "../user/Choisir.vue";
import AjouterAlbum from "../user/AjouterAlbum.vue";

export default {
  name: "QuickOeuvre",
  props: {
    is_expanded: {
      default: false,
      type: Boolean,
    },
    artwork: Object,
  },
  components: {
    Choisir,
    AjouterAlbum,
  },
  data() {
    return {
      show_selection_modal: false,
      show_album_modal: false,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    selectOeuvre() {
      if (this.$root.selection_periode_is !== "en cours") {
        this.$toast("La période de sélection n’est pas encore ouverte !");
        return false;
      }
      if (this.artwork.preventselection === true) {
        if (this.artwork.reason) this.$toast.error(this.artwork.reason);
        return false;
      }

      this.show_selection_modal = true;
    },
    addToAlbum() {
      if (this.$root.is_logged_in === false) {
        // pas logged in, on met de côté
        if (!this.$root.artwork_temp_album.includes(this.artwork.slug))
          this.$root.artwork_temp_album.push(this.artwork.slug);
        else
          this.$root.artwork_temp_album = this.$root.artwork_temp_album.filter(
            (a) => a !== this.artwork.slug
          );
      } else this.show_album_modal = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.m_quickOeuvreActions {
  display: flex;
  flex-flow: row wrap;
  background: white;
  color: black;
  border-radius: 4px;

  padding: calc(var(--spacing) / 8);
  gap: calc(var(--spacing) / 8);
  font-size: var(--fs-small);
}

._quickButtons {
  position: relative;
  display: block;
  // width: 2rem;
  margin: 0;
  height: 2rem;
  padding: calc(var(--spacing) / 4);
  border-radius: 4px;

  display: flex;
  gap: calc(var(--spacing) / 4);
  background-color: transparent;

  &:hover {
    background-color: #eee;
  }

  svg {
    display: block;
    // width: 100%;
    // height: 100%;
    width: 1.5rem;
    height: 1.5rem;

    path {
      color: currentColor;
    }
  }

  &.is--chosen {
    color: var(--red);
    svg {
      path {
      }
    }
  }
}
</style>
