import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Oeuvre from "../views/Oeuvre.vue";
import Artiste from "../views/Artiste.vue";
import Explorer from "../views/Explorer.vue";
import Newsletter from "../views/Newsletter.vue";
import Compte from "../views/Compte.vue";
import VerifyAccount from "../views/VerifyAccount.vue";
import DefaultPage from "../views/DefaultPage.vue";
import Album from "../views/Album.vue";
import Visiteur from "../views/Visiteur.vue";
import Accrochages from "../views/Accrochages.vue";
import PageNotFound from "../views/PageNotFound.vue";
// import Accrochages from "../views/Accrochages.vue";
import Rechercher from "../views/Rechercher.vue";
import TirageAuSort from "../views/TirageAuSort.vue";
import Suivi from "../views/Suivi.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Accueil",
    title: "Sors de ta Réserve ! Le site du frac île-de-france",
    component: Home,
    meta: {
      title: "Sors de ta Réserve ! Le site du frac île-de-france",
      metaTags: [
        // {
        //   name: "description",
        //   content: "TODO",
        // },
        // {
        //   property: "og:description",
        //   content: "TODO",
        // },
      ],
    },
  },
  {
    path: "/oeuvre/:id",
    name: "Oeuvre",
    component: Oeuvre,
    meta: {
      title: "Sors de ta Réserve ! Le site du frac île-de-france",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "Oeuvre" */ "../views/Oeuvre.vue"),
  },
  {
    path: "/artiste/:id",
    name: "Artiste",
    component: Artiste,
    meta: {
      title: "Sors de ta Réserve ! Le site du frac île-de-france",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "Oeuvre" */ "../views/Oeuvre.vue"),
  },
  {
    path: "/explorer",
    name: "Explorer",
    title: "Explorer la collection du frac île-de-france",
    component: Explorer,
    meta: {
      title:
        "Explorer les collections du frac île-de-france | Sors de ta Réserve !",
    },
  },
  {
    path: "/participer",
    name: "Comment participer",
    component: DefaultPage,
    meta: {
      title: "Participer à Sors de ta Réserve !",
    },
  },
  {
    path: "/a-propos",
    name: "À propos",
    component: DefaultPage,
    meta: {
      title: "À propos de Sors de ta Réserve !",
    },
  },
  {
    path: "/albums",
    name: "Albums",
    redirect: "/explorer?mode=frac_albums",
  },
  {
    path: "/album-du-frac/:id",
    name: "Album du frac",
    component: Album,
    meta: {
      title: "Album du frac | Sors de ta Réserve !",
    },
  },
  {
    path: "/album/:id",
    name: "Album",
    component: Album,
    meta: {
      title: "Album | Sors de ta Réserve !",
    },
  },
  {
    path: "/accrochages",
    name: "Les accrochages",
    component: Accrochages,
    meta: {
      title: "Les accrochages de Sors de ta Réserve !",
    },
  },
  {
    path: "/mentions-legales",
    name: "Mentions Légales",
    component: DefaultPage,
    meta: {
      title: "Mentions Légales | Sors de ta Réserve !",
    },
  },
  {
    path: "/mon-compte",
    name: "Mon compte",
    component: Compte,
    meta: {
      title: "Sors de ta Réserve ! Le site du frac île-de-france",
    },
    children: [
      {
        path: "verification/:userID/:token",
        name: "Validation du compte",
        meta: {
          title: "Sors de ta Réserve ! Le site du frac île-de-france",
        },
        component: VerifyAccount,
      },
    ],
  },
  {
    path: "/visiteur/:userid",
    name: "Visiteur",
    component: Visiteur,
    meta: {
      title: "Sors de ta Réserve ! Le site du frac île-de-france",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "Oeuvre" */ "../views/Oeuvre.vue"),
  },
  {
    path: "/newsletter",
    name: "La newsletter",
    component: Newsletter,
    meta: {
      title: "Newsletter de Sors de ta Réserve !",
    },
  },
  {
    path: "/rechercher/:str?",
    name: "Rechercher",
    component: Rechercher,
    meta: {
      title: "Rechercher dans Sors de ta Réserve !",
    },
  },
  {
    path: "/tirage-au-sort",
    name: "Tirage au sort",
    component: TirageAuSort,
    meta: {
      title: "Tirage au sort",
    },
  },
  {
    path: "/suivi",
    name: "Suivi des visiteurs",
    component: Suivi,
    meta: {
      title: "Suivi des visiteurs",
    },
  },
  { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "production" ? "/" : "/",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (from && Object.keys(to.query).length) {
        if (to.fullPath.split("?")[0] == from.fullPath.split("?")[0]) return;
      }

      let position = { x: 0, y: 0 };
      // Keep scroll position when using browser buttons
      if (savedPosition) {
        position = savedPosition;
      }
      setTimeout(() => {
        resolve(position);
      }, 500);
    });
  }, //   scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
});

// from https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
