<template>
  <div class="m_explorer">
    <transition name="fade-fast">
      <div class="welcomeModal" v-if="show_welcome_modal">
        <div
          class="welcomeModal--overlay"
          @click="navigation_mode = 'aleatoire'"
        />
        <div class="welcomeModal--inner">
          <button
            class="modalClose"
            @click="navigation_mode = 'aleatoire'"
            aria-label="Close"
          >
            &times;
          </button>

          <h1 class="sectionTitle">
            Explorer la collection du frac île-de-france
          </h1>
          <h2 class="sectionSubtitle">
            Naviguer par oeuvre, par artiste, par thématique, par année de
            création…
          </h2>

          <div class="">
            <div class="radioInputs">
              <label
                :for="opt.key"
                v-for="opt in navigation_options"
                :key="opt.key"
                class="radioInputs--items"
                :class="{
                  'is--active': opt.key === 'aleatoire',
                }"
              >
                <input
                  type="radio"
                  :id="opt.key"
                  :name="opt.key"
                  :value="opt.key"
                  v-model="navigation_mode"
                />
                {{ opt.label }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="pagetransition" mode="out-in">
      <OeuvresAleatoires
        v-if="!navigation_mode || navigation_mode === 'aleatoire'"
        :show_nav="navigation_mode === 'aleatoire'"
        @randomizeCalled="
          navigation_mode !== 'aleatoire' ? (navigation_mode = 'aleatoire') : ''
        "
      />
      <AllArtists v-else-if="navigation_mode === 'artistes'" />
      <Albums v-else-if="navigation_mode === 'frac_albums'" :from_frac="true" />
      <Albums
        v-else-if="navigation_mode === 'user_albums'"
        :from_frac="false"
      />
      <AllArtworks
        v-else-if="navigation_mode === 'all_artworks'"
        mode="all_artworks"
      />
      <AllArtworks
        v-else-if="navigation_mode === 'date_creation'"
        mode="date_creation"
      />
      <AllArtworks
        v-else-if="navigation_mode === 'acquisition_year'"
        mode="acquisition_year"
      />
      <AllArtworks v-else-if="navigation_mode === 'medium'" mode="medium" />
      <AllArtworks v-else-if="navigation_mode === 'country'" mode="country" />
    </transition>
  </div>
</template>
<script>
import OeuvresAleatoires from "../components/explore_modes/OeuvresAleatoires.vue";
import AllArtists from "../components/explore_modes/AllArtists.vue";
import Albums from "../components/explore_modes/Albums.vue";
import AllArtworks from "../components/explore_modes/AllArtworks.vue";

export default {
  props: {},
  components: {
    OeuvresAleatoires,
    AllArtists,
    Albums,
    AllArtworks,
  },
  data() {
    return {
      navigation_mode: false,
      show_welcome_modal: true,
      navigation_options: [
        {
          key: "aleatoire",
          label: "œuvres aléatoires",
        },
        {
          key: "artistes",
          label: "afficher tous les artistes",
        },
        {
          key: "all_artworks",
          label: "toutes les œuvres",
        },
        {
          key: "frac_albums",
          label: "les albums du frac",
        },
        {
          key: "user_albums",
          label: "les albums des visiteurs",
        },
        {
          key: "date_creation",
          label: "dates de création",
        },
        {
          key: "acquisition_year",
          label: "dates d’acquisition",
        },
        {
          key: "medium",
          label: "par medium",
        },
      ],
    };
  },
  created() {},
  mounted() {
    // if (this.navigation_mode) this.show_welcome_modal = true;
  },
  beforeDestroy() {},
  watch: {
    navigation_mode() {
      if (this.navigation_mode) {
        let query = {
          mode: this.navigation_mode,
        };
        if (this.navigation_mode === "artistes") {
          const alphabet = "abcdefghijklmnopqrstuvwxyz";
          const random_lettre =
            alphabet[Math.floor(Math.random() * alphabet.length)];
          query.lettre = random_lettre;
        }

        if (this.navigation_mode !== this.$route.query?.mode) {
          this.$router.push({
            name: "Explorer",
            query,
          });
        }
      }
      // this.show_welcome_modal = false;
      // if (this.navigation_mode === "aleatoire")
      //   this.oeuvres_aleatoire_key = Math.random();
    },
    $route: {
      handler() {
        if (
          this.$route.query &&
          this.$route.query.mode &&
          this.$route.query.mode !== "false"
        ) {
          this.navigation_mode = this.$route.query.mode;
          this.show_welcome_modal = false;
        } else {
          this.navigation_mode = false;
          this.show_welcome_modal = true;
        }
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_explorer {
  min-height: calc(100vh - 70px);
  border-bottom: 2px solid var(--gray-background);
}
.welcomeModal {
  position: absolute;
  inset: 0px;
  z-index: 1;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  pointer-events: none;
}

.welcomeModal--overlay {
  position: absolute;
  inset: 0;

  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  pointer-events: auto;

  transition: all 1.8s cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.welcomeModal--inner {
  position: relative;
  background: white;
  max-width: 620px;
  border-radius: 4px;
  padding: calc(var(--spacing) * 1);

  pointer-events: auto;
}
</style>
