<template>
  <div class="m_mediums">
    <div
      v-for="[medium, { label, artworks }] in artworks_by_mode"
      :data-year="medium"
      :ref="`medium-${medium}`"
      class="m_mediums--section"
      :key="medium"
    >
      <div v-html="label" class="_label" />

      <div class="_list">
        <router-link
          v-for="artwork in artworks"
          :key="artwork.slug"
          :to="`/oeuvre/${artwork.slug}`"
          :title="artwork.title"
        >
          <PreviewOeuvre
            :oeuvre="artwork"
            :thumb_resolution="200"
            :show_title="false"
            :show_quick_actions="false"
          />
        </router-link>
      </div>
    </div>

    <!-- <div v-for="artwork in artworks" :key="artwork.slug"></div> -->
  </div>
</template>
<script>
export default {
  props: {
    artworks_by_mode: Array,
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    // mediums() {
    //   return this.artworks.reduce((acc, a) => {
    //     acc.push(a.medium);
    //     return acc;
    //   }, {});
    // },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_mediums {
  min-height: calc(100vh - 70px);

  align-content: flex-start;
  padding: calc(var(--spacing) / 1);
  background-color: var(--green);

  ::v-deep {
    .m_oeuvre--content--image {
      img,
      .noimage {
        width: 100px;
        height: 100px;

        object-fit: scale-down;
        // max-height: none;
      }
    }
  }
}

.m_mediums--section {
  position: relative;
  margin-bottom: calc(var(--spacing) * 2);
}

._list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: calc(var(--spacing) / 2);
}

._label {
  position: sticky;
  top: 0;
  z-index: 150;
  padding: calc(var(--spacing) / 1) 0;
  text-align: center;

  margin-bottom: calc(var(--spacing) / 2);
  font-weight: bold;
  background-color: var(--green);

  ::v-deep small {
    margin-left: calc(var(--spacing) / 4);
    color: white;
    span {
      color: black;
    }
  }
}
</style>
