<template>
  <div v-if="accrochage">
    <h2 class="sectionTitle">{{ section_title }}</h2>
    <!-- <p class="sectionDesc">
      Ces œuvres ont été choisies par les internautes et sont exposées en ce
      moment aux
      <a href="#">Réserves de Romainville</a>.
    </p> -->
    <AccrochagePreview ref="preview" :accrochage="accrochage" />
  </div>
</template>
<script>
import AccrochagePreview from "../AccrochagePreview.vue";

export default {
  props: {},
  components: {
    AccrochagePreview,
  },
  data() {
    return {
      accrochage: undefined,
    };
  },
  created() {
    this.fetchCurrentExhibition().then((response) => {
      this.accrochage = response;
    });
  },
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    section_title() {
      if (
        this.accrochage &&
        this.$moment(this.$root.current_time).isBetween(
          this.accrochage.starts,
          this.accrochage.ends
        )
      )
        return `L’accrochage en cours`;
      return `Dernier accrochage`;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
