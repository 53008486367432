<template>
  <div class="m_albumsList">
    <router-link
      v-for="album in albums"
      :key="album.slug"
      :to="from_frac ? `/album-du-frac/${album.slug}` : `/album/${album.slug}`"
      :title="album.title"
    >
      <AlbumPreview2 :album="album" :show_author="show_author" />
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    from_frac: {
      type: Boolean,
      default: true,
    },
    albums_uid: [Boolean, Array],
    // fully formed array with artworks ID
    albums_to_list: Array,
    only_x_latest: Number,
    reverse_order: {
      type: Boolean,
      default: true,
    },
    show_author: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      is_loading: true,
      albums: [],
    };
  },
  created() {},
  mounted() {
    if (this.albums_to_list) return;

    new Promise((resolve) => resolve())
      .then(() => {
        return this.fetchAllAlbums({
          from_frac: this.from_frac,
          uid_filter: this.albums_uid,
        });
      })
      .then(({ albums }) => {
        this.is_loading = false;

        let _albums = albums;
        if (this.only_x_latest) _albums = _albums.slice(-this.only_x_latest);

        if (this.reverse_order) this.albums = _albums.reverse();
        else this.albums = _albums;
      });
  },
  beforeDestroy() {},
  watch: {
    albums_to_list: {
      handler() {
        this.albums = this.albums_to_list || [];
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_albumsList {
  display: flex;
  flex-flow: row wrap;
  padding: calc(var(--spacing));
  gap: calc(var(--spacing));

  justify-content: center;

  // a {
  //   text-decoration: none;
  // }
}
</style>
