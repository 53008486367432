import { render, staticRenderFns } from "./Suivi.vue?vue&type=template&id=02879a08&scoped=true&"
import script from "./Suivi.vue?vue&type=script&lang=js&"
export * from "./Suivi.vue?vue&type=script&lang=js&"
import style0 from "./Suivi.vue?vue&type=style&index=0&id=02879a08&lang=scss&scoped=true&"
import style1 from "./Suivi.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02879a08",
  null
  
)

export default component.exports