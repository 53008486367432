<template>
  <div>
    <div class="contentBloc limitedWidth">
      <h1 class="sectionTitle">
        Newsletter
      </h1>
      <p class="sectionDesc">
        Inscris-toi pour être informé·e des accrochages à venir et recevoir des
        invitations aux vernissages !
      </p>
      <CreateOrLogin />
    </div>
  </div>
</template>
<script>
import CreateOrLogin from "../components/user/CreateOrLogin.vue";

export default {
  props: {},
  components: { CreateOrLogin },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
._names {
  display: flex;
  flex-flow: row wrap;

  gap: calc(var(--spacing));

  > * {
    flex: 1 1 auto;
  }
}
</style>
