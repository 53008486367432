<template>
  <div class="m_accrochagePreview">
    <div class="_presentation">
      <StatusPastille
        class="_presentation--status"
        :status="accrochage_status"
      />
      <h1 class="sectionTitle">
        {{ accrochage.title }}
      </h1>
      <h2 class="sectionSubtitle">
        {{ formatStartEndExhibition(accrochage) }}
      </h2>
      <div v-html="accrochage.short_description" />

      <!-- <a
        v-if="accrochage.cover_url"
        :href="accrochage.cover_url"
        target="_blank"
      >
        <img :src="accrochage.cover_url" />
      </a> -->
    </div>
    <div class="_listOfArtworks">
      <ListOfArtworks
        :layout="'horizontal'"
        :ids="accrochage.artworks"
        :cover_url="accrochage.cover_url"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    accrochage: Object,
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    accrochage_status() {
      if (
        this.$moment(this.$root.current_time).isBetween(
          this.accrochage.starts,
          this.accrochage.ends
        )
      )
        return "en cours";
      else if (
        this.$moment(this.$root.current_time).isBefore(this.accrochage.starts)
      )
        return "à venir";
      return "archivé";
    },
  },
  methods: {
    formatStartEndExhibition({ starts, ends }) {
      if (!starts || !ends) return;

      function makeDate(ts) {
        const _d = new Date(ts);
        return _d.toLocaleDateString("fr");
      }

      return "du " + makeDate(starts) + " au " + makeDate(ends);
    },
  },
};
</script>
<style lang="scss" scoped>
.m_accrochagePreview {
  display: flex;
  flex-flow: row wrap;
}

._presentation {
  padding: calc(var(--spacing) * 1);
  flex: 0 0 320px;

  > * {
    // margin-bottom: calc(var(--spacing) * 2);
  }

  > * {
    text-align: left;
  }
}

._presentation--status {
  display: inline-block;
  margin-bottom: calc(var(--spacing) / 2);
}

._listOfArtworks {
  flex: 1 1 420px;
  overflow: hidden;

  ::v-deep ._artworks--list {
    // padding: 0 !important;
  }
}
</style>
