<template>
  <section class="_artworks" :data-layout="layout">
    <h3 v-if="show_title">
      <template v-if="artworks_count > 0">
        Œuvres <small>({{ artworks_count }})</small>
      </template>
      <template v-else>Aucune œuvres à afficher</template>
    </h3>

    <div
      v-if="is_loading || !oeuvres"
      class="_loader"
      :class="{ 'is--tiny': layout === 'tiny' }"
      key="loader"
    >
      Chargement…
    </div>
    <div v-else-if="cover_url || oeuvres.length > 0">
      <Modal @close="show_cover = false" v-if="show_cover" type="full_screen">
        <template v-slot:body>
          <img :src="cover_url" />
        </template>
      </Modal>

      <flickity
        class="_flickity"
        v-if="layout === 'horizontal'"
        ref="flickity"
        :options="flickityOptions"
      >
        <div class="_coverImg slide" v-if="cover_url">
          <div @click="sliderClick" data-iscover>
            <img :src="cover_url" />
            <div class="_title">Aperçu de l’accrochage</div>
          </div>
        </div>
        <div v-for="oeuvre in oeuvres" :key="oeuvre.slug" class="slide">
          <router-link
            :to="`/oeuvre/${oeuvre.slug}`"
            :title="oeuvre.title"
            event
            @click.native.prevent="sliderClick"
          >
            <PreviewOeuvre
              :oeuvre="oeuvre"
              :thumb_resolution="thumb_resolution"
              :show_quick_actions="layout !== 'tiny'"
              :show_title="show_artworks_title && layout !== 'tiny'"
              :show_authors="show_authors"
            />
          </router-link>
        </div>
      </flickity>

      <div class="_artworks--list" v-else>
        <div v-for="(oeuvre, index) in oeuvres" :key="oeuvre.slug">
          <router-link :to="`/oeuvre/${oeuvre.slug}`" :title="oeuvre.title">
            <PreviewOeuvre
              :oeuvre="oeuvre"
              :thumb_resolution="thumb_resolution"
              :show_quick_actions="layout !== 'tiny'"
              :show_title="show_artworks_title && layout !== 'tiny'"
              :show_authors="show_authors"
            />
          </router-link>
          <div key="_editButtons" class="_editButtons" v-if="with_edit_buttons">
            <div>
              <!-- <label>
                déplacer
              </label> -->
              <div class="customSelector is--small">
                <select
                  :value="index + 1"
                  @change="
                    $emit('changePos', {
                      artwork_slug: oeuvre.slug,
                      $event,
                    })
                  "
                >
                  <option
                    v-for="index in oeuvres.length"
                    :key="index"
                    :value="index"
                    v-html="index"
                  />
                </select>
              </div>
            </div>

            <div>
              <button
                type="button"
                @click="$emit('removeArtwork', { artwork_slug: oeuvre.slug })"
              >
                supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-for="index in new Array(10)" :key="index" /> -->
    </div>
  </section>
</template>
<script>
import Modal from "./Modal.vue";

import Flickity from "vue-flickity";

export default {
  props: {
    ids: Array,
    artist_id: [String, Number],
    cover_url: String,
    layout: String,
    show_title: {
      type: Boolean,
      default: false,
    },
    show_artworks_title: {
      type: Boolean,
      default: true,
    },
    show_authors: {
      type: Boolean,
      default: true,
    },
    thumb_resolution: {
      type: Number,
      default: 800,
    },
    with_edit_buttons: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Modal,
    Flickity,
  },
  data() {
    return {
      artworks_ids_to_fetch: [],
      oeuvres: [],

      show_cover: false,

      flickityOptions: {
        initialIndex: 0,
        groupCells: this.$root.is_narrow_device ? false : true,
        imagesLoaded: true,
        pageDots: false,
        resize: true,
        arrowShape:
          "M42,19.4L42 12.7 13.8 12.7 11.1 13 13.6 11.3 20.9 4.8 20.9 0 16 0 0 16.1 16 32.1 20.7 32.1 20.8 27.2 13.6 20.7 11.1 18.9 13.8 19.4z",
        selectedAttraction: 0.2,
        percentPosition: false,
        friction: 0.8,
        cellAlign: this.$root.is_narrow_device ? "center" : "left",
        contain: true,
      },

      is_loading: true,
    };
  },
  created() {},
  mounted() {
    if (this.ids) {
      // no ids to fetch (eg empty accrochages, but possibly with cover)
      if (this.ids.length === 0) this.is_loading = false;
    } else if (this.artist_id && this.artist_id != 0) {
      this.fetchArtist({ id: this.artist_id }).then(({ artist }) => {
        this.artworks_ids_to_fetch = artist.oeuvres;
      });
    } else {
      this.is_loading = false;
    }
  },
  beforeDestroy() {},
  watch: {
    artworks_ids_to_fetch: {
      handler() {
        console.log(`ListOfArtworks / artworks_ids_to_fetch watcher`);
        if (this.artworks_ids_to_fetch.length === 0) return;

        // this.is_loading = true;

        let content = ["cover", "title", "preventselection", "reason"];
        if (this.show_authors) content.push("authors");
        let ids_to_showcase = this.artworks_ids_to_fetch;

        this.fetchArtworks({
          ids: ids_to_showcase,
          content,
        }).then((artworks) => {
          this.is_loading = false;
          this.oeuvres = artworks;

          setTimeout(() => {
            if (this.$refs.flickity) this.$refs.flickity.resize();
            setTimeout(() => {
              if (this.$refs.flickity) this.$refs.flickity.resize();
              setTimeout(() => {
                if (this.$refs.flickity) this.$refs.flickity.resize();
              }, 1500);
            }, 1500);
          }, 1500);
        });
      },
      immediate: true,
    },
    ids: {
      handler() {
        if (this.ids) {
          this.artworks_ids_to_fetch = this.ids;
        }
      },
      immediate: true,
    },
  },
  computed: {
    artworks_count() {
      if (this.oeuvres) return this.oeuvres.length;
      return false;
    },
  },
  methods: {
    sliderClick(evt) {
      if (this.$refs.flickity.$flickity.isPreventingClicks) return false;
      if (evt.currentTarget.getAttribute("href"))
        this.$router.push(evt.currentTarget.getAttribute("href"));
      else if (
        evt.currentTarget.dataset &&
        Object.prototype.hasOwnProperty.call(
          evt.currentTarget.dataset,
          "iscover"
        )
      )
        this.show_cover = true;
    },
  },
};
</script>
<style lang="scss" scoped>
._artworks--list {
  --max_artwork_height: 300px;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;

  padding: 0 calc(var(--spacing) * 2);
  gap: calc(var(--spacing) * 2) calc(var(--spacing) * 1);

  > * {
    flex: 1 1 auto;
    max-width: 180px;
    position: relative;

    ::v-deep {
      .m_oeuvre--content--image {
        .noimage,
        img {
          max-height: var(--max_artwork_height);
        }

        &::before {
          content: "⬤";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.5);
          color: var(--blue);
          z-index: 1001;

          font-weight: bold;
          font-size: var(--fs-larger);
          display: flex;
          justify-content: center;
          align-items: center;

          opacity: 0;
          transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

          pointer-events: none;
        }
      }
    }
  }

  [data-layout="tiny"] & {
    gap: calc(var(--spacing) / 4);
    justify-content: flex-start;
    padding: 0;
    display: flex;

    > * {
      flex: 0 0 auto;
      ::v-deep {
        .m_oeuvre--content {
          position: relative;
          margin-bottom: 0 !important;

          .m_oeuvre--content--image {
            // position: absolute;
            width: 50px;
            height: 50px;

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .router-link-exact-active {
    pointer-events: none;

    // opacity: 0.5;
    ::v-deep {
      .m_oeuvre--content--image {
        &::before {
          opacity: 0.9;
          // mix-blend-mode: multiply;
        }
      }
    }
  }

  ::v-deep {
    a {
      &:hover,
      &:focus {
        // opacity: 0.8;
      }
    }
  }
}

._flickity {
  padding: 0 calc(var(--spacing) * 1);

  // while loading
  min-height: min(50vh, var(--max_artwork_height));

  overflow-x: auto;

  > * {
    max-width: none;
  }

  ::v-deep {
    .m_oeuvre--content--image {
      img,
      .noimage {
        height: 50vh;
        // max-height: none;
      }
    }
    .m_oeuvre--content {
      #app:not(.is--narrow) & {
        margin-bottom: 3.5rem;
      }
    }
    .m_oeuvre--content--caption {
      #app:not(.is--narrow) & {
        position: absolute;
        width: 100%;
        // padding-right: calc(var(--spacing) / 2);
        top: 100%;
      }
    }
  }
}

._navList {
  display: flex;
  place-items: center center;
  padding: calc(var(--spacing) / 2) calc(var(--spacing) / 4 * 3);

  button {
    padding: calc(var(--spacing) / 2) calc(var(--spacing) / 2);
  }
}

.flickity-enabled {
  width: 100%;
}

._flickity {
  width: 100%;
  height: 100%;
  // max-height: var(--max_artwork_height);
  --max_artwork_height: 300px;

  // > * {
  //   width: 100%; /* full width */
  //   height: 160px; /* height of carousel */
  //   margin-right: 10px;
  // }
  ::v-deep .slide {
    // width: 50vh;
    margin-right: calc(var(--spacing) * 1);
    opacity: 0.3;
    max-width: 100%;

    #app.is--narrow & {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);

    &.is-selected {
      opacity: 1;
    }
  }

  ::v-deep {
    img,
    .noimage {
      height: 50vh;
      max-height: var(--max_artwork_height);
      object-fit: contain;
    }
  }

  ::v-deep {
    .flickity-button {
      background: transparent;
      background: rgba(255, 255, 255, 0.4);
    }
    .flickity-button:hover {
      background: #fff;
    }

    .flickity-prev-next-button {
      overflow: hidden;
    }
    .flickity-button:disabled {
      display: none;
    }

    .flickity-prev-next-button .flickity-button-icon {
      left: -68%;
      top: -75%;
      width: 150%;
      height: 150%;
    }

    .flickity-prev-next-button.previous .flickity-button-icon {
      left: 17%;
      top: 27%;
    }
  }
}

._coverImg {
  border-right: 4px solid var(--gray-background);
  padding-right: calc(var(--spacing) * 1);

  > * {
    cursor: pointer;
  }

  ._title {
    margin-top: calc(var(--spacing) / 2);
  }
  img {
    height: 50vh;
    max-height: var(--max_artwork_height);
  }
}

._editButtons {
  padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(var(--spacing) / 2);

  // margin: calc(var(--spacing) / 2) 0;
  // border: 2px solid var(--gray-background);
}
</style>
