export default {
  computed: {},
  methods: {
    fetchPath({ path, url, method = "GET", headers, body }) {
      return new Promise((resolve, reject) => {
        const fetch_URL = url ? url : this.$root.url_to_sdtr_api + path;

        if (this.$root.is_debug) {
          // this.$toast(`FETCHING ${fetch_URL}`);
        }

        let opt = { method };
        if (headers) opt.headers = headers;
        if (body) opt.body = JSON.stringify(body);

        // console.log(`ROOT / fetchPath • fetching fetch_URL = ${fetch_URL}`);
        // console.log(`ROOT / fetchPath • fetching opt = ${JSON.stringify(opt)}`);
        fetch(fetch_URL, opt)
          .then((response) => {
            if (response.status == "404") throw `not_found`;
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("text/html") !== -1)
              return response.text();
            else if (
              contentType &&
              contentType.indexOf("application/json") !== -1
            )
              return response.json();
            else {
              return reject(`Not json`);
            }
          })
          .then((response) => {
            if (!response) {
              console.log(`ROOT / fetchPath • fetching failed with no results`);
              return reject(`No results`);
            }
            console.log(`ROOT / fetchPath • success for ${fetch_URL}`);
            return resolve(response);
          })
          .catch((err) => {
            console.log(
              `ROOT / fetchPath • fetching ${path} failed with error ${err}`
            );
            return reject(err);
          });
      });
    },
    fetchPrivate({ path, method, body, login_as_SU, csrf }) {
      return new Promise((resolve, reject) => {
        console.log(`ROOT / fetchPrivate • fetching = ${path}`);

        let headers = {};
        if (csrf) {
          headers["X-CSRF"] = csrf;
        } else if (login_as_SU) {
          headers["Authorization"] = `Basic ${Buffer.from(
            "louis.eveillard@gmail.com" + ":" + "newuserpass123"
          ).toString("base64")}`;
        } else {
          const { email, password } = this.$root.user_login;
          headers["Authorization"] = `Basic ${Buffer.from(
            email + ":" + password
          ).toString("base64")}`;
        }

        this.fetchPath({ path, method, headers, body })
          .then((response) => {
            if (response.status === "error") {
              console.log(
                `ROOT / fetchPrivate • failed fetching = ${path} with ${response.message}`
              );
              return reject(response.message);
            }
            console.log(`ROOT / fetchPrivate • success fetching = ${path}`);
            return resolve(response);
          })
          .catch((err) => {
            console.log(
              `ROOT / fetchPrivate • failed fetching = ${path} with ${err}`
            );
            return reject(err);
          });
      });
    },
    fetchPageContent({ path }) {
      return new Promise((resolve, reject) => {
        this.fetchPath({
          path,
        })
          .then((response) => {
            return resolve(response.content);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    debounce(fn, delay) {
      var timeoutID = null;
      return function() {
        clearTimeout(timeoutID);
        var args = arguments;
        var that = this;
        timeoutID = setTimeout(function() {
          fn.apply(that, args);
        }, delay);
      };
    },
  },
};
