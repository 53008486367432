export default {
  computed: {},
  methods: {
    fetchAllAlbums({ from_frac, uid_filter }) {
      return new Promise((resolve, reject) => {
        let path = "";

        if (from_frac) {
          path += `/albums-du-frac`;
        } else {
          path += `/albums-des-visiteurs`;
        }

        if (uid_filter) path += `/${uid_filter.join(",")}`;
        else path += `?extend=children`;

        console.log(`ROOT / fetchAllAlbums • fetching = ${path}`);

        this.fetchPath({
          path,
        })
          .then((response) => {
            if (uid_filter && uid_filter.length === 1) response = [response];

            const _albums = response.children
              ? Object.values(response.children)
              : response;

            const albums = _albums.map((album) => this.prepareAlbum({ album }));
            return resolve({ albums });
          })
          .catch((err) => {
            console.log(
              `ROOT / fetchAllAlbums • fetching failed with error ${err}`
            );
            return reject(err);
          });
      });
    },
    // fetchUserAlbumsList({ uid_filter}) {
    //   return new Promise((resolve, reject) => {
    //     const path = `/albums-des-visiteurs?extend=children`;
    //     console.log(`ROOT / fetchUserAlbumsList • fetching = ${path}`);

    //     this.fetchPath({
    //       path,
    //     })
    //       .then((response) => {
    //         const albums = Object.values(response.children).map((album) =>
    //           this.prepareAlbum({ album })
    //         );
    //         return resolve({ albums });
    //       })
    //       .catch((err) => {
    //         console.log(
    //           `ROOT / fetchAllAlbumsDuFrac • fetching failed with error ${err}`
    //         );
    //         return reject(err);
    //       });
    //   });
    // },
    fetchSpecificUserAlbums({ userid }) {
      return new Promise((resolve, reject) => {
        const path = `/users/${userid}/albums?extend=children`;
        console.log(`ROOT / fetchSpecificUserAlbums • fetching = ${path}`);

        this.fetchPath({
          path,
        })
          .then((response) => {
            if (Array.isArray(response))
              return response.map((album) => this.prepareAlbum({ album }));
            else return [];
          })
          .then((albums) => resolve(albums))
          .catch((err) => {
            console.log(
              `ROOT / fetchAllAlbumsDuFrac • fetching failed with error ${err}`
            );
            return reject(err);
          });
      });
    },

    findAlbumsWithArtwork({ artwork_id }) {
      return new Promise((resolve, reject) => {
        const path = `/search_artwork_in_user_albums/${artwork_id}`;
        console.log(`ROOT / findAlbumsWithArtwork • fetching = ${path}`);

        this.fetchPath({
          path,
        })
          .then((albums) => resolve(albums))
          .catch((err) => {
            console.log(
              `ROOT / fetchAllAlbumsDuFrac • fetching failed with error ${err}`
            );
            return reject(err);
          });
      });
    },

    prepareAlbum({ album }) {
      const _album = {};
      _album.ogData = album.data;

      if (album.content?.title) _album.title = album.content.title.value;
      if (album.data?.slug) _album.slug = album.data.slug;
      if (album.content?.main_text)
        if (album.content.main_text.blocksToHtml)
          _album.main_text = album.content.main_text.blocksToHtml;
        else if (album.content.main_text.value)
          _album.main_text = album.content.main_text.value;
      if (album.content?.userid) _album.userid = album.content.userid.value;
      if (album.content?.username)
        _album.username = album.content.username.value;
      if (album.content?.artworks)
        if (album.content.artworks.yaml)
          _album.artworks = album.content.artworks.yaml.map((a) =>
            a.replace("artworks/", "")
          );
        else if (album.content.artworks.value)
          _album.artworks = album.content.artworks.value.split(",");

      return _album;
    },
    createUserAlbum({ title, artworks, userid, username }) {
      return new Promise((resolve, reject) => {
        const method = "POST";

        // ?title=foo&artworks=1,2,3&user=

        let path = `/albums-des-visiteurs`;

        let params = new URLSearchParams();
        params.set("title", title);
        params.set("artworks", artworks);
        params.set("userid", userid);
        params.set("username", username);

        path += `?${params.toString()}`;

        let new_album_id;
        this.fetchPath({ path, method })
          .then((response) => {
            if (response.status === "error") return reject(response.message);
            new_album_id = response.data.slug;
            return;
          })
          .then(() => this.setCurrentUserAlbums())
          .then(() => resolve(new_album_id))
          .catch((err) => reject(err));
      });
    },
    editMyAlbum({ album_id, data }) {
      return new Promise((resolve, reject) => {
        this.fetchPrivate({
          path: `/kirby/pages/albums-des-visiteurs+${album_id}/auth`,
          method: "PATCH",
          body: data,
          // login_as_SU: true,
          // csrf: this.$root.csrf,
        })
          .then((response) => {
            if (response.status === "error") return reject(response.message);
            return resolve(response);
          })
          .then(() => this.setCurrentUserAlbums())
          .then(() => resolve())
          .catch((err) => reject(err));
      });
    },
    removeMyAlbum({ album_id }) {
      return new Promise((resolve, reject) => {
        this.fetchPrivate({
          path: `/kirby/pages/albums-des-visiteurs+${album_id}/auth`,
          method: "DELETE",
        })
          .then((response) => {
            if (response.status === "error") return reject(response.message);
            return resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
  },
};
