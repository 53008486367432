<template>
  <div class="m_createUser">
    <form @submit.prevent="createNewUser">
      <ol class="_numberedSteps">
        <li>
          <div class="fillField">
            <label for="NAME">
              Prénom/nom (ou pseudonyme)
            </label>
            <input
              type="text"
              id="NAME"
              name="NAME"
              class="is--required"
              required=""
              v-model="new_user_name"
              z
            />
            <small>
              Ce nom servira à t’identifier, il sera affiché à tous.
            </small>
          </div>
        </li>
        <li>
          <div class="fillField">
            <label for="EMAIL">
              Ton adresse mail
            </label>
            <input
              type="email"
              id="EMAIL"
              name="EMAIL"
              class="is--required"
              placeholder=""
              required=""
              v-model="new_user_mail"
            />
            <small>
              Utilisée pour te connecter à ton compte, retrouver ton mot de
              passe ou pour t’indiquer si l’œuvre que tu as choisi a été tirée
              au sort pour un accrochage.
            </small>
          </div>
        </li>
        <li>
          <div class="fillField">
            <label for="PASSWORD">
              Ton mot de passe
            </label>
            <PasswordField v-model="new_password" autocomplete="new-password" />
            <small>
              Minimum 8 caractères.
            </small>
          </div>
        </li>
        <li>
          <div class="fillField">
            <label for="remember_login_details">
              <input
                type="checkbox"
                id="remember_login_details"
                name="remember_login_details"
                v-model="remember_login_details"
              />
              Enregistrer ces identifiants avec ce navigateur (utilise un
              cookie)
            </label>
          </div>
        </li>
      </ol>

      <input type="submit" value="Créer" class="" />
    </form>
    <!-- <br />
    <pre>
      {{ created_user_infos }}
      
      </pre> -->
    <div v-if="is_loading" class="_loaderCont">
      <div class="_loader" key="loader">
        Chargement…
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      new_user_name: "",
      new_user_mail: "",
      new_password: "",

      is_loading: false,
      created_user_infos: false,
      remember_login_details: true,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    createNewUser() {
      console.log(`CreateUser / createUser`);
      this.is_loading = true;

      this.createUser({
        name: this.new_user_name,
        email: this.new_user_mail,
        password: this.new_password,
      })
        .then((res) => {
          console.log(`CreateUser / success for createUser`);
          this.$toast.success(`Ton compte vient d’être créé !`);
          this.created_user_infos = res;
          return;
        })
        .then(() => {
          console.log(`CreateUser / now login as`);
          return this.loginAs({
            email: this.new_user_mail,
            password: this.new_password,
            remember_login_details: this.remember_login_details,
          });
        })
        .then(() => {
          this.is_loading = false;
        })
        .catch((err) => {
          this.is_loading = false;
          this.$toast.error(`Erreur à la création : ${err}`);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.m_createUser {
  position: relative;
  max-width: 44ch;
  width: 100%;
  margin: 0 auto;
}

._loaderCont {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 100000;
}
</style>
