<template>
  <div class="contentBloc limitedWidth">
    <h1 class="sectionTitle">
      Page introuvable
    </h1>
    <h2 class="sectionSubtitle">
      La page demandée n’existe pas.
    </h2>

    <div>
      <div class="mainText">
        Vous pouvez
        <router-link to="/">retourner sur la page d’accueil</router-link> ou
        lancer une recherche.
      </div>
      <br />

      <SearchBar />
    </div>
  </div>
</template>
<script>
import SearchBar from "../components/subcomponents/SearchBar.vue";

export default {
  props: {},
  components: {
    SearchBar,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
