<template>
  <div class="m_randomOeuvres">
    <div class="titleBar" v-if="show_nav">
      <div>
        <router-link to="/explorer">œuvres aléatoires</router-link>
        <!-- <div v-if="is_loading" class="_loader" key="loader">…</div> -->
      </div>
    </div>

    <div @click="randomize">
      <transition-group
        name="oeuvres-slide-in"
        tag="section"
        appear
        class="m_randomOeuvres--oeuvres"
        @mousemove="setPerspOrigin"
        :style="{ '--total': oeuvres.length }"
      >
        <div
          class="m_randomOeuvres--oeuvres--oeuvre"
          v-for="oeuvre in oeuvres"
          :key="oeuvre.slug"
          :style="{
            left: `${oeuvre.x}%`,
            top: `${oeuvre.y}%`,
            // transform: `translate(${oeuvre.x}%, ${oeuvre.y}%)`,
            '--i': oeuvre.i,
            '--s': oeuvre.s,
          }"
        >
          <router-link
            @click.native.prevent="navToOeuvre(`/oeuvre/${oeuvre.slug}`)"
            :to="`/oeuvre/${oeuvre.slug}`"
            event
            :key="oeuvre.slug"
            :title="oeuvre.title"
          >
            <PreviewOeuvre
              :id="oeuvre.slug"
              :oeuvre="oeuvre"
              :thumb_resolution="400"
            />
          </router-link>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
// import Draggabilly from "draggabilly";

export default {
  props: {
    show_nav: Boolean,
  },
  components: {},
  data() {
    return {
      oeuvres: [],
      is_loading: false,
      is_dragging: false,
      animate_random_button: false,
    };
  },
  created() {},
  mounted() {
    this.startRandom();
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    startRandom() {
      this.is_loading = true;

      this.fetchArtworks({ size: 8, only_with_images: true, shuffle: true })
        .then((artworks) => {
          // if(!this.oeuvres)
          this.oeuvres = artworks.map((o, index) => {
            const l = index / (artworks.length - 1);

            o.x = l * 70 + 15;

            // -1 or +1
            const top_or_bottom = (index % 2) * 2 - 1;

            o.y = 50 + top_or_bottom * (Math.random() * 25 + 10);
            o.s = Math.random() / 2 + 1;
            o.i = index;
            o._show = false;

            return o;
          });
          // this.shuffleArray(this.oeuvres);
        })
        .catch(() => {
          // console.log("err " + );
        })
        .then(() => {
          this.is_loading = false;

          // this.$nextTick(() => {
          //   document
          //     .querySelectorAll(".m_randomOeuvres--oeuvres--oeuvre")
          //     .forEach((e) => {
          //       const d = new Draggabilly(e, {
          //         containment: true,
          //       });
          //       d.on("dragStart", () => {
          //         this.is_dragging = true;
          //       });
          //       d.on("dragEnd", () => {
          //         setTimeout(() => {
          //           this.is_dragging = false;
          //         }, 100);
          //       });
          //     });
          // });
        });
    },
    setPerspOrigin($event) {
      const mouseX = $event;
      mouseX;
    },
    randomize() {
      if (this.is_dragging) return;
      this.animate_random_button = true;
      this.oeuvres = [];
      this.startRandom();
      this.$emit("randomizeCalled");
    },
    navToOeuvre(route) {
      if (!this.is_dragging) this.$router.push(route);
      // route;
    },
  },
};
</script>
<style lang="scss" scoped>
.m_randomOeuvres {
  background-color: var(--blue);
}
.m_randomOeuvres--oeuvres {
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  // grid-gap: 1rem;

  // perspective: 900px;

  // transform-style: preserve-3d;

  // animation-name: perspective;
  // animation-duration: 1s;
  // animation-timing-function: ease-in-out;
  // animation-direction: alternate;
  // animation-iteration-count: infinite;

  height: calc(100vh - 70px);
  overflow: hidden;

  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 100 100' x='0px' y='0px' width='35' height='35' %3E%3Ccircle cx='50' cy='50' r='50' fill='white' /%3E%3Cpath d='M26,58A28,28,0,0,1,54,30a20,20,0,0,1,0,40,12,12,0,0,1,0-24,4,4,0,0,1,0,8H50v8h4a12,12,0,0,0,0-24,20,20,0,0,0,0,40,28,28,0,0,0,0-56A36,36,0,0,0,18,58V74h8Z' /%3E%3C/svg%3E%0A")
      16 16,
    pointer;

  // background: black;
  // grid-template-rows: masonry;

  .m_randomOeuvres--oeuvres--oeuvre {
    // transform: translateZ(calc(calc(var(--i) + 1) * 10px));
  }

  a {
    display: block;
    text-decoration: none;
    transform: translate3d(-50%, -50%, 0);
  }
}

::v-deep .m_oeuvre--content--caption {
  display: none;
}

::v-deep .m_oeuvre--content--image img {
  // height: 300px;
  // display: flex;
  // justify-content: center;
  // background: var(--gray-background);
  // width: 15vw;
  width: calc(var(--s) * 20vmin) !important;
  height: auto;

  transition: all 1s;
}

.m_randomOeuvres--oeuvres--oeuvre {
  display: block;
  position: absolute;
  pointer-events: none;
  // transform: translate3d(-50%, -50%, 0);
  // transition-property: all;
  // transition-duration: 0.4s;
  // transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    // z-index: 10;
    // transform: translate3d(-50%, -50%, 0) scale(1.06);
  }

  > a {
    pointer-events: auto;
  }
}

._randomizer {
  // position: absolute;
  // z-index: 150;
  background: var(--active-color);
}
</style>
