<template>
  <transition name="pop">
    <span v-show="show_pastille">
      <div
        class="m_timer"
        :class="{
          'is--pastille': !$root.is_narrow_device,
        }"
      >
        <div class="m_timer--content">
          <component
            :is="pastille_url ? 'router-link' : 'span'"
            :to="`/${pastille_url}`"
            v-if="pastille_text"
            v-html="pastille_text"
          />

          <!-- si pendant la sélection, on affiche la fin -->
          <template v-else-if="$root.selection_periode_is === 'en cours'">
            <span>
              prochain tirage au sort dans
              <strong v-html="time_remaining_human" />
              <br class="_br" />
            </span>
          </template>
          <!-- si avant la période de sélection, on affiche la date de début -->
          <template v-else-if="$root.selection_periode_is === 'à venir'">
            <span>
              ouverture de la sélection dans
              <strong v-html="time_until_selection" />
              <br class="_br" />
            </span>
          </template>

          <button type="button" class="_minimizeButton" @click="minimizeTimer">
            <span class="_biggerTarget" />
            &times;
          </button>
        </div>
      </div>
    </span>
  </transition>
</template>
<script>
// import Draggabilly from "draggabilly";

export default {
  props: {},
  components: {},
  data() {
    return {
      show_pastille: false,

      active_pastille: false,
      pastille_text: false,
      pastille_url: false,
    };
  },
  created() {},
  mounted() {
    this.getInfos().then(() => {
      if (this.active_pastille) {
        this.show_pastille = true;
        // new Draggabilly(this.$el, {
        //   containment: true,
        // });
      }
    });
  },
  beforeDestroy() {},
  watch: {},
  computed: {
    time_remaining_human() {
      if (this.$root.time_remaining_in_selection < 0) return false;
      return this.formatTime(this.$root.time_remaining_in_selection);
    },
    time_until_selection() {
      return this.formatTime(this.$root.time_until_selection);
    },
  },
  methods: {
    getInfos() {
      return new Promise((resolve) => {
        this.fetchSelectionInfos().then((obj) => {
          if (obj.active_pastille) this.active_pastille = obj.active_pastille;
          if (obj.pastille_text) this.pastille_text = obj.pastille_text;
          if (obj.pastille_url) this.pastille_url = obj.pastille_url;

          if (obj.selection_start_date)
            this.$root.selection_start_date = obj.selection_start_date;
          if (obj.selection_end_date)
            this.$root.selection_end_date = obj.selection_end_date;

          return resolve();
        });
      });
    },
    formatTime(time) {
      let timing = "";

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(time / (1000 * 60 * 60 * 24));
      if (days !== 0) timing += days + "&nbsp;" + (days > 1 ? "jours" : "jour");

      if (days > 7) return timing;

      if (days !== 0) timing += "<br class='_br'>";

      var hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      if (hours > 0)
        timing +=
          "<span class='_comma'>, </span>" +
          hours +
          "&nbsp;" +
          (hours > 1 ? "heures" : "heure") +
          "";

      var minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > 0)
        timing +=
          "<br class='_br'>" +
          "<span class='_comma'>, </span>" +
          minutes +
          "&nbsp;" +
          (minutes > 1 ? "minutes" : "minute");

      var seconds = Math.floor((time % (1000 * 60)) / 1000);

      if (seconds > 0)
        timing +=
          "<br class='_br'>" +
          "<span class='_comma'> et </span>" +
          seconds +
          "&nbsp;" +
          (seconds > 1 ? "secondes" : "seconde");

      return timing;
    },
    minimizeTimer() {
      this.show_pastille = false;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.m_timer {
  position: relative;
  z-index: 1500;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: var(--active-color);
  color: white;

  // cursor: pointer;

  &:not(.is--pastille) {
    position: fixed;
    top: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100%;
    height: auto;

    ::v-deep ._br {
      display: none;
    }
    ::v-deep ._comma {
      display: inline-block;
      white-space: pre;
    }

    .m_timer--content {
      width: 100%;
      padding: calc(var(--spacing) * 1) calc(var(--spacing) * 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &.is--pastille {
    position: absolute;
    top: 64vh;
    left: 72vw;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    padding: calc(var(--spacing) * 2) calc(var(--spacing) * 2)
      calc(var(--spacing) * 1);

    ::v-deep ._comma {
      display: none;
    }

    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    // transform: rotate(0deg);
    transform: rotate(8deg);
    &:not(:hover) {
    }
  }
}

._minimizeButton {
  // position: absolute;
  // bottom: 0;
  // left: calc(50% - 1em);
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  flex: 0 0 auto;
  // z-index: 1000;
  // background-color: var(--active-color);
  //
  padding: 0;
  margin: 0.5rem;
  border-radius: 50%;
  // background: white;
  background: rgba(255, 255, 255, 0.2);

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  ._biggerTarget {
    display: block;
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    // background: rgba(255, 0, 255, 0.2);
    border-radius: 30%;

    width: 3rem;
    height: 3rem;
  }
}
</style>
