<template>
  <transition name="slide-from-right">
    <div v-if="$root.artwork_temp_album.length > 0" class="m_albumCartWrapper">
      <div class="m_albumCartWrapper--content">
        <strong>Album</strong>

        <!-- :key="$root.artwork_temp_album.join('-')" -->
        <ListOfArtworks
          ref="albumList"
          :layout="'tiny'"
          :thumb_resolution="100"
          class="_currentAlbum"
          :ids="$root.artwork_temp_album"
          :show_artworks_title="false"
          :show_authors="false"
        />

        <button type="button" @click="show_album_modal = true">créer</button>
      </div>

      <AjouterAlbum
        v-if="show_album_modal"
        :artwork_ids="$root.artwork_temp_album"
        @close="show_album_modal = false"
      />
    </div>
  </transition>
</template>
<script>
import AjouterAlbum from "./user/AjouterAlbum.vue";

export default {
  props: {},
  components: {
    AjouterAlbum,
  },
  data() {
    return {
      show_album_modal: false,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {
    "$root.artwork_temp_album"() {
      setTimeout(() => {
        if (this.$refs.albumList)
          this.$refs.albumList.$el.scrollTo(
            0,
            this.$refs.albumList.$el.scrollHeight
          );
      }, 200);
    },
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_albumCartWrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1001;
  pointer-events: none;

  display: flex;
  align-items: center;

  > * {
    background: var(--green);
    // border-radius: 4px 0 0 4px;
    border-radius: 4px;
    padding: calc(var(--spacing) / 4);
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    pointer-events: auto;
  }
}

._currentAlbum {
  // background: var(--gray-background);
  scroll-behavior: smooth;

  &::v-deep {
    ._artworks--list {
      flex-flow: column nowrap;
      gap: calc(var(--spacing) / 4);
    }

    .m_oeuvre--content--image::before {
      // display: none !important;
    }
  }
  // padding: 4px;
  //border-radius: 4px;
}

.m_albumCartWrapper--content {
  display: flex;
  flex-flow: column nowrap;
  gap: calc(var(--spacing) / 4);
  text-align: center;
  line-height: 1;
  // padding-right: calc(var(--spacing) / 1);
  margin-right: calc(var(--spacing) / 1);

  max-height: min(70vh, 500px);

  display: flex;
  flex-flow: column nowrap;

  > * {
    flex: 0 0 auto;

    &._currentAlbum {
      flex: 1 1 auto;
      overflow: auto;
    }
  }

  button {
    margin: 0;
    padding-inline: 0;
    background: white;
    // background: var(--red);
  }
}
</style>
