var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.is_loading)?_c('div',[_c('div',{key:"loader",staticClass:"_loader"},[_vm._v(" Chargement… ")])]):_c('div',[_c('div',{staticClass:"contentBloc limitedWidth"},[_c('h1',{staticClass:"sectionTitle"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),(_vm.content.subtitle)?_c('h2',{staticClass:"sectionSubtitle"},[_vm._v(" "+_vm._s(_vm.content.subtitle)+" ")]):_vm._e(),(_vm.$root.selection_start_date && _vm.$root.selection_end_date)?_c('div',{staticClass:"_selectionPeriod"},[_vm._v(" Période de sélection : "),_c('br'),_vm._v(" du "),_c('time',{attrs:{"datetime":_vm.$moment(_vm.$root.selection_start_date).format(
                'YYYY-MM-DDThh:mm:ssTZD'
              )},domProps:{"innerHTML":_vm._s(
              _vm.$moment(_vm.$root.selection_start_date)
                .format('LLLL')
                .toLowerCase()
            )}}),_c('br'),_vm._v(" au "),_c('time',{attrs:{"datetime":_vm.$moment(_vm.$root.selection_end_date).format(
                'YYYY-MM-DDThh:mm:ssTZD'
              )},domProps:{"innerHTML":_vm._s(
              _vm.$moment(_vm.$root.selection_end_date)
                .format('LLLL')
                .toLowerCase()
            )}})]):_vm._e(),_c('div',{staticClass:"mainText",domProps:{"innerHTML":_vm._s(_vm.content.text)}})]),_c('div',{staticClass:"sectionSeparator"}),_c('section',{staticClass:"_accrochages"},[_c('h1',{staticClass:"sectionTitle"},[_vm._v(" Derniers accrochages ")]),_vm._l((_vm.past_accrochages),function(accrochage){return [_c('AccrochagePreview',{key:accrochage.slug,ref:("accrochage-" + (accrochage.slug)),refInFor:true,attrs:{"accrochage":accrochage}}),_c('div',{key:("separator-" + (accrochage.slug)),staticClass:"sectionSeparator"})]})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }