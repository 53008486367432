<template>
  <div class="m_loginAsUser">
    <form class="_form" @submit.prevent="loginAsUser">
      <div class="fillField">
        <label>
          Ton adresse mail
        </label>
        <input
          type="email"
          name="EMAIL"
          class="is--required"
          placeholder=""
          required=""
          v-model="user_mail"
          ref="mailField"
          :class="{
            'is--invalid':
              this_mail_doesnt_exist && this_mail_doesnt_exist === user_mail,
          }"
        />
        <small
          v-if="this_mail_doesnt_exist && this_mail_doesnt_exist === user_mail"
        >
          Cette adresse mail ne possède pas de compte, mais tu peux toujours en
          créer un.
        </small>
      </div>
      <div class="fillField">
        <label>
          Ton mot de passe
        </label>
        <PasswordField v-model="password" autocomplete="current-password" />
        <button
          class="smallTextButton"
          type="button"
          @click="show_reset_pass_window = !show_reset_pass_window"
        >
          Récupérer son mot de passe
        </button>
      </div>

      <ResetPass
        v-if="show_reset_pass_window"
        :mail="user_mail"
        @close="show_reset_pass_window = false"
      />

      <div class="fillField">
        <label for="remember_login_details">
          <input
            type="checkbox"
            id="remember_login_details"
            name="remember_login_details"
            v-model="remember_login_details"
          />
          Enregistrer ces identifiants avec ce navigateur (utilise un cookie)
        </label>
      </div>

      <input
        type="submit"
        value="S’identifier"
        class=""
        :disabled="this_mail_doesnt_exist === user_mail"
      />
      <div v-if="is_loading" class="_loaderCont">
        <div class="_loader" key="loader">
          Chargement…
        </div>
      </div>
    </form>

    <!-- <pre>
      {{ login_info }}
    </pre> -->
  </div>
</template>
<script>
import ResetPass from "./ResetPass.vue";

export default {
  props: {},
  components: {
    ResetPass,
  },
  data() {
    return {
      user_mail: "",
      password: "",

      login_info: false,
      is_loading: false,
      this_mail_doesnt_exist: "",

      show_reset_pass_window: false,
      remember_login_details: true,
    };
  },
  created() {},
  mounted() {
    // this.user_mail = "hello@louiseveillard.com";
    // this.password = "TnhWW2UBkmFe97u";
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    loginAsUser() {
      this.is_loading = true;
      this.loginAs({
        email: this.user_mail,
        password: this.password,
        remember_login_details: this.remember_login_details,
      })
        .then(() => {
          this.is_loading = false;
        })
        .catch((err) => {
          this.is_loading = false;
          if (err === "not_found") this.this_mail_doesnt_exist = this.user_mail;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.m_loginAsUser {
  max-width: 44ch;
  width: 100%;
  margin: 0 auto;
}
._form {
  position: relative;
}
._loaderCont {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 100000;
}
</style>
