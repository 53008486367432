var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m_createOrLogin"},[_c('div',{staticClass:"radioSwitch"},_vm._l(([
        {
          key: 'create',
          label: 'Créer un compte',
        },
        {
          key: 'login',
          label: 'S’identifier',
        } ]),function(opt){return _c('label',{key:opt.key,class:{
        'is--active': opt.key === _vm.mode,
      },attrs:{"for":opt.key}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mode),expression:"mode"}],attrs:{"type":"radio","id":opt.key,"name":opt.key},domProps:{"value":opt.key,"checked":_vm._q(_vm.mode,opt.key)},on:{"change":function($event){_vm.mode=opt.key}}}),_c('span',[_vm._v(" "+_vm._s(opt.label)+" ")])])}),0),_c('div',{staticClass:"_content"},[(_vm.mode === 'create')?_c('CreateUser'):(_vm.mode === 'login')?_c('LoginAsUser'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }