<template>
  <div class="m_createOrLogin">
    <div class="radioSwitch">
      <label
        :for="opt.key"
        v-for="opt in [
          {
            key: 'create',
            label: 'Créer un compte',
          },
          {
            key: 'login',
            label: 'S’identifier',
          },
        ]"
        :key="opt.key"
        :class="{
          'is--active': opt.key === mode,
        }"
      >
        <input
          type="radio"
          :id="opt.key"
          :name="opt.key"
          :value="opt.key"
          v-model="mode"
        />
        <span>
          {{ opt.label }}
        </span>
      </label>
    </div>

    <div class="_content">
      <CreateUser v-if="mode === 'create'" />
      <LoginAsUser v-else-if="mode === 'login'" />
    </div>
  </div>
</template>
<script>
import CreateUser from "./CreateUser.vue";
import LoginAsUser from "./LoginAsUser.vue";

export default {
  props: {},
  components: {
    CreateUser,
    LoginAsUser,
  },
  data() {
    return {
      mode: "create",
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_createOrLogin {
  // background: var(--gray-background);
  // border: 2px solid var(--gray-background);
  // border-radius: 24px;
  // max-width: 320px;

  input {
    // background: white;
  }
}
._content {
  padding: calc(var(--spacing) / 1);
  margin: 0 auto;
}
</style>
