var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"m_topbar",class:{
      'is--movedUp': _vm.is_moved_up,
      'is--scrolled': _vm.$root.scroll_value > _vm.scroll_threshold,
    }},[_c('div',{staticClass:"m_topbar--content"},[_c('div',{staticClass:"m_topbar--content--title"},[(!_vm.$root.is_homepage)?_c('span',{staticClass:"_navLink"},[_c('router-link',{attrs:{"to":"/"}},[_c('SDTRLogo',{staticClass:"_logo",attrs:{"staggered_animation":false}})],1)],1):_vm._e(),_c('a',{staticClass:"_fracLogo",attrs:{"href":"https://www.fraciledefrance.com/","target":"_blank"}},[_c('FracLogo')],1)]),(_vm.$root.is_narrow_device)?[_c('button',{staticClass:"_hamburger",attrs:{"type":"button"},on:{"click":function($event){_vm.show_menu = !_vm.show_menu}}},[_c('svg',{staticClass:"svg-icon",attrs:{"viewBox":"0 0 20 20","x":"0px","y":"0px","width":"35","height":"35"}},[_c('path',{attrs:{"fill":"currentColor","d":"M3.314,4.8h13.372c0.41,0,0.743-0.333,0.743-0.743c0-0.41-0.333-0.743-0.743-0.743H3.314\n\t\t\t\t\t\t\t\tc-0.41,0-0.743,0.333-0.743,0.743C2.571,4.467,2.904,4.8,3.314,4.8z M16.686,15.2H3.314c-0.41,0-0.743,0.333-0.743,0.743\n\t\t\t\t\t\t\t\ts0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,15.2,16.686,15.2z M16.686,9.257H3.314\n\t\t\t\t\t\t\t\tc-0.41,0-0.743,0.333-0.743,0.743s0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,9.257,16.686,9.257z"}})])]),(_vm.show_menu)?_c('div',{staticClass:"_smMenu"},[_c('div',{staticClass:"_smMenu--overlay",on:{"click":function($event){_vm.show_menu = false}}}),_c('div',{staticClass:"_smMenu--content"},[_c('div',{staticClass:"_noMargins"},[_c('button',{staticClass:"modalClose",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){_vm.show_menu = !_vm.show_menu}}},[_vm._v(" × ")])]),_c('SearchBar',{attrs:{"show_advanced_search_button":false,"search_button_can_be_active":false}}),_c('router-link',{attrs:{"to":"/participer"}},[_vm._v("participer")]),_c('router-link',{attrs:{"to":"/explorer"}},[_vm._v("explorer la collection")]),_c('router-link',{attrs:{"to":"/accrochages"}},[_vm._v("les accrochages")]),_c('router-link',{staticClass:"_accountLink",class:{
                'is--loggedin': _vm.$root.is_logged_in,
              },attrs:{"to":"/mon-compte"}},[(_vm.$root.is_login_as)?[_c('div',{key:"loader",staticClass:"_loader"},[_vm._v("…")])]:(!_vm.$root.is_logged_in)?[_vm._v(" mon compte ")]:[_vm._v(_vm._s(_vm.$root.user_login.name))]],2),_c('PageFooter')],1)]):_vm._e()]:[_c('transition',{attrs:{"name":"fade"}},[(!_vm.$root.is_homepage && _vm.$route.name !== 'Rechercher')?_c('div',{staticClass:"m_topbar--content--search"},[_c('SearchBar',{attrs:{"show_advanced_search_button":false,"search_button_can_be_active":false}})],1):_vm._e()]),_c('nav',{staticClass:"m_topbar--content--buttons"},[_c('router-link',{attrs:{"to":"/participer"}},[_vm._v("participer")]),_c('router-link',{attrs:{"to":"/explorer"}},[_vm._v("explorer la collection")]),_c('router-link',{attrs:{"to":"/accrochages"}},[_vm._v("les accrochages")]),_c('div',{staticClass:"_navVerticalSep"}),_c('router-link',{staticClass:"_accountLink",class:{
              'is--loggedin': _vm.$root.is_logged_in,
            },attrs:{"to":"/mon-compte"}},[(_vm.$root.is_login_as)?[_c('div',{key:"loader",staticClass:"_loader"},[_vm._v("…")])]:(!_vm.$root.is_logged_in)?[_vm._v(" mon compte ")]:[_vm._v(_vm._s(_vm.$root.user_login.name))]],2)],1),(_vm.is_moved_up)?_c('div',{staticClass:"_hoverZone"}):_vm._e()]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }