<template>
  <footer class="m_pagefooter">
    <div class="sectionSeparator" />

    <div class="m_pagefooter--topitems">
      <div>
        <address class="_text">
          <b>Venir au frac île-de-france, les réserves</b><br /><br />
          43 rue de la Commune de Paris<br />93230 Romainville
          <a href="https://goo.gl/maps/8UG1gXPT3iDDiCX86" target="_blank"
            >Google maps ↗</a
          >
          <br />
          <a href="tel:+33176211333">01 76 21 13 33</a><br />
          <a href="mailto:reserves@fraciledefrance.com">
            reserves@fraciledefrance.com
          </a>
        </address>
      </div>
      <div>
        <div class="_text mainText">
          <b>Newsletter</b><br />

          <a
            href="https://app.mailjet.com/widget/iframe/4nvh/iUT"
            target="_blank"
            >Inscris-toi</a
          >
          pour être informé·e des accrochages à venir et recevoir des
          invitations aux vernissages !
        </div>
      </div>
    </div>

    <div class="_links">
      <a href="https://www.fraciledefrance.com/" target="_blank">
        <FracLogo />
      </a>
      <router-link :to="`/a-propos`">
        à propos
      </router-link>
      <router-link :to="`/mentions-legales`">
        crédits & mentions légales
      </router-link>

      <small v-if="$root.app_version" class="_appVersion">
        version v{{ $root.app_version }}
        <br />
        <a href="mailto:ifabre@fraciledefrance.com" target="_blank">
          contact par e-mail
        </a>
      </small>
    </div>
  </footer>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_pagefooter {
  // margin-top: calc(var(--spacing) * 8);
  // margin-bottom: calc(var(--spacing) * 4);
  padding: 0 calc(var(--spacing) / 2);
  text-align: center;

  // border-top: 2px solid var(--gray-background);
}

.m_pagefooter--topitems {
  display: flex;
  flex-flow: row wrap;
  gap: calc(var(--spacing));

  align-items: center;
  margin-bottom: calc(var(--spacing) * 2);

  > * {
    flex: 1 1 320px;
    display: flex;
    justify-content: center;
  }

  #app.is--narrow & {
    // margin: 0 calc(var(--spacing) * 1);

    > * {
    }
  }
}

._text {
  max-width: 30ch;
}

._links {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: calc(var(--spacing) * 2);

  border-top: 2px solid var(--gray-background);
  padding: calc(var(--spacing) * 2);
  margin: 0 calc(var(--spacing) * 4);

  > * {
    flex: 1 1 220px;
  }

  #app.is--narrow & {
    padding: calc(var(--spacing) * 1);
    margin: 0 calc(var(--spacing) * 1);
  }
}
</style>
