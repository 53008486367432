<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="is_loading">
        <div class="_loader" key="loader">
          Chargement…
        </div>
      </div>
      <div v-else>
        <div class="contentBloc limitedWidth">
          <h1 class="sectionTitle">
            Les albums de
            <template v-if="username">{{ username }}</template>
          </h1>
          <!-- <h2 class="sectionSubtitle" v-if="content.subtitle">
            {{ content.subtitle }}
          </h2> -->
          <!-- <pre>{{ user_infos }}</pre> -->
          <AlbumsList
            v-if="user_albums.length > 0"
            class="_userAlbums"
            :albums_to_list="user_albums"
            :from_frac="false"
            :show_author="false"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import AlbumsList from "../components/subcomponents/AlbumsList.vue";

export default {
  props: {},
  components: { AlbumsList },
  data() {
    return {
      userid: this.$route.params.userid,
      username: undefined,
      user_albums: [],

      is_loading: true,
    };
  },
  created() {},
  mounted() {
    this.fetchSpecificUserAlbums({ userid: this.userid }).then((albums) => {
      this.is_loading = false;
      if (albums) this.user_albums = albums;
      this.username = albums[0].username;
    });
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
