<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="is_loading">
        <div class="_loader" key="loader">
          Chargement…
        </div>
      </div>
      <div v-else class="contentBloc limitedWidth">
        <h1 class="sectionTitle">
          {{ content.title }}
        </h1>
        <h2 class="sectionSubtitle" v-if="content.subtitle">
          {{ content.subtitle }}
        </h2>
        <div v-html="content.text" class="mainText" />
        <!-- {{ content.text }} -->
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      content: {
        title: "",
        subtitle: "",
        text: "",
      },
      is_loading: true,
    };
  },
  created() {},
  mounted() {
    const path = window.location.pathname;

    if (this.$root.is_debug) {
      // this.$toast(`fetching page content`);
    }

    this.fetchPageContent({ path }).then(
      ({ page_title, page_subtitle, main_text }) => {
        this.is_loading = false;
        this.content = {
          title: page_title.value,
          subtitle: page_subtitle.value,
          text: main_text.blocksToHtml,
        };
      }
    );
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.mainText {
  padding: calc(var(--spacing)) 0;
}
</style>
