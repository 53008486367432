<template>
  <div class="m_tirage">
    <div class="contentBloc limitedWidth">
      <input type="text" v-model="pwd" placeholder="mot de passe" />
      <button type="button" @click="fetchUsersInfos">Valider</button>
      <transition name="fade" mode="out-in">
        <div v-if="is_loading">
          <div class="_loader" key="loader">
            Chargement…
          </div>
        </div>
        <div v-else class="contentBloc limitedWidth">
          <div class="mainText">
            <ol class="_numberedSteps">
              <li v-for="user in public_users" :key="user.id">
                <div class="_user">
                  {{ user.username }} / {{ user.email }} /
                  <a :href="`mailto:${user.email}`" target="_blank">{{
                    user.email
                  }}</a>
                  (<a
                    :href="$root.url_to_sdtr_api + '/panel/users/' + user.id"
                    target="_blank"
                    >panel</a
                  >)
                  <br />
                  <div class="oeuvreCard" v-if="user.content.artwork_id">
                    <router-link
                      :to="`/oeuvre/${user.content.artwork_id}`"
                      event
                    >
                      <PreviewOeuvre
                        :id="user.content.artwork_id"
                        :show_quick_actions="false"
                        :thumb_resolution="200"
                      />
                    </router-link>
                    <div v-if="user.content.artwork_message">
                      {{ user.content.artwork_message }}
                    </div>
                  </div>
                </div>
              </li>
            </ol>
            <!-- {{ users }} -->
            <!-- <ol class="_numberedSteps _items">
              <li
                v-for="{ artwork_id, users } in tirage"
                :key="artwork_id"
                class="_item"
              >
                <div class="_w">
                  <div class="oeuvreCard">
                    <router-link :to="`/oeuvre/${artwork_id}`" event>
                      <PreviewOeuvre
                        :id="artwork_id"
                        :show_quick_actions="false"
                        :thumb_resolution="200"
                      />
                    </router-link>
                  </div>
                  <div>
                    <small class="_user" v-for="user in users" :key="user.id">
                      {{ user.username }} –
                      <a :href="`mailto:${user.email}`">{{ user.email }}</a> (<a
                        :href="
                          $root.url_to_sdtr_api + '/panel/users/' + user.id
                        "
                        target="_blank"
                        >panel</a
                      >)
                      <div
                        v-if="user.content.artwork_message"
                        v-html="user.content.artwork_message"
                      />
                    </small>
                    <small>
                      <textarea
                        v-html="users.map((u) => u.email).join(', \n')"
                        readonly
                      />
                    </small>
                  </div>
                </div>
              </li>
            </ol> -->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      users: [],
      artworks_selected_with_duplicates: undefined,
      artworks_selected_uniques: undefined,

      pwd: "",
      tirage: [],
      is_loading: false,
    };
  },
  created() {
    if (this.tirage.length > 0) return;
  },
  beforeDestroy() {},
  watch: {},
  computed: {
    public_users() {
      return this.users.filter((u) => u.role === "public");
    },
    // mail_of_users_with_selected_artworks() {
    //   return this.tirage.reduce((acc, { users }) => {
    //     return users.map((u) => u.email);
    //   }, []);
    // },
  },
  methods: {
    fetchUsersInfos() {
      this.is_loading = true;
      this.fetchPrivate({
        path: `/tirage-au-sort/${this.pwd}`,
      })
        .then((users) => {
          this.is_loading = false;
          this.users = Object.values(users);

          // const artworks_selected_with_duplicates = this.users.reduce(
          //   (acc, u) => {
          //     if (u.content?.artwork_id) acc.push(u.content.artwork_id);
          //     return acc;
          //   },
          //   []
          // );

          // https://bost.ocks.org/mike/shuffle/
          // function shuffle(array) {
          //   var m = array.length,
          //     t,
          //     i;

          //   // While there remain elements to shuffle…
          //   while (m) {
          //     // Pick a remaining element…
          //     i = Math.floor(Math.random() * m--);

          //     // And swap it with the current element.
          //     t = array[m];
          //     array[m] = array[i];
          //     array[i] = t;
          //   }

          //   return array;
          // }

          // shuffle(artworks_selected_with_duplicates);

          // const unique = (value, index, self) => {
          //   return self.indexOf(value) === index;
          // };
          // const artworks_selected_uniques = artworks_selected_with_duplicates.filter(
          //   unique
          // );

          // this.tirage = artworks_selected_uniques.map((artwork_id) => {
          //   const users = this.users.filter((u) => {
          //     if (u.content?.artwork_id && u.content.artwork_id === artwork_id)
          //       return true;
          //   });

          //   return {
          //     artwork_id,
          //     users,
          //   };
          // });
        })
        .catch((err) => {
          this.$toast.error(`Mauvais mot de passe.`);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.m_tirage {
}

._item {
  margin-bottom: calc(var(--spacing) * 1);

  > * {
  }
}

._w {
  width: 100%;
}

.oeuvreCard {
  margin-bottom: 0;

  ::v-deep {
    .m_oeuvre--content {
      margin-bottom: 0 !important;
    }
  }
}

._user {
  border-left: 2px solid black;
  padding-left: calc(var(--spacing) / 4);
  margin-bottom: calc(var(--spacing) * 2);
}

textarea {
  min-height: calc(var(--spacing) * 1);
}
</style>
<style lang="scss">
@media print {
  .m_timer,
  .m_trackingConsent,
  .m_topbar--content--buttons,
  .m_pagefooter,
  ._raw {
    display: none !important;
  }

  textarea {
    display: none;
  }

  ._item {
    page-break-inside: avoid;
    break-inside: avoid-page;
  }

  .m_oeuvre--content .m_oeuvre--content--image {
    flex-basis: 20% !important;
  }
}
</style>
