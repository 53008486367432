<template>
  <div
    id="app"
    :class="{
      'is--homePage': $root.is_homepage,
      'is--narrow': $root.is_narrow_device,
    }"
  >
    <transition-page>
      <TopBar :key="$root.is_homepage ? 'topbar_home' : 'topbar'" />
    </transition-page>

    <TrackingConsent
      v-if="show_modal === 'tracking'"
      @close="show_modal = 'timer'"
    />
    <Timer v-show="show_modal === 'timer'" @close="show_modal = false" />
    <AlbumCart />

    <transition-page>
      <router-view class="_pageContent" :key="$route.path" />
    </transition-page>

    <PageFooter />
  </div>
</template>
<script>
import TopBar from "./components/TopBar.vue";
import Timer from "./components/Timer.vue";
import TrackingConsent from "./components/TrackingConsent.vue";
import AlbumCart from "./components/AlbumCart.vue";
import PageFooter from "./components/PageFooter.vue";
import TransitionPage from "./transitions/TransitionPage.vue";

export default {
  name: "App",
  data() {
    return {
      show_modal: "tracking",
    };
  },
  components: {
    TopBar,
    Timer,
    TrackingConsent,
    AlbumCart,
    PageFooter,
    TransitionPage,
  },
};
</script>

<style lang="scss">
::-moz-selection {
  background: rgba(141, 141, 141, 0.3);
}
::selection {
  background: rgba(141, 141, 141, 0.3);
}

:root {
  --dark-gray: rgb(71, 71, 71);
  --text-gray: rgb(121, 121, 121);
  --light-gray: rgb(220, 220, 220);
  --gray-background: rgb(241, 241, 241);

  --active-color: var(--blue);

  --blue: hsl(205, 100%, 35%);
  --green: hsl(147, 58%, 43%);
  --red: hsl(333, 81%, 51%);
  --yellow: hsl(52, 100%, 54%);

  --spacing: 1rem;
  --modal-maxwidth: 26rem;

  --fs-small: 0.85rem;
  --fs-larger: 1.5rem;
}

html,
body {
  min-height: 100%;
  background: white;
  scroll-behavior: smooth;
  caret-color: var(--blue);
  accent-color: var(--blue);
}

body {
  margin: 0;
  // margin-bottom: 150px;
}

address {
  font-style: normal;

  a {
    text-decoration: underline;

    &:hover,
    &:focus-visible,
    &:active {
      text-decoration: none;
    }
  }
}

#app {
  font-family: "ab_eiffel", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;

  // from fraciledefrance.com
  // font-size: 17px;
  // letter-spacing: 0.2px;
  font-size: 106%;

  -moz-font-feature-settings: "onum";
  -moz-font-feature-settings: "tnum=1";
  font-feature-settings: "onum" 1;
  letter-spacing: 0.012em;

  > ._pageContent:not(.m_home) {
    // padding-top: 80px;
    min-height: 60vh;
  }
}

* {
  box-sizing: border-box;
}

.dummy {
  width: 100%;
  height: 200px;
  background: var(--gray-background);
  margin: 1rem 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}
figure {
  margin: 0;
}
figcaption {
  font-size: var(--fs-small);
  text-align: right;
  max-width: 55ch;
  margin-left: auto;
  padding: calc(var(--spacing) / 2);
  padding-right: 0;
}

cite {
  font-style: normal;
  font-size: var(--fs-small);
}

._title,
._albumName {
  font-size: 1.2rem;
}

.to-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._authors,
._albumAuthor {
  font-size: 1.2rem;
  color: var(--text-gray);
}

.sectionTitle {
  text-align: center;
  font-size: 2rem;
  margin-bottom: calc(var(--spacing) * 2);
}
.sectionSubtitle {
  text-align: center;
  color: var(--text-gray);
  font-weight: none;
  margin-bottom: calc(var(--spacing) * 2);
  font-size: 1.2rem;

  small {
    display: inline-block;
  }
}

.sectionSubtitle,
.sectionDesc {
  .sectionTitle + & {
    margin-top: calc(var(--spacing) * -2);
    margin-bottom: calc(var(--spacing) * 1);
  }
}

.mainText {
  // word-wrap: break-word;
  max-width: 66ch;

  > * {
    margin: calc(var(--spacing) * 1) 0;
  }

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  a {
    text-decoration-line: underline;

    &:hover,
    &:focus-visible,
    &:active {
      text-decoration: none;
    }
  }

  &.fromNavigart {
    white-space: pre-wrap;

    p {
      margin: 0;
    }
  }

  video,
  img {
    max-width: 100%;
    height: auto;
  }

  video,
  iframe {
    display: block;
    width: 100%;
    border: none;
    aspect-ratio: 1.78;
  }

  .audioBlock {
    white-space: normal;

    audio {
      width: 100%;
    }
  }
}

audio::-webkit-media-controls-enclosure {
  background-color: #c6c6ec;
}

.sectionDesc {
  max-width: 66ch;
  margin: 1rem auto;
  text-align: center;

  a {
    text-decoration: underline;

    &:hover,
    &:focus-visible,
    &:active {
      text-decoration: none;
    }
  }
}

.ta-center {
  text-align: center;
}

._debugButton {
  opacity: 0.2;
  font-size: var(--fs-small);

  background: transparent;
  padding: 0;
  margin-top: calc(var(--spacing) / 2);
}

button,
.button,
input[type="submit"] {
  appearance: none;
  cursor: pointer;

  border-radius: 1.125rem;
  padding: calc(var(--spacing) / 8) calc(var(--spacing) / 1.5);
  margin: calc(var(--spacing) / 4) 0;

  border: none;
  color: inherit;

  font-size: inherit;
  font-family: inherit;
  line-height: 1.1;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--gray-background);

  transition: all 0.2s;

  &:hover,
  &:focus-visible,
  &:active {
    &:not([disabled]) {
      background-color: var(--light-gray);

      &.is--active {
        background-color: var(--dark-gray);
      }
    }
  }

  svg {
    height: 1em;
  }

  &._withIcon {
    svg {
      margin-right: 0.5em;
    }
  }
  &.is--active {
    background-color: #000;
    color: white;
    fill: white;
  }

  > ._loader {
    width: 1em;
    height: 1em;
    margin: 0;
    margin-right: calc(var(--spacing) / 2);
    // border: none;
  }

  &.is--spinning svg {
    transform-origin: center;
    animation: spin 1s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &[disabled],
  &.is--disabled {
    color: var(--text-gray);
    cursor: not-allowed;
  }
}

input[type="range"] {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;

  &::-webkit-slider-runnable-track {
    background-color: var(--light-gray);
    height: 4px;
    border-radius: 4px;
    border: 0px solid transparent;
  }

  &::-moz-range-track {
    background-color: var(--light-gray);
    height: 6px;
    border-radius: 3px;
    border: none;
  }

  &::-moz-range-thumb {
    border-radius: 20px;
    height: 18px;
    width: 18px;
    border: none;
    background: none;
    background-color: var(--green);
  }

  &:active::-moz-range-thumb {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    border-radius: 100%;
    background-color: var(--green);
    height: 18px;
    width: 18px;
    margin-top: -7px;
  }

  &[disabled]::-webkit-slider-thumb {
    background-color: transparent;
    border: 1px solid #d7dbdd;
  }

  &:active::-webkit-slider-thumb {
    outline: none;
  }
}

.smallTextButton {
  // background: transparent;
  // padding: 0;
  font-size: var(--fs-small);
}

input[type="submit"],
.greenBtn {
  background: var(--green);

  &[disabled] {
    background: var(--light-gray);
  }
}
.redBtn {
  background: var(--red);
}

input[type="submit"] {
  margin-top: calc(var(--spacing));
  margin-left: auto;
  margin-right: auto;
}

.squareGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-auto-rows: 1fr;

  & > * {
    aspect-ratio: 1;
    overflow: hidden;
  }
}

label {
  display: block;
  margin-bottom: calc(var(--spacing) / 8);
  // font-weight: bold;
}

a {
  text-decoration: none;
  text-decoration-thickness: from-font;
  text-underline-offset: 0.1em;
  text-decoration-color: currentColor;
  color: inherit;

  &:hover,
  &:focus-visible {
    text-decoration-line: underline;
  }

  > .m_albumpreview,
  > .m_oeuvre,
  > img {
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    &:hover,
    &:focus-visible,
    &:active {
      opacity: 0.88;
    }
  }
}

h1 {
  font-weight: 600;
  font-size: inherit;
  margin: 0;
}
h2,
h3,
h4 {
  font-weight: 600;
  font-size: inherit;
  margin: 0;

  small {
    display: inline-block;
  }
}

.sectionSeparator {
  margin-bottom: 5vh;
}

pre {
  background: var(--gray-background);
  padding: calc(var(--spacing) / 2);
  overflow: scroll;
  max-height: 500px;
}

.titleBar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  padding: 0rem;
  margin: 0.5rem auto 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    display: flex;
    // gap: calc(var(--spacing) * 1);
    align-items: center;
    background: var(--gray-background);
    // border: 2px solid var(--blue);
    // color: white;
    // box-shadow: 0 0 1rem 0.1rem rgba(41, 41, 41, 0.25);

    border-radius: 25px;
    background: var(--red);

    > a {
      display: inline-block;
      padding: calc(var(--spacing) / 1.5) calc(var(--spacing));
    }
  }

  // button {
  //   margin: calc(var(--spacing) / 4);
  //   background: white;
  // }

  ._loader {
    display: inline-block;
    margin: 0;
    width: 2em;
    height: 2em;
    vertical-align: middle;
  }
}

.radioInputs {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;

  .radioInputs--items {
    display: flex;
    align-items: center;
    line-height: 1;
    font-weight: normal;
    // border: 1px solid black;
    padding: 0.75rem 1rem;
    border-radius: 1.5rem;
    margin: 0.25rem;
    cursor: pointer;

    background-color: rgba(220, 220, 220, 0.4);

    &:hover,
    &:focus-visible,
    &:active {
      &:not([disabled]) {
        background-color: var(--light-gray);

        &.is--active {
          background-color: var(--dark-gray);
        }
      }
    }

    &.is--disabled {
      color: #999;
      cursor: not-allowed;
    }
    &.is--active {
      background-color: var(--blue);
      color: white;
      fill: white;
      // color: #999;
      // cursor: not-allowed;
    }

    input {
      margin: 0;
      margin-right: 0.5rem;
      margin-bottom: 0.5px;
      filter: grayscale(100%);
    }
  }
}

.moreInfos {
  border-left: 4px solid var(--gray-background);
  margin-left: calc(var(--spacing) / 2);
  padding: calc(var(--spacing) / 2) calc(var(--spacing) / 2);

  max-width: 66ch;
}

.Vue-Toastification__toast.Vue-Toastification__toast {
  background-color: var(--blue);
  min-height: 48px;
  padding: calc(var(--spacing) / 1);
  z-index: 20001;
  border-radius: 0;

  font-family: "ab_eiffel", Helvetica, Arial, sans-serif;

  &.Vue-Toastification__toast--success {
    background-color: var(--green);
  }
  &.Vue-Toastification__toast--warning {
    background-color: var(--yellow);
  }
  &.Vue-Toastification__toast--error {
    background-color: var(--red);
  }
}

.Vue-Toastification__close-button {
  margin-left: 18px;
  padding: 0 10px;
}

.Vue-Toastification__toast-body {
  display: flex;
  align-items: center;
}

.customSelector {
  position: relative;

  display: flex;
  align-items: center;

  &.is--short {
    max-width: 25ch;
    select {
      max-width: 33ch;
    }
  }

  select {
    font-family: inherit;
    font-size: inherit;
    min-height: 2.25rem;
    border-radius: 1.125rem;
    padding: 0.5rem 2rem 0.5rem 0.75rem;

    border: none;
    outline: none;
    height: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    // background-color: #fff;
    background-color: var(--gray-background);

    // color: #fff;
    background-image: none;

    &:hover,
    &:focus-visible,
    &:active {
      &:not([disabled]) {
        background-color: var(--light-gray);
      }
    }
  }
  &::after {
    content: "▼";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 0.7em;
    padding: 0 1rem;
    z-index: 10;
    pointer-events: none;
  }

  .customSelector--suffix {
    position: absolute;
    left: 100%;
    margin-left: 1rem;
    min-height: 2.25rem;
    border-radius: 1.125rem;
    padding: 0rem 1rem;

    background-color: rgba(220, 220, 220, 0.4);
    &:hover {
      background-color: rgba(220, 220, 220, 0.8);
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.is--small {
    select {
      min-height: 1.2rem;
      border-radius: 0.9rem;
      padding: 0.1rem 1.5rem 0.1rem 0.75rem;
    }

    &::after {
      padding: 0 0.5rem;
    }
  }
}

small {
  display: block;
  font-weight: normal;
  font-size: var(--fs-small);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// @keyframes perspective {
//   0% {
//     perspective-origin: 59% 50%;
//   }

//   25% {

//   }

//   50% {

//   }

//   to {
//     perspective-origin: 19% 20%;
//   }
// }

.contentBloc {
  margin: calc(var(--spacing) * 3) auto;
  padding: 0 var(--spacing);
}
.limitedWidth {
  max-width: 66ch;
}
.marginAuto {
  margin: 0 auto;
}
.marginNone {
  margin: 0;
}
.marginBottomNone {
  margin-bottom: 0;
}
.marginTopNone {
  margin-top: 0;
}

input[type="email"],
input[type="text"],
input[type="password"],
textarea,
select {
  border-radius: 0;
  // margin: 0.5em 0;
  padding: calc(var(--spacing) / 2);
  margin: calc(var(--spacing) / 8) 0;
  width: 100%;
  max-width: 100%;

  border: none;
  border-left: 2px solid transparent;
  background: var(--gray-background);

  font-size: inherit;
  font-family: inherit;

  &:focus,
  &:active {
    outline: 0;
    border-color: var(--green);
  }

  &.is--invalid {
    border-color: var(--red);
  }

  &.is--required {
    background-size: 16px 16px;
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-image: radial-gradient(var(--red) 18%, transparent 25%);
    transition: background-size 0.2s;
  }
}

option[disabled] {
  color: var(--text-gray);
}

textarea {
  resize: vertical;
  min-height: calc(var(--spacing) * 5);
}

mark {
  // background: hsla(209, 67%, 42%, 0.2);
  // border-radius: 4px;
  // color: var(--active-color);
  background: none;
  font-weight: 600;
  // color: white;
}

select {
}

input[type="checkbox"] {
  margin: 0;
  margin-right: 0.5em;
}

.fillField.fillField {
  margin-bottom: calc(var(--spacing) / 1);
  width: 100%;

  > * {
    display: block;
  }

  input[type="email"],
  input[type="text"],
  input[type="password"],
  textarea,
  select {
  }

  ._maxlength {
    text-align: right;
  }
}

.noimage {
  // position: absolute;
  height: 100%;
  width: 100%;
  // min-height: 200px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  padding: calc(var(--spacing) * 1);
  font-size: var(--fs-small);

  color: var(--text-gray);
}

input {
}

.oeuvreCard {
  .m_oeuvre--content {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    border: 2px solid var(--gray-background);
    margin-bottom: calc(var(--spacing) / 1) !important;

    .m_oeuvre--content--image {
      flex: 0 0 33%;
      position: relative;
      background: var(--blue);

      &::before {
        content: "";
        display: block;
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
      }

      ._content {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }

      // width: 100px;
      // width: 100%;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        margin-right: 0;
        margin-left: auto;
        object-fit: contain;
        object-position: center;
      }
    }
    .m_oeuvre--content--caption {
      position: relative;
      flex: 1 1 0;
      padding: calc(var(--spacing) / 2);
      margin: 0;

      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: flex-start;

      ._title {
        white-space: normal;
      }

      ._authors {
        white-space: normal;
      }
    }
  }
}

.t-warning {
  color: var(--red);
}

.radioSwitch {
  width: 100%;
  padding: calc(var(--spacing) / 2);

  display: flex;
  justify-content: center;

  label {
    flex: 1 1 0;
    font-weight: bold;
    padding: calc(var(--spacing) / 2);
    cursor: pointer;

    // border-radius: 1em;

    span {
      border-bottom: 2px solid transparent;
    }

    &:hover span {
      color: var(--blue);
    }
    &.is--active span {
      border-bottom-color: var(--blue);
      color: var(--blue);
    }

    input {
      width: 1px;
      height: 1px;
      opacity: 0;
      margin: 0;
      padding: 0;
    }
  }

  > * {
    flex: 1 1 auto;
    text-align: center;
  }
}

hr {
  border: none;
  border-top: 1px solid black;
  max-width: 3rem;
  margin: 2rem;
  margin-left: 0;
}

._loader,
._loader::after {
  --loader-color1: var(--gray-background);
  --loader-color2: var(--text-gray);

  border-radius: 50%;
  width: 4em;
  height: 4em;
  overflow: hidden;
}
._loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border: 0.5em solid var(--loader-color1);
  border-left: 0.5em solid var(--loader-color2);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  &.is--tiny {
    margin: auto;
  }
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.appear {
  &-enter-active,
  &-leave-active {
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    // overflow: hidden;
  }

  &-enter,
  &-leave-active {
    opacity: 0;
  }
}

.oeuvres-slide-in {
  &-leave-active,
  &-enter-active {
  }
  &-enter-active {
    transform-origin: 75% 0%;
    transition: all 1s calc(0.05s * var(--i)) cubic-bezier(0.19, 1, 0.22, 1);
  }
  &-leave-active {
    // transition-delay: calc(0.1s * (var(--total) - var(--i)));
    transform-origin: -25% 0%;
    // transition: all 0.5s calc(0.025s * var(--i)) cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
  &-enter {
    transform: scale(0);
  }
  &-leave-to {
    transform: scale(0);
  }
}

.slide-in-fast {
  &-move {
    // transition: opacity .5s linear, transform .5s ease-in-out;
  }
  &-leave-active {
    transition: all 0s;
    // transition: all .1s cubic-bezier(0.19, 1, 0.22, 1);
    // transition-delay: calc( 0.1s * (var(--total) - var(--i)) );
  }
  &-enter-active {
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    // transition: all .25s cubic-bezier(0.32, 0.94, 0.67, 1.53);
    transition-delay: calc(0.02s * var(--i));
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-enter {
    transform: translateX(2em);
  }
}
.slide-from-right {
  &-enter-active,
  &-leave-active {
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
  }
  &-enter,
  &-leave-to {
    transform: translateX(100%);
  }
}

.scrollButton {
  --arrow-size: 1.5em;

  position: absolute;
  // bottom: calc(var(--spacing) * 1);
  bottom: 0;
  left: calc(50% - var(--arrow-size) / 2 - calc(var(--spacing) / 2));
  // width: 2em;
  // height: 2em;
  padding: calc(var(--spacing) / 2);
  border-radius: 50%;

  background-color: transparent;
  // background: white;
  color: var(--blue);

  animation-name: bounce;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.9, 0.01, 0.99, 1.01);
  animation-direction: alternate;
  animation-iteration-count: infinite;

  &:hover,
  &:focus {
    animation-play-state: paused;
  }

  svg {
    width: var(--arrow-size);
    height: var(--arrow-size);
    padding: calc(var(--spacing) / 6);
  }
}

@keyframes bounce {
  0% {
    transform: translate(0, calc(var(--spacing) / -2));
  }
  100% {
    transform: translate(0, calc(var(--spacing) / 1));
  }
}

.modalClose {
  width: 1.5em;
  height: 1.5em;
  font-size: 1.5em;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
}

ol._numberedSteps {
  list-style: none;
  counter-reset: my-awesome-counter 0;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    counter-increment: my-awesome-counter;
    display: flex;
    // padding: calc(var(--spacing) / 2) 0;
    padding: 0;
  }
  li::before {
    content: counter(my-awesome-counter) ".";
    position: relative;
    // right: 100%;
    // height: 100%;

    padding: 0 calc(var(--spacing) / 2) 0 0;
    // border-right: 2px solid var(--text-gray);
    // background: var(--gray-background);

    display: flex;
    align-items: flex-start;

    font-weight: bold;
  }
}
</style>
