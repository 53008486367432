<template>
  <div class="m_searchResults">
    <div v-if="is_loading" class="_loader" key="loader">
      Chargement…
    </div>

    <template v-else>
      <h1 class="sectionTitle">Résultats pour «&nbsp;{{ term }}&nbsp;»</h1>

      <div v-if="response_status === 'no_results'" class="sectionDesc">
        Aucun artiste, œuvre ou album ne correspond à cette recherche.<br />
        Relance une recherche ou
        <router-link to="/explorer">explore la collection</router-link>
        par dates, medium, ou artistes&nbsp;!
      </div>
      <template v-else>
        <section class="_answerType" v-if="authors_id.length > 0">
          <h3 class="sectionSubtitle">
            <template v-if="authors_id.length === 1">
              Artiste
            </template>
            <template v-else>
              Artistes
            </template>
            <small>({{ authors_id.length }})</small>
          </h3>
          <div class="_artists">
            <router-link
              v-for="author_id in authors_id"
              :key="author_id.slug"
              router-link
              :to="`/artiste/${author_id.slug}`"
              v-html="$root.highlightSearchedTerm(author_id.name)"
            />
          </div>
        </section>
        <!-- <ListOfArtworks :artist_id="author_id.slug" :show_authors="false" /> -->
        <!-- </div> -->

        <section class="_answerType" v-if="artworks_id.length > 0">
          <h3 class="sectionSubtitle">
            <template v-if="artworks_id.length === 1">
              Œuvre
            </template>
            <template v-else>
              Œuvres
            </template>
            &nbsp;
            <small>({{ artworks_id.length }})</small>
          </h3>
          <ListOfArtworks
            :show_title="false"
            :ids="artworks_id"
            layout="horizontal"
          />
        </section>

        <section class="_answerType" v-if="frac_albums_id.length > 0">
          <h3 class="sectionSubtitle">
            <template v-if="frac_albums_id.length === 1">
              Album du frac
            </template>
            <template v-else>
              Albums du frac
            </template>
            &nbsp;
            <small>({{ frac_albums_id.length }})</small>
          </h3>
          <AlbumsList :from_frac="true" :albums_uid="frac_albums_id" />
        </section>
        <section class="_answerType" v-if="user_albums_id.length > 0">
          <h3 class="sectionSubtitle">
            <template v-if="user_albums_id.length === 1">
              Album d’un utilisateur
            </template>
            <template v-else>
              Albums des utilisateurs
            </template>
            &nbsp;
            <small>({{ user_albums_id.length }})</small>
          </h3>
          <AlbumsList :from_frac="false" :albums_uid="user_albums_id" />
        </section>
      </template>
    </template>
  </div>
</template>
<script>
import AlbumsList from "../subcomponents/AlbumsList.vue";
export default {
  props: {
    term: String,
  },
  components: {
    AlbumsList,
  },
  data() {
    return {
      response: {},
      response_status: "",

      artworks_id: [],
      authors_id: [],
      frac_albums_id: [],
      user_albums_id: [],

      is_loading: false,
    };
  },
  created() {},
  mounted() {
    if (!this.term || this.term === "") return;

    this.is_loading = true;

    if (this.$root.is_debug) {
      // this.$toast(`fetching search ${this.term}`);
    }

    // see https://api.stg.navigart.fr/full-text.html
    this.fetchPath({ path: `/search/${this.term}` }).then((response) => {
      this.is_loading = false;

      if (
        response.artworks.length === 0 &&
        response.authors.length === 0 &&
        response.frac_albums.length === 0 &&
        response.user_albums.length === 0
      )
        this.response_status = "no_results";
      else this.response_status = "has_results";

      this.response = response;
      this.artworks_id = response.artworks;
      this.authors_id = response.authors;
      this.frac_albums_id = response.frac_albums;
      this.user_albums_id = response.user_albums;

      this.$root.hightlight_term = this.term;
    });
  },
  beforeDestroy() {
    this.$root.hightlight_term = false;
  },
  watch: {},
  computed: {},
  methods: {
    startSearch() {},
  },
};
</script>
<style lang="scss" scoped>
.m_searchResults {
}

._answerType {
  margin: calc(var(--spacing) * 2) 0 calc(var(--spacing) * 4);
}

._artists {
  display: flex;
  flex-flow: row wrap;
  place-content: center;
  gap: calc(var(--spacing)) calc(var(--spacing));

  > * {
    padding: calc(var(--spacing) / 2) calc(var(--spacing));
    background: var(--gray-background);
    border-radius: 1.125rem;
  }
}
</style>
