import Vue from "vue";
import App from "./App.vue";

import VueRouter from "vue-router";
import router from "./router";
Vue.use(VueRouter);

import VueMatomo from "vue-matomo";
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: "https://sorsdetareserve.fraciledefrance.com/matomo",
  siteId: 2,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: "matomo",

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: true,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: false,

  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: true,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: false,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: false,

  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,

  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: undefined,

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: undefined,

  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: [],

  // A function to determine whether to track an interaction as a site search
  // instead of as a page view. If not a function, all interactions will be
  // tracked as page views. Receives the new route as an argument, and
  // returns either an object of keyword, category (optional) and resultsCount
  // (optional) to track as a site search, or a falsey value to track as a page
  // view.
  // Default: false, i.e. track all interactions as page views
  // Example: (to) => {
  //   if (to.query.q && to.name === 'search') {
  //     return { keyword: to.query.q, category: to.params.category }
  //   } else {
  //    return null
  //   }
  // }
  trackSiteSearch: false,

  // Set this to include crossorigin attribute on the matomo script import
  // Default: undefined, possible values : 'anonymous', 'use-credentials'
  crossOrigin: undefined,
});

import { version } from "../package.json";

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  // hideProgressBar: true,
  position: POSITION.BOTTOM_CENTER,
  timeout: 5000,
  maxToasts: 5,
});

Vue.prototype.$eventHub = new Vue(); // Global event bus

import moment from "moment";
// import "moment/locale/fr";
moment.locale("fr");
Vue.prototype.$moment = moment;

const shoetest = require("shoetest");

import FracLogo from "./components/subcomponents/FracLogo.vue";
Vue.component("FracLogo", FracLogo);
import SDTRLogo from "./components/subcomponents/SDTRLogo.vue";
Vue.component("SDTRLogo", SDTRLogo);

import PreviewOeuvre from "./components/subcomponents/PreviewOeuvre.vue";
Vue.component("PreviewOeuvre", PreviewOeuvre);
import AlbumPreview2 from "./components/subcomponents/AlbumPreview2.vue";
Vue.component("AlbumPreview2", AlbumPreview2);
import ListOfArtworks from "./components/subcomponents/ListOfArtworks.vue";
Vue.component("ListOfArtworks", ListOfArtworks);

import RS from "./components/subcomponents/RS";
Vue.component("RS", RS);

import PasswordField from "./components/subcomponents/PasswordField";
Vue.component("PasswordField", PasswordField);

import StatusPastille from "./components/subcomponents/StatusPastille";
Vue.component("StatusPastille", StatusPastille);

Vue.directive("visible", function(el, binding) {
  el.style.visibility = binding.value ? "visible" : "hidden";
});

import utils from "./mixins/utils";
Vue.mixin(utils);

import artwork_mixin from "./mixins/artwork";
Vue.mixin(artwork_mixin);
import artist_mixin from "./mixins/artist";
Vue.mixin(artist_mixin);
import album_mixin from "./mixins/album";
Vue.mixin(album_mixin);
import exhibition_mixin from "./mixins/exhibition";
Vue.mixin(exhibition_mixin);
import users_mixin from "./mixins/users";
Vue.mixin(users_mixin);

Vue.config.productionTip = false;

import localstore from "store";
Vue.prototype.$localstore = localstore;

new Vue({
  router,
  render: (h) => h(App),
  data: {
    scroll_value: 0,
    scrolling_up: false,
    has_seen_home_animation: false,

    modal_is_open: false,
    current_time: undefined,

    selection_start_date: false,
    selection_end_date: false,

    is_login_as: false,

    artwork_temp_album: [],

    url_to_sdtr_api:
      process.env.NODE_ENV === "production"
        ? `https://sdtr-api.fraciledefrance.com`
        : `http://localhost:8000`,

    is_debug: process.env.NODE_ENV !== "production",

    app_version: version,

    all_artists: [],
    all_artworks: [],

    csrf: undefined,
    user_login: {
      email: "",
      password: "",
      name: "",
      id: "",
      content: {
        verified: "",
        banned: "",
        newsletter: "",
        artwork_message: "",
        artwork_id: "",
      },
      albums: [],
    },

    last_album_created_or_added_to: undefined,

    hightlight_term: false,

    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);

    this.current_time = Date.now();
    setInterval(() => {
      this.current_time = Date.now();
    }, 1000);

    const user_login_email = this.$localstore.get("user_login_email");
    if (user_login_email) this.user_login.email = user_login_email;

    const user_login_password = this.$localstore.get("user_login_password");
    if (user_login_password) this.user_login.password = user_login_password;

    if (this.user_login.email && this.user_login.password) {
      this.is_login_as = true;
      this.loginAs({
        email: this.user_login.email,
        password: this.user_login.password,
      })
        .then(() => {
          this.is_login_as = false;
        })
        .catch(() => {
          this.is_login_as = false;
        });
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    scroll_value(val, oldVal) {
      this.scrolling_up = val < oldVal;
    },
    modal_is_open() {
      if (this.modal_is_open) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "";
    },
    user_login: {
      handler() {
        if (this.$matomo && this.$matomo.hasConsent()) {
          // localstore.set("user_login_email", this.user_login.email);
          // localstore.set("user_login_password", this.user_login.password);
        }
      },
      deep: true,
    },
  },
  methods: {
    handleScroll() {
      this.scroll_value = window.pageYOffset;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    highlightSearchedTerm(name) {
      // const regex = new RegExp(this.$root.hightlight_term, "gi");
      if (this.$root.hightlight_term)
        return shoetest.replace(
          this.$root.hightlight_term,
          `<mark class="is--highlighted">$&</mark>`,
          name
        );
      return name;
    },
    scrollABit() {
      console.log("scrollABit");
      window.scroll(0, this.$root.windowHeight);
    },
  },

  computed: {
    is_narrow_device() {
      return this.$root.windowWidth <= 700;
    },
    is_homepage() {
      return this.$route.path === "/";
    },
    is_logged_in() {
      return !!this.user_login.id;
    },
    time_remaining_in_selection() {
      return this.selection_end_date - this.current_time;
    },
    time_until_selection() {
      return this.selection_start_date - this.current_time;
    },
    selection_periode_is() {
      if (
        this.selection_start_date &&
        this.selection_end_date &&
        this.$moment(this.current_time).isBetween(
          this.selection_start_date,
          this.selection_end_date
        )
      )
        return "en cours";
      else if (
        this.selection_start_date &&
        this.$moment(this.current_time).isBefore(this.selection_start_date)
      )
        return "à venir";
      return "archivé";
    },
  },
}).$mount("#app");
