<template>
  <div class="m_oeuvre">
    <div key="content">
      <div class="_topBloc" v-if="!is_loading">
        <div class="m_oeuvre--image" ref="imageBlock">
          <!-- <div v-if="is_loading || !artwork" key="loader" class="_loader">
            Chargement…
          </div> -->
          <template v-if="artwork && artwork.files && artwork.files.length > 0">
            <!-- <transition name="fade-veryfast" mode="out-in"> -->
            <img
              :src="current_image.url"
              :key="current_image.url"
              :srcset="current_image_srcset"
              :sizes="is_fullscreen ? '100vw' : '50vw'"
              :class="{
                'is--hidden': !image_is_loaded,
              }"
              @load="image_is_loaded = true"
              @error="imageFailedToLoad"
            />
            <!-- </transition> -->

            <div class="_imageButtonRow">
              <div class="_navRow" v-if="artwork.files.length > 1">
                <button
                  type="button"
                  class="button-tall"
                  :disabled="current_image_index === 0"
                  @click="current_image_index -= 1"
                >
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="10.5px"
                    height="8px"
                    viewBox="0 0 42 32.1"
                    style="overflow:visible;enable-background:new 0 0 42 32.1;transform: rotate(0deg);"
                    xml:space="preserve"
                  >
                    <polygon
                      class="st0"
                      style="fill: currentColor;"
                      points="42,19.4 42,12.7 13.8,12.7 11.1,13 13.6,11.3 20.9,4.8 20.9,0 16,0 0,16.1 16,32.1 20.7,32.1
  	20.8,27.2 13.6,20.7 11.1,18.9 13.8,19.4 "
                    ></polygon>
                  </svg>
                </button>
                <span>
                  {{ current_image_index + 1 }}/{{ artwork.files.length }}
                </span>
                <button
                  type="button"
                  :disabled="current_image_index >= artwork.files.length - 1"
                  @click="current_image_index += 1"
                >
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="10.5px"
                    height="8px"
                    viewBox="0 0 42 32.1"
                    style="overflow:visible;enable-background:new 0 0 42 32.1;transform: rotate(180deg);"
                    xml:space="preserve"
                  >
                    <polygon
                      class="st0"
                      style="fill: currentColor;"
                      points="42,19.4 42,12.7 13.8,12.7 11.1,13 13.6,11.3 20.9,4.8 20.9,0 16,0 0,16.1 16,32.1 20.7,32.1
  	20.8,27.2 13.6,20.7 11.1,18.9 13.8,19.4 "
                    ></polygon>
                  </svg>
                </button>
              </div>
              <div>
                <button type="button" v-if="!is_fullscreen" @click="toggleFs">
                  agrandir
                </button>
                <button type="button" v-if="is_fullscreen" @click="toggleFs">
                  réduire
                </button>
              </div>
            </div>
          </template>
          <div v-else class="noimage">
            aucune image à afficher ;-(
          </div>

          <div class="m_oeuvre--image--copyright" v-if="current_image">
            {{ current_image.content.photographer }}
            <template
              v-if="
                current_image.content.photographer &&
                  current_image.content.copyright
              "
            >
              —
            </template>
            {{ current_image.content.copyright }}
          </div>
        </div>

        <!-- v-show="show_meta" -->
        <div class="m_oeuvre--meta">
          <div v-if="is_loading" key="loader" class="_loader">
            Chargement…
          </div>

          <div v-else-if="artwork">
            <div class="_authors" v-if="artwork.authors">
              <template v-for="({ _id, name }, index) in artwork.authors">
                <template v-if="index > 0">,&#32;</template
                ><router-link
                  router-link
                  :to="`/artiste/${_id}`"
                  :key="_id"
                  v-html="name.notice"
                />
              </template>
            </div>
            <div class="_title">
              {{ artwork.title }}
            </div>

            <QuickOeuvreActions
              class="m_oeuvre--content--quickActions"
              :id="id"
              :is_expanded="true"
              :artwork="artwork"
            />

            <RS :content="rs_text" prefix="Partage cette œuvre !" />
            <!-- <br /> -->

            <div
              class="_fields _fields_relatedArtworks"
              v-if="artwork.artwork_type === 'separable'"
            >
              <button
                type="button"
                class="no-style"
                v-if="artwork.recap_description || artwork.recap_title"
                @click="show_ensemble = !show_ensemble"
                :class="{
                  'is--active': show_ensemble,
                }"
                v-html="artwork.recap_description || artwork.recap_title"
              />

              <div class="moreInfos" v-if="show_ensemble">
                <ListOfArtworks
                  :ids="artwork.ensemble_artworks"
                  :layout="'tiny'"
                  :thumb_resolution="100"
                  :show_title="false"
                />
              </div>
            </div>
            <div class="_fields">
              <button
                type="button"
                @click="show_infos = !show_infos"
                :class="{
                  'is--active': show_infos,
                }"
                v-html="'informations sur l’œuvre'"
              />
              <div class="moreInfos" v-if="show_infos">
                <div v-if="artwork.artwork_type === 'nonseparable'">
                  <div
                    v-if="artwork.recap_description"
                    v-html="artwork.recap_description"
                  />
                </div>

                <div class="_year" v-if="artwork.date_creation">
                  Année de création :
                  <router-link
                    :to="
                      `/explorer?mode=date_creation&annee=${artwork.date_creation}`
                    "
                  >
                    {{ artwork.date_creation }}
                  </router-link>
                </div>

                <div class="_tirage" v-if="artwork.tirage_photo">
                  Tirage : {{ artwork.tirage_photo }}
                </div>
                <div class="_tirage" v-if="artwork.tirage">
                  Tirage : {{ artwork.tirage }}
                </div>
                <div
                  class="_domain_description_mst"
                  v-if="artwork.domain_description_mst"
                  v-html="artwork.domain_description_mst"
                />
                <div
                  class="_dimensions_additional"
                  v-if="artwork.dimensions_additional"
                  v-html="artwork.dimensions_additional"
                />

                <div v-if="artwork.dimensions">
                  Dimensions : {{ artwork.dimensions }}
                </div>
              </div>
            </div>

            <div class="_fields">
              <Notices
                :notes="artwork.oeuvres_notes"
                :main_text="artwork.main_text"
              />
            </div>

            <div
              class="_fields"
              v-if="
                Array.isArray(artwork.accrochages) &&
                  artwork.accrochages.length > 0
              "
            >
              <br />
              <div
                class="_accrochages relatedSection"
                v-if="artwork.accrochages"
              >
                <label>Accrochages qui comprennent cette œuvre</label>
                <router-link
                  v-for="accrochage in artwork.accrochages"
                  :key="accrochage.slug"
                  :to="`/accrochages?accrochage=${accrochage.slug}`"
                  :title="accrochage.title"
                >
                  {{ accrochage.title }}
                  <img :src="accrochage.cover_url" />
                </router-link>
              </div>
            </div>

            <!-- <template v-if="show_debug">
              • ID de l’œuvre : {{ id }}
              <div>
                •
                <a :href="artwork.navigarturl" target="_blank">
                  navigart ↗
                </a>
              </div>
              • données bruts :
              <pre>{{ artwork }}</pre>
            </template> -->
          </div>
        </div>

        <transition name="fade">
          <button
            type="button"
            class="scrollButton"
            @click="$root.scrollABit"
            v-if="!has_scrolled && show_scroll_button"
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="15.75px"
              height="12px"
              viewBox="0 0 42 32.1"
              style="overflow:visible;enable-background:new 0 0 42 32.1;transform: rotate(270deg);"
              xml:space="preserve"
            >
              <polygon
                class="st0"
                style="fill: currentColor;"
                points="42,19.4 42,12.7 13.8,12.7 11.1,13 13.6,11.3 20.9,4.8 20.9,0 16,0 0,16.1 16,32.1 20.7,32.1
  	20.8,27.2 13.6,20.7 11.1,18.9 13.8,19.4 "
              />
            </svg>
          </button>
        </transition>
      </div>

      <div class="sectionSeparator" />

      <div v-if="is_loading" key="loader" class="_loader">
        Chargement…
      </div>

      <aside v-else-if="has_scrolled" class="_otherArtworks">
        <h1 class="sectionTitle">Œuvres en lien</h1>

        <template v-if="artwork.authors.length === 1">
          <h2 class="sectionSubtitle">
            de l’artiste
            <router-link
              router-link
              :to="`/artiste/${artwork.authors[0]._id}`"
              :key="artwork.authors[0]._id"
              v-html="artwork.authors[0].name.notice"
            />
          </h2>
          <ListOfArtworks
            :artist_id="artwork.authors[0]._id"
            :show_authors="false"
          />
        </template>
        <template v-else>
          <h2 class="sectionSubtitle">
            de l’artiste
            <span class="customSelector is--short">
              <select v-model="selected_artist_id">
                <option
                  v-for="artist in artwork.authors"
                  :key="artist._id"
                  :value="artist._id"
                >
                  {{ artist.name.notice }}
                </option>
              </select>
            </span>
          </h2>
          <ListOfArtworks
            :artist_id="selected_artist_id"
            :key="selected_artist_id"
            :show_authors="false"
          />
        </template>

        <div class="sectionSeparator" />

        <section
          v-if="
            artwork &&
              ((related_frac_albums_id && related_frac_albums_id.length > 0) ||
                (related_user_albums_id && related_user_albums_id.length > 0))
          "
          class="m_oeuvre--inAlbums"
        >
          <h1 class="sectionTitle">Les albums qui contiennent cette œuvre</h1>

          <template v-if="related_frac_albums_id.length > 0">
            <h2 class="sectionSubtitle">Albums du frac</h2>
            <AlbumsList
              :from_frac="true"
              :albums_uid="related_frac_albums_id"
            />
          </template>

          <template v-if="related_user_albums_id.length > 0">
            <h2 class="sectionSubtitle">Les albums des visiteurs</h2>
            <AlbumsList
              :from_frac="false"
              :albums_uid="related_user_albums_id"
            />
          </template>
        </section>
      </aside>
    </div>
  </div>
</template>
<script>
import AlbumsList from "../components/subcomponents/AlbumsList.vue";
import Notices from "../components/subcomponents/Notices.vue";
import QuickOeuvreActions from "../components/subcomponents/QuickOeuvreActions.vue";

export default {
  props: {},
  components: {
    AlbumsList,
    Notices,
    QuickOeuvreActions,
  },
  data() {
    return {
      id: this.$route.params.id,
      artwork: undefined,

      current_image_index: 0,

      show_ensemble: true,
      show_infos: false,
      image_is_loaded: false,

      selected_artist_id: 0,

      is_loading: true,
      is_fullscreen: false,

      show_scroll_button: false,
      has_scrolled: false,

      show_albums: false,

      related_user_albums_id: undefined,
      related_frac_albums_id: undefined,
      // show_meta: true,
    };
  },
  created() {},
  mounted() {
    this.fetchArtworks({ extend: "children,files", id: this.id }).then(
      (artworks) => {
        this.artwork = artworks[0];
        window.document.title = this.artwork.title;
        this.$nextTick(() => {
          this.is_loading = false;
        });

        this.selected_artist_id = this.artwork.authors[0]._id;
      }
    );

    setTimeout(() => (this.show_scroll_button = true), 2000);

    document.addEventListener("fullscreenchange", this.detectFullScreen);
    window.addEventListener("keyup", this.navImage);
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.detectFullScreen);
    window.removeEventListener("keyup", this.navImage);
  },
  watch: {
    "$root.scroll_value": {
      handler() {
        if (this.$root.scroll_value > 100 && !this.has_scrolled) {
          this.has_scrolled = true;

          if (!this.related_user_albums_id) {
            this.fetchRelatedAlbums();
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    current_image() {
      if (!this.artwork) return false;
      return this.artwork.files[this.current_image_index];
    },
    current_image_srcset() {
      if (
        !this.current_image ||
        !this.current_image.content ||
        !this.current_image.content.available_thumbs ||
        this.current_image.content.available_thumbs.length === 0
      )
        return false;

      const srcset_arr = Object.entries(
        this.current_image.content.available_thumbs
      ).reduce((acc, [res, url]) => {
        acc.push(`${url} ${res}w`);
        return acc;
      }, []);
      return srcset_arr.join(",\n");
    },
    rs_text() {
      // const authors = this.artwork.authors[0].name.notice;
      return this.artwork.title;
    },
  },
  methods: {
    detectFullScreen() {
      if (document.fullscreenElement) {
        this.is_fullscreen = true;
        // window.addEventListener("popstate", this.quitFSOnBack);
      } else {
        this.is_fullscreen = false;
        this.$nextTick(() => {
          // window.removeEventListener("popstate", this.quitFSOnBack);
        });
      }
    },
    toggleFs() {
      const elem = this.$refs.imageBlock;

      if (!this.is_fullscreen)
        elem.requestFullscreen().catch((err) => {
          err;
          // window.notify({
          //   message: `Error going fullscreen ${err.message} (${err.name})`,
          //   icon: "exclamation-triangle",
          //   type: "warning",
          // });
        });
      else document.exitFullscreen();
    },
    fetchRelatedAlbums() {
      this.findAlbumsWithArtwork({ artwork_id: this.id })
        .then((albums_id) => {
          this.related_user_albums_id = albums_id.user_albums;
          this.related_frac_albums_id = albums_id.frac_albums;
        })
        .catch((err) => err);
    },
    quitFSOnBack(e) {
      console.log(`Oeuvre / quitFSOnBack`);
      e.preventDefault();
      history.go(1);
    },
    scrollABit() {
      window.scroll(0, this.$root.windowHeight);
    },
    navImage(event) {
      if (event.target.tagName === "INPUT") return;

      if (
        event.keyCode === 39 &&
        this.current_image_index < this.artwork.files.length - 1
      ) {
        this.current_image_index += 1;
      } else if (event.keyCode === 37 && this.current_image_index > 0) {
        this.current_image_index -= 1;
      }
    },
    imageFailedToLoad(err) {
      const img_url = this.artwork.files[this.current_image_index].url.replace(
        4000,
        1000
      );
      this.artwork.files[this.current_image_index].url = img_url;
      this.artwork.files[
        this.current_image_index
      ].content.available_thumbs = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.m_oeuvre {
  min-height: calc(100vh - 70px);

  > ._loader {
  }
}

._topBloc {
  display: flex;
  flex-flow: row wrap;
  // align-items: center;
  align-items: flex-start;
  // margin-bottom: calc(var(--spacing) * 6);
  border-bottom: 2px solid var(--gray-background);

  min-height: calc(100vh - 70px);

  > .m_oeuvre--meta {
    flex: 1 1 50%;
  }
  > .m_oeuvre--image {
    flex: 1 1 50%;
    min-width: 320px;
  }
}

.m_oeuvre--image {
  position: relative;
  position: -webkit-sticky;
  position: sticky;

  top: 0;
  width: 100%;
  height: calc(100vh - 70px);
  // cursor: pointer;

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // margin-bottom: 50px;
  // border-bottom: 1px solid black;
  // background: rgba(245, 245, 245, 1);
  // background: var(--gray-background);
  background-color: var(--blue);
  color: white;

  img {
    width: 100%;
    height: 100%;
    // padding: 2px;
    object-fit: contain;
    object-position: center;

    opacity: 1;
    transition: 1s opacity cubic-bezier(0.19, 1, 0.22, 1);

    &.is--hidden {
      opacity: 0.1;
    }
  }

  .noimage {
    color: inherit;
  }

  &:fullscreen {
    background: white;
    img {
      object-position: center;
    }
  }
}

// .m_oeuvre--image--fsButton {
//   position: absolute;
//   bottom: 0;
//   left: calc(50% - 1em);
//   width: 2em;
//   height: 2em;
//   font-size: 1.4em;

//   display: flex;
//   align-items: center;
// }

.m_oeuvre--image--copyright {
  // position: absolute;
  // bottom: 0;
  // left: 0%;
  flex: 0 0 100%;
  text-align: left;
  color: black;

  font-size: var(--fs-small);

  padding: calc(var(--spacing) / 8) calc(var(--spacing) / 4);
}

.m_oeuvre--content--quickActions {
  margin: 0;
  margin-left: calc(var(--spacing) / -2);
}

.m_oeuvre--meta {
  position: relative;
  padding: calc(var(--spacing) * 2) calc(var(--spacing) * 4)
    calc(var(--spacing) * 4) calc(var(--spacing) * 2);
  min-height: calc(100vh - 70px);
  // margin-top: 40vh;
  background: white;

  display: flex;
  align-items: center;

  > * {
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }

  button {
    max-width: 35ch;
  }

  ._fields {
    margin-top: calc(var(--spacing) / 2);
    margin-bottom: calc(var(--spacing) / 2);

    ::v-deep button {
      // margin-left: calc(var(--spacing) / -1.5);
    }

    button {
      margin-bottom: 0;
    }
  }
}

._title {
  font-size: 3rem;
  font-weight: bold;
}

._authors {
}

._albums {
}

._fsButton {
  position: absolute;
  right: 0;
  top: 0;

  button {
    background: white;
  }
}

._imageButtonRow {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  // max-width: 320px;
  padding: calc(var(--spacing) / 8);
  margin-bottom: calc(var(--spacing) / 4);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  // align-items: flex-end;
  gap: calc(var(--spacing) / 8);

  ._navRow {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(var(--spacing) / 2);
    padding: calc(var(--spacing) / 8);

    background: rgba(255, 255, 255, 0.6);
    color: black;

    border-radius: 1.125rem;

    &:hover {
      background: white;
    }

    button {
      width: 2rem;
      height: 2rem;
      margin: 0;
      background: transparent;

      &:not([disabled]) {
        background: var(--blue);
      }
    }
  }

  button {
    background: rgba(255, 255, 255, 0.6);
    color: black;

    &:hover,
    &:focus-visible,
    &:active {
      &:not([disabled]) {
        background: white;
      }
    }
  }
}

._readFullNotice {
  display: inline-block;
}

.relatedSection {
}

.relatedSection--content {
  padding: calc(var(--spacing) * 1);
  background: var(--gray-background);
}

._albumPreview {
  // display: flex;
  // width: 100%;
}

.m_oeuvre--inAlbums {
  // margin-top: calc(var(--spacing) * 2);

  ::v-deep .m_oeuvre--content--image {
    // height: 300px;

    img {
      // height: 100%;
    }
  }
}

._accrochages {
  label {
    display: block;
    margin-bottom: calc(var(--spacing) / 2);
    font-weight: normal;
  }

  a {
    display: inline-flex;
    align-content: center;
    align-items: center;
    gap: calc(var(--spacing) / 2);

    font-weight: bold;
  }
}

._fields_relatedArtworks {
}

._otherArtworks {
  > section {
    // margin-bottom: calc(var(--spacing) * 4);
  }
}

.scrollButton {
  background: white;
}

pre {
  max-width: 500px;
}

.customSelector {
  display: inline-flex;
}

.m_oeuvre {
  ::v-deep .RS {
    justify-content: flex-start;

    ._instaInstr > * {
      margin-left: 0;
    }
  }
}
</style>
