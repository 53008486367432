<template>
  <div class="m_allArtworks">
    <div class="titleBar">
      <div>
        <router-link to="/explorer">
          <template v-if="mode === 'all_artworks'">
            liste des œuvres
          </template>
          <template v-if="mode === 'date_creation'">
            date de création
          </template>
          <template v-else-if="mode === 'acquisition_year'">
            date d’acquisition
          </template>
          <template v-else-if="mode === 'medium'">
            par medium
          </template>
          <template v-else-if="mode === 'country'">
            par nationalité des artistes
          </template>
          <div v-if="is_loading" class="_loader" key="loader">…</div>
        </router-link>
      </div>
    </div>

    <div v-if="scroll_to_annee && is_loading" class="_loaderScrollAnnee">
      <div class="_loader" key="loader">
        Chargement…
      </div>
    </div>

    <section v-if="['date_creation', 'acquisition_year'].includes(mode)">
      <div
        v-for="[year, { label, artworks }] in artworks_by_mode"
        :key="year"
        :data-year="year"
        :ref="`year-${year}`"
        class="_byYear"
      >
        <!-- <h1 class="sectionTitle" v-html="year" /> -->

        <h2 class="_stickyTitle sectionTitle" v-html="label" />
        <div class="_list">
          <div v-for="artwork in artworks" :key="artwork.slug">
            <router-link
              :to="`/oeuvre/${artwork.slug}`"
              @click.native.prevent="
                navToOeuvre({ route: `/oeuvre/${artwork.slug}`, year })
              "
              event
              :title="artwork.title"
            >
              <PreviewOeuvre
                :oeuvre="artwork"
                :thumb_resolution="100"
                :show_title="false"
                :show_quick_actions="false"
              />
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else-if="mode === 'all_artworks'"
      :style="`--custom-width: ${debounced_thumb_width}px`"
    >
      <div v-if="is_loading" class="_loader" key="loader">
        Chargement…
      </div>
      <template v-else>
        <h1
          class="_stickyTitle sectionTitle"
          v-html="
            makeLabel({
              artworks: all_artworks,
              artworks_with_covers: all_artworks_with_cover,
            })
          "
        />

        <div class="_widthSelector">
          <label for="thumb_width">
            Taille des vignettes
          </label>
          <input
            type="range"
            id="thumb_width"
            name="thumb_width"
            min="15"
            max="500"
            v-model.lazy="debounced_thumb_width"
          />
        </div>
        <div class="_pages">
          <label>
            <!-- Page {{ current_page_number + 1 }}/{{ max_number_of_pages }}<br /> -->
            Oeuvres {{ current_page_number * items_per_page + 1 }} →
            {{
              current_page_number * items_per_page +
                1 +
                paginated_artworks_with_cover.length
            }}
          </label>
        </div>

        <div class="_list _list_AllAw">
          <button
            type="button"
            class="_pageBtn"
            @click="prevPage"
            v-if="current_page_number > 0"
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="15.75px"
              height="12px"
              viewBox="0 0 42 32.1"
              style="overflow:visible;enable-background:new 0 0 42 32.1;transform: rotate(0deg);"
              xml:space="preserve"
            >
              <polygon
                class="st0"
                style="fill: currentColor;"
                points="42,19.4 42,12.7 13.8,12.7 11.1,13 13.6,11.3 20.9,4.8 20.9,0 16,0 0,16.1 16,32.1 20.7,32.1
  	20.8,27.2 13.6,20.7 11.1,18.9 13.8,19.4 "
              />
            </svg>
            <span>page précédente</span>
          </button>

          <div
            v-for="artwork in paginated_artworks_with_cover"
            :key="artwork.slug"
          >
            <router-link
              :to="`/oeuvre/${artwork.slug}`"
              @click.native.prevent="
                navToOeuvre({ route: `/oeuvre/${artwork.slug}`, year })
              "
              event
              :title="artwork.title"
            >
              <PreviewOeuvre
                :oeuvre="artwork"
                :thumb_resolution="closest_thumb_resolution"
                :show_title="false"
                :show_quick_actions="debounced_thumb_width > 100"
              />
            </router-link>
          </div>

          <button
            type="button"
            class="_pageBtn"
            @click="nextPage"
            v-if="current_page_number + 1 < max_number_of_pages"
          >
            <span>page suivante</span>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="15.75px"
              height="12px"
              viewBox="0 0 42 32.1"
              style="overflow:visible;enable-background:new 0 0 42 32.1;transform: rotate(180deg);"
              xml:space="preserve"
            >
              <polygon
                class="st0"
                style="fill: currentColor;"
                points="42,19.4 42,12.7 13.8,12.7 11.1,13 13.6,11.3 20.9,4.8 20.9,0 16,0 0,16.1 16,32.1 20.7,32.1
  	20.8,27.2 13.6,20.7 11.1,18.9 13.8,19.4 "
              />
            </svg>
          </button>
        </div>
      </template>
    </section>
    <section v-else-if="mode === 'medium'">
      <Medium :artworks_by_mode="artworks_by_mode" />
    </section>
    <section v-else-if="mode === 'country'">
      <Country :artworks_by_mode="artworks_by_mode" />
    </section>
  </div>
</template>
<script>
import Medium from "./Medium.vue";
import Country from "./Country.vue";

export default {
  props: {
    mode: String,
  },
  components: {
    Medium,
    Country,
  },
  data() {
    return {
      // artworks_ids: [],
      all_artworks_temp: [],
      scroll_to_annee: false,

      is_loading: false,

      current_page_number: 0,
      items_per_page: 400,

      debounced_thumb_width: Math.min(200, this.$root.windowWidth / 6),
      debounce_timeout: null,
    };
  },
  created() {},
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.annee &&
      this.$route.query.annee !== "false"
    ) {
      this.scroll_to_annee = this.$route.query.annee;
    }

    if (this.$root.all_artworks.length === 0) {
      this.is_loading = true;

      let current_batch = 0;
      const items_per_batch = 300;

      const fetchArtworksByBatch = () => {
        current_batch += 1;
        const from = 5000 - current_batch * items_per_batch;
        if (from + items_per_batch > 0)
          // current_batch += 1;
          // const from = current_batch * items_per_batch;
          // if (from + items_per_batch < 2100)
          return this.fetchArtworks({
            size: items_per_batch,
            from,
            content: [
              "cover",
              "title",
              "acquisition_year",
              "date_creation",
              "medium",
              "authors_nationality",
            ],
          }).then((artworks) => {
            this.all_artworks_temp = this.all_artworks_temp.concat(artworks);
            return fetchArtworksByBatch();
          });
        else return;
      };

      fetchArtworksByBatch().then(() => {
        this.$root.all_artworks = this.all_artworks_temp;

        if (this.scroll_to_annee) {
          this.is_loading = false;
          this.$nextTick(() => {
            setTimeout(() => {
              this.scrollToYear(this.scroll_to_annee);
            }, 200);
          });
        } else {
          this.is_loading = false;
        }
      });
    } else {
      setTimeout(() => {
        this.scrollToYear(this.scroll_to_annee);
      }, 200);
    }
  },
  beforeDestroy() {},
  watch: {
    $route: {
      handler() {
        // if (
        //   this.$route.query &&
        //   this.$route.query.annee &&
        //   this.$route.query.annee !== "false"
        // ) {
        //   this.scroll_to_annee = this.$route.query.annee;
        // }
      },
      immediate: true,
    },
    "$root.scroll_value": {
      handler() {
        // if (!this.$el) return;
        // let active_year_tag = Array.from(
        //   this.$el.querySelectorAll("._byYear")
        // ).find((i) => {
        //   return i.offsetTop + i.offsetHeight > this.$root.scroll_value;
        // });
        // const new_year = active_year_tag.dataset.year;
        // if (
        //   new_year === this.scroll_to_annee ||
        //   new_year === this.$route.query.annee
        // )
        //   return;
        // console.log(`new_year ${new_year}`);
        // this.$router.replace({
        //   query: {
        //     mode: this.mode,
        //     annee: new_year,
        //   },
        // });
      },
      immediate: true,
    },
  },
  computed: {
    all_artworks() {
      return this.$root.all_artworks.length > 0
        ? this.$root.all_artworks
        : this.all_artworks_temp;
    },
    artworks_by_mode() {
      const aw = this.all_artworks;

      let _artworks_by_mode = aw.reduce((acc, artwork) => {
        let key;

        if (["date_creation", "acquisition_year"].includes(this.mode)) {
          const isValidYear = (value) =>
            /^\d+$/.test(value) &&
            Number.parseInt(value) > 0 &&
            Number.parseInt(value) < 2030;

          if (isValidYear(artwork[this.mode]))
            key = Number.parseInt(artwork[this.mode]);
        } else if (this.mode === "medium") {
          key = artwork["medium"];
        }

        if (key) {
          if (!Object.prototype.hasOwnProperty.call(acc, key)) acc[key] = [];
          acc[key].push(artwork);
        }

        return acc;
      }, {});

      _artworks_by_mode = Object.entries(_artworks_by_mode)
        .sort((a, b) => {
          if (["date_creation", "acquisition_year"].includes(this.mode))
            return a[0] - b[0];
          else if (["medium", "country"].includes(this.mode))
            return a[1].length - b[1].length;
        })
        .reverse()
        .map(([y, artworks]) => {
          const artworks_with_covers = artworks.filter((a) => a.cover);

          const oeuvres_count = this.makeLabel({
            artworks,
            artworks_with_covers,
          });
          const label = `${y}<small>${oeuvres_count}</small>`;

          return [
            y,
            {
              artworks: artworks_with_covers,
              label,
            },
          ];
        });

      return _artworks_by_mode;
    },
    all_artworks_with_cover() {
      return this.all_artworks.filter((a) => a.cover);
    },
    paginated_artworks_with_cover() {
      // .slice(0, 500)
      const from = this.current_page_number * this.items_per_page;
      const to = (this.current_page_number + 1) * this.items_per_page - 1;
      return this.all_artworks_with_cover.slice(from, to);
    },
    max_number_of_pages() {
      return Math.ceil(
        this.all_artworks_with_cover.length / this.items_per_page
      );
    },

    thumb_width: {
      get() {
        return this.debounced_thumb_width;
      },
      set(val) {
        this.is_loading = true;
        if (this.debounce_timeout) clearTimeout(this.debounce_timeout);
        this.debounce_timeout = setTimeout(() => {
          this.debounced_thumb_width = val;
          this.is_loading = false;
        }, 300);
      },
    },
    closest_thumb_resolution() {
      return [4000, 3000, 2000, 1000, 800, 400, 200, 100]
        .reverse()
        .find((res) => res > this.thumb_width * 1.5);
    },
    // paginated_artworks_ids() {
    //   const start_index = this.items_per_page * this.current_page_number;
    //   const end_index = this.items_per_page * (this.current_page_number + 1);
    //   return this.artworks_ids.slice(start_index, end_index);
    // },
  },
  methods: {
    scrollToYear(yr) {
      const $yr = this.$refs[`year-${yr}`];
      if ($yr) {
        const ot = $yr[0].offsetTop;
        // const oh = $yr[0].offsetHeight;
        // const scroll_window_height = 0;

        const scroll_value = ot + 1;
        console.log(
          `ALLARTWORKS / methods • scrollToYear will scroll to = ${scroll_value}`
        );

        window.scrollTo(0, scroll_value);
      }
    },
    navToOeuvre({ route, year }) {
      this.$router.replace({
        query: {
          mode: this.mode,
          annee: year,
        },
      });
      this.$router.push(route);
    },
    makeLabel({ artworks, artworks_with_covers }) {
      let label = `
          ${artworks.length} ${artworks.length > 1 ? "œuvres" : "œuvre"}`;

      if (artworks_with_covers.length === 0)
        label = `<span>${label} sans visuels</span>`;
      else if (artworks.length !== artworks_with_covers.length)
        label += ` <span>dont ${artworks.length -
          artworks_with_covers.length} sans visuels</span>`;
      return label;
    },

    nextPage() {
      window.scrollTo(0, 0);
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.current_page_number += 1;
        });
      });
    },
    prevPage() {
      this.current_page_number -= 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.m_allArtworks {
  min-height: calc(100vh - 70px);
  // padding: calc(var(--spacing) / 2);
}

._byYear {
  // margin-bottom: calc(var(--spacing) * 2);
}
._stickyTitle {
  display: block;
  position: sticky;
  top: 0;
  padding: calc(var(--spacing) * 3) 0 calc(var(--spacing) * 1);
  margin-bottom: 0;
  z-index: 1;

  background: white;
}

._list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  gap: calc(var(--spacing) * 2) calc(var(--spacing) / 2);

  > * {
    // padding: calc(var(--spacing) / 4);
    padding: 0;
    // width: 10px;
    // width: calc((var(--s) + 1) * 100px);
    width: 50px;
    // min-height: 50px;
    height: auto;
  }

  ::v-deep {
    a {
      text-decoration: none;
    }
  }

  // ::v-deep {
  //   ._stickyTitle,
  //   .m_oeuvre--content--caption {
  //     display: none;
  //   }
  // }
}

._loaderScrollAnnee {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1000;

  display: flex;
  place-items: center center;
}

._list_AllAw {
  gap: calc(var(--spacing) * 4) calc(var(--spacing) * 1);
  gap: min(var(--custom-width) / 2, var(--spacing) * 4)
    min(var(--custom-width) / 10, var(--spacing));
  padding: 0 calc(min(var(--custom-width) / 10, var(--spacing)) * 2);

  > * {
    width: var(--custom-width);

    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

._widthSelector {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: calc(var(--spacing) / 2);
  margin: calc(var(--spacing) * 1) 0;

  h1 {
    ::v-deep span {
      font-weight: normal;
    }
  }
}

._pages {
  text-align: center;
  margin: calc(var(--spacing) * 1) 0;
}
._pageBtn {
  flex: 0 0 auto;
  width: auto;
  margin: 0;
  margin: 0 calc(var(--spacing) / 2);
  padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);
  background: transparent;
  align-self: center;

  display: flex;
  flex-flow: row nowrap;

  gap: calc(var(--spacing) / 2);

  font-weight: bold;

  span {
    white-space: nowrap;
  }

  svg {
  }
}
</style>
