<template>
  <span class="_statusLabel" :data-status="status">{{ status }}</span>
</template>
<script>
export default {
  props: { status: String },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
._statusLabel {
  background: var(--light-gray);
  color: white;
  background: var(--dark-gray);
  padding: 0 calc(var(--spacing) / 2);
  border-radius: 1em;

  &[data-status="à venir"] {
    background: var(--yellow);
    color: black;
  }
  &[data-status="nouveau"] {
    background: var(--yellow);
    color: black;
  }
  &[data-status="en cours"] {
    background: var(--blue);
  }
  &[data-status="archivé"] {
    // background: var(--blue);
  }
}
</style>
