<template>
  <Modal @close="$emit('close')">
    <template v-slot:body>
      <!-- si plusieurs oeuvre, création d'un album -->
      <template v-if="artwork_ids">
        <!-- 1. se logger/créer compte 2. créer album avec un nom custom -->
        <ListOfArtworks
          :layout="'tiny'"
          :thumb_resolution="100"
          class="_currentAlbum"
          :ids="artwork_ids"
          :show_artworks_title="false"
          :show_authors="false"
        />

        <template v-if="$root.is_logged_in === false">
          <p>
            <template v-if="artwork_ids">
              Tu dois avoir un compte pour enregistrer cet album ou le partager.
              L’inscription est gratuite et te permettra aussi de choisir une
              œuvre qui pourra faire partie du prochain accrochage !
            </template>
          </p>

          <CreateOrLogin />
        </template>
        <template v-else>
          <div class="fillField">
            <label for="ALBUM_NAME">
              Nom de ce nouvel album
            </label>
            <input
              type="text"
              id="ALBUM_NAME"
              name="ALBUM_NAME"
              class="is--required"
              required=""
              v-model="new_album_name"
              maxlength="40"
            />
            <small
              class="_maxlength"
              v-if="new_album_name.length"
              v-html="new_album_name.length + '<' + 40"
            />
          </div>
        </template>
      </template>
      <!-- si une oeuvre, ajout à un album existant -->
      <template v-else>
        <!-- on est forcément loggé donc -> ajout oeuvre album existant -->
        <div class="oeuvreCard" v-if="artwork">
          <PreviewOeuvre
            :id="artwork.slug"
            :oeuvre="artwork"
            :show_quick_actions="false"
            :thumb_resolution="200"
          />
        </div>

        <div class="_albumSelect">
          <div class="radioSwitch">
            <label
              :for="opt.key"
              v-for="opt in [
                {
                  key: 'create_album',
                  label: 'Créer un album avec cette oeuvre',
                },
                {
                  key: 'append_to_album',
                  label: 'Ajouter cette oeuvre à un album existant',
                },
              ]"
              :key="opt.key"
              :class="{
                'is--active': opt.key === mode,
              }"
            >
              <input
                type="radio"
                :id="opt.key"
                :name="opt.key"
                :value="opt.key"
                v-model="mode"
              />
              <span>
                {{ opt.label }}
              </span>
            </label>
          </div>

          <div v-if="mode === 'create_album'">
            <div class="fillField">
              <label for="ALBUM_NAME">
                Nom du nouvel album
              </label>
              <input
                type="text"
                id="ALBUM_NAME"
                name="ALBUM_NAME"
                class="is--required"
                required=""
                v-model="new_album_name"
                maxlength="40"
              />
              <small
                class="_maxlength"
                v-if="new_album_name.length"
                v-html="new_album_name.length + '<' + 40"
              />
            </div>
          </div>
          <div v-else-if="mode === 'append_to_album'">
            <template v-if="albums.length === 0">
              Tu n’as pas encore créé d’albums
            </template>
            <template v-else>
              Liste de mes albums
              <div class="customSelector">
                <select v-model="selected_album_id">
                  <option value="" selected disabled
                    >Choisissez un album</option
                  >
                  <option
                    v-for="(album, index) in albums"
                    :key="index"
                    :value="album.slug"
                    :disabled="albumIncludesArtwork(album)"
                  >
                    {{ album.title }}
                    <template v-if="albumIncludesArtwork(album)"
                      >(cet album contient déjà cette œuvre)</template
                    >
                  </option>
                </select>
              </div>
            </template>
          </div>
        </div>
      </template>
    </template>

    <template v-slot:buttons v-if="$root.is_logged_in">
      <button type="button" @click="$emit('close')">
        Annuler
      </button>

      <button
        type="button"
        class="greenBtn"
        :class="{
          'is--saving': is_saving,
        }"
        :disabled="disable_save_button"
        @click="createAlbum"
      >
        <div v-if="is_saving" class="_loader" key="loader">
          Chargement…
        </div>
        Valider
      </button>
    </template>
  </Modal>
</template>
<script>
import Modal from "../subcomponents/Modal.vue";
import CreateOrLogin from "./CreateOrLogin.vue";

export default {
  props: { artwork: Object, artwork_ids: Array },
  components: {
    CreateOrLogin,
    Modal,
  },
  data() {
    return {
      is_saving: false,
      selected_album_id: "",

      mode: "create_album",
      new_album_name: "",

      albums:
        this.$root.user_login.albums.length > 0
          ? this.$root.user_login.albums
          : [],
    };
  },
  created() {},
  mounted() {
    // if (this.albums.length > 0) this.selected_album_id = this.albums[0].slug;
    if (this.$root.last_album_created_or_added_to) {
      const album = this.albums.find(
        (a) => a.slug === this.$root.last_album_created_or_added_to
      );
      if (album && !this.albumIncludesArtwork(album)) {
        this.selected_album_id = this.$root.last_album_created_or_added_to;
        this.mode = "append_to_album";
      }
    }
  },
  beforeDestroy() {},
  watch: {},
  computed: {
    disable_save_button() {
      if (this.is_saving) return true;
      if (this.mode === "append_to_album")
        if (this.albums.length === 0) return true;
        else if (this.selected_album_id === "") return true;
      return false;
    },
  },
  methods: {
    albumIncludesArtwork(album) {
      if (album.artworks) return album.artworks.includes(this.artwork.slug);
      return false;
    },
    createAlbum() {
      this.is_saving = true;

      // possibilités :
      //
      // mode === 'create_album' -> artwork_ids = créer un album à partir de la liste
      // mode === 'create_album' -> artwork = créer un album à partir d’une oeuvre
      // mode === 'append_to_album' -> artwork = ajouter à la fin d’un album

      const artworks = this.artwork_ids
        ? this.artwork_ids
        : [this.artwork.slug];

      if (this.mode === "create_album") {
        this.createUserAlbum({
          title: this.new_album_name,
          artworks,
          username: this.$root.user_login.name,
          userid: this.$root.user_login.id,
        })
          .then((new_album_id) => {
            this.is_saving = false;
            this.$root.artwork_temp_album = [];
            this.$router.push({
              path: `/album/${new_album_id}`,
            });

            this.$root.last_album_created_or_added_to = new_album_id;
          })
          .catch((err) => {
            this.is_saving = false;
            this.$toast.error(`L’album n’a pas été créé : ${err}`);
          });
      } else if (this.mode === "append_to_album") {
        // TODO append to album by using PATCH
        const album = this.$root.user_login.albums.find(
          (a) => a.slug === this.selected_album_id
        );
        if (!album) this.$toast.error(`Album introuvable`);

        let artworks;
        if (album.artworks) artworks = album.artworks.slice();

        console.log(
          `AjouterAlbum / createAlbum • existing artworks ${artworks}`
        );
        artworks.push(this.artwork.slug);
        console.log(
          `AjouterAlbum / createAlbum • new artworks list ${artworks}`
        );

        this.editMyAlbum({
          album_id: this.selected_album_id,
          data: {
            artworks: artworks.join(","),
            // title: "Test API",
          },
        })
          .then((response) => {
            // this.is_saving = false;
            const album_id = response.data.slug;

            // const url_to_album = this.$router.resolve({
            //   path: `/album/${album_id}`,
            // });

            this.$toast.success(`
            L’album ${response.data.title} a été mis à jour.
            `);

            debugger;
            setTimeout(() => {
              this.$emit("close");
            }, 1000);

            // this.$router.push({
            //   path: `/album/${album_id}`,
            // })
            // .catch((err) => {
            //   if (err.message.startsWith("Avoided redundant navigation"))
            //     this.$router.go();
            // });

            this.$root.last_album_created_or_added_to = album_id;
          })
          .catch((err) => {
            this.is_saving = false;
            this.$toast.error(`L’album n’a pas été créé : ${err}`);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
._currentAlbum {
  pointer-events: none;

  ::v-deep {
    .router-link-exact-active {
      .m_oeuvre--content--image {
        &::before {
          display: none;
        }
      }
    }
  }
}

._albumSelect {
  // display: flex;
  // flex-flow: row wrap;

  > * {
    // flex: 1 1 120px;
  }
}
</style>
