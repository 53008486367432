<template>
  <div class="m_searchBar">
    <form
      role="search"
      method="get"
      class="searchBar"
      @submit.prevent="submitSearch"
      :class="{
        'is--active': make_search_button_active,
      }"
    >
      <input
        type="text"
        placeholder="Rechercher une œuvre ou un·e artiste dans la collection"
        v-model="search_string"
        ref="searchField"
      />
      <button
        type="submit"
        class="searchBar--icon"
        :disabled="search_string.length === 0"
      >
        <span v-if="search_string.length > 0">Rechercher</span>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="24.4px"
          height="24.4px"
          viewBox="0 0 24.4 24.4"
          style="overflow:visible;enable-background:new 0 0 24.4 24.4;"
          xml:space="preserve"
        >
          <path
            d="M24.2,21.1L18.1,15c1-1.6,1.5-3.5,1.5-5.3c0-5.4-4.3-9.7-9.8-9.7C4.2,0,0,4.5,0,9.8c0,5.4,4.4,9.8,9.9,9.8
	c1.9,0,3.6-0.5,5.2-1.5l6,6c0.3,0.2,0.6,0.2,0.9,0l2.2-2.2C24.4,21.7,24.4,21.3,24.2,21.1 M9.9,18.4c-4.8,0-8.6-3.9-8.6-8.6
	c0-4.6,3.7-8.5,8.6-8.5c4.8,0,8.4,3.7,8.5,8.4C18.4,14.7,14.4,18.4,9.9,18.4 M21.5,22.8l-5.5-5.5c0.2-0.2,1.1-1,1.3-1.3l5.5,5.5
	L21.5,22.8z"
          />
        </svg>
      </button>
    </form>

    <template v-if="show_advanced_search_button">
      <div
        class="_advSearchLink"
        :class="{
          'is--invisible': !(search_string && search_string.length > 0),
        }"
        v-if="$route.name !== 'Rechercher'"
      >
        <router-link :to="`/rechercher/${search_string}?advanced`">
          recherche avancée
        </router-link>
      </div>

      <div
        v-else
        class="_searchPane"
        :class="{
          'is--active': show_advanced_options,
        }"
      >
        <div class="_advSearchButton" v-if="!show_advanced_options">
          <button
            type="button"
            :class="{
              'is--active': show_advanced_options,
            }"
            @click="show_advanced_options = !show_advanced_options"
          >
            recherche avancée
          </button>
        </div>

        <div v-if="show_advanced_options">
          <button
            type="button"
            class="_advSearchButton--closeBtn"
            @click="show_advanced_options = false"
          >
            ×
          </button>

          <h3>Options de recherche avancées</h3>

          <div class="_advOptsBtns">
            <button type="button">Artistes</button>
            <button type="button">Œuvres</button>
            <button type="button">Medium</button>
            <button type="button">Années de création</button>
            <button type="button">Années d’acquisition</button>
            <button type="button">Nationalité</button>
          </div>
        </div>
      </div>
    </template>
    <!-- <div class="searchSuggestions">
            Par exemple&nbsp;:&nbsp;
            <a href="#" @click="setSearchString('Marc Couturier')"
              >Marc Couturier</a
            >,&nbsp;
            <a href="#" @click="setSearchString('art abstrait')"
              >art abstrait </a
            >, ou&nbsp;
            <a href="#" @click="setSearchString('nouvelles acquisitions')"
              >nouvelles acquisitions</a
            >.
          </div> -->
  </div>
</template>
<script>
export default {
  props: {
    initial_search_string: String,
    focus: {
      type: Boolean,
      default: false,
    },
    search_button_can_be_active: {
      type: Boolean,
      default: true,
    },
    show_advanced_search_button: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      search_string: "",
      show_advanced_options: false,
    };
  },
  created() {},
  mounted() {
    if (this.focus) this.$refs.searchField.focus();
    if (Object.hasOwn(this.$route.query, "advanced"))
      this.show_advanced_options = true;
  },
  beforeDestroy() {},
  watch: {
    initial_search_string: {
      handler() {
        this.search_string = this.initial_search_string || "";
      },
      immediate: true,
    },
  },
  computed: {
    make_search_button_active() {
      // if (this.$route.name === "Accueil") return false;
      if (
        this.search_string === this.$route.params.str ||
        this.search_string === ""
      )
        return false;
      return true;
    },
  },
  methods: {
    submitSearch() {
      this.search_string = this.search_string.trim();
      this.$router.push({
        path: `/rechercher/${encodeURIComponent(this.search_string)}`,
        props: true,
      });
      return;
    },
    setSearchString(val) {
      this.search_string = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.m_searchBar {
  position: relative;
}

.searchBar {
  border-radius: 25px;
  background: white;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-shadow: 0 0 1rem 0rem rgba(41, 41, 41, 0.25);
  padding: calc(var(--spacing) / 4);

  input {
    border: none;
    margin: 0;
    background: transparent;

    $t-inputp: 0.6rem;
    padding: $t-inputp 0rem $t-inputp $t-inputp * 2;
    // font-size: 1.2em;

    &:not(:placeholder-shown) {
      // font-weight: bold;
    }
  }

  .searchBar--icon {
    display: flex;
    padding: calc(var(--spacing) / 2) calc(var(--spacing) / 2);

    text-transform: lowercase;
    gap: calc(var(--spacing) / 2);

    background: transparent;
    border-radius: 25px;

    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    // font-size: 0;
  }

  &.is--active {
    .searchBar--icon {
      padding: calc(var(--spacing) / 2) calc(var(--spacing) / 1);
      background: var(--active-color);
      fill: white;
      color: white;
      font-size: inherit;
    }

    &:hover,
    &:focus {
      background: var(--gray-background);
      font-size: inherit;
    }
  }
}

.searchSuggestions {
  padding: 1em;
  text-align: center;

  // a {
  //   text-decoration: none;
  // }
}

._advSearchLink {
  padding: 0 var(--spacing);
  font-size: var(--fs-small);
  text-align: center;
  transition: all 1.4s cubic-bezier(0.19, 1, 0.22, 1);
  // background: white;

  &.is--invisible {
    opacity: 0;
  }
}
._advSearchButton {
  text-align: center;
  padding: calc(var(--spacing) / 2);

  button {
    margin: auto;
  }
}

._searchPane {
  position: relative;
  border-radius: calc(var(--spacing) / 4);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  margin: 0 calc(var(--spacing) * 2);
  padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2)
    calc(var(--spacing) / 2);
  // text-align: center;

  &.is--active {
    background: white;
    margin: calc(var(--spacing) * 1) calc(var(--spacing) * 2);
  }

  h3 {
    margin-bottom: calc(var(--spacing) / 2);
  }
}

._advOptsBtns {
  display: flex;
  gap: calc(var(--spacing) / 2);
}

._advSearchButton--closeBtn {
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  padding: calc(var(--spacing) / 2);
  margin: 0;
  line-height: 0.5;
}
</style>
