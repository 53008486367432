<template>
  <div class=" m_rechercher">
    <div class="contentBloc limitedWidth">
      <SearchBar
        class="_searchBar limitedWidth"
        :initial_search_string="search_string"
        :focus="focus_field"
      />
    </div>
    <transition name="fade" mode="out-in">
      <SearchResults
        v-if="search_string !== ''"
        :key="search_string"
        :term="search_string"
      />
    </transition>
  </div>
</template>
<script>
import SearchBar from "../components/subcomponents/SearchBar.vue";
import SearchResults from "../components/subcomponents/SearchResults.vue";

export default {
  props: {},
  components: {
    SearchBar,
    SearchResults,
  },
  data() {
    return {
      search_string: "",
      focus_field: this.$route.params.str ? false : true,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {
    "$route.params.str": {
      handler() {
        if (this.$route.params.str) this.search_string = this.$route.params.str;
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_rechercher {
  > * {
    margin: calc(var(--spacing) * 2) auto;
  }
}
</style>
