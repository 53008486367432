export default {
  computed: {},
  methods: {
    fetchArtist({ id }) {
      return new Promise((resolve, reject) => {
        // fetch(`https://api.stg.navigart.fr/39/authors/${this.id}`, {
        const path = `/authors/${id}?extend=children,files,authors`;
        console.log(`ROOT / fetchArtist • fetching = ${path}`);

        if (this.$root.is_debug) {
          // this.$toast(`fetching artist ${id}`);
        }

        this.fetchPath({
          path,
        })
          .then((response) => {
            let artist = this.prepareArtist({ artist: response });

            return resolve({
              artist,
            });
          })
          .catch((err) => {
            console.log(
              `ROOT / fetchArtist • fetching id = ${id} failed with error ${err}`
            );
            return reject(err);
          });
      });
    },
    prepareArtist({ artist }) {
      const _artist = {};
      _artist.ogData = artist;

      if (artist.data?.title) _artist.title = artist.data.title;
      if (artist.data?.slug) _artist.slug = artist.data.slug;
      if (artist.content?.name_list)
        _artist.name_list = artist.content.name_list.value;

      if (artist.content?.related) {
        _artist.oeuvres = artist.content.related.yaml.reduce((acc, r) => {
          if (r.startsWith("artworks")) acc.push(r.replace("artworks/", ""));
          return acc;
        }, []);

        if (artist.content?.main_text?.blocksToHtml)
          _artist.main_text = artist.content.main_text.blocksToHtml;

        _artist.related = artist.content.related.yaml;
      }
      return _artist;
    },
    fetchAllArtists() {
      return new Promise((resolve, reject) => {
        // TODO replace with equivalent on sdtr api
        // const fetch_URL = `https://sdtr.fraciledefrance.com/authors/?size=0&term=authors&term_from=0&term_order=count:desc&term_size=10000`;
        const path = `/authors?extend=children.content,children.data&content=name_list`;
        console.log(`ROOT / fetchAllArtists • fetching = ${path}`);

        if (this.$root.is_debug) {
          // this.$toast(`fetching all artists`);
        }

        this.fetchPath({
          path,
        })
          .then((response) => {
            let all_artists = Object.values(response.children).map((artist) =>
              this.prepareArtist({ artist })
            );

            all_artists.sort((a, b) => {
              if (a.name_list && b.name_list)
                return a.name_list.localeCompare(b.name_list, undefined, {
                  sensitivity: "base",
                });
              return false;
            });

            return resolve({
              all_artists,
            });
          })
          .catch((err) => {
            console.log(
              `ROOT / fetchAllArtists • fetching failed with error ${err}`
            );
            return reject(err);
          });
      });
    },
    fetchArtistNotice({ artist }) {
      return new Promise((resolve, reject) => {
        if (artist.oeuvres && artist.oeuvres.length > 0) {
          this.fetchArtworks({ id: artist.oeuvres[0] })
            .then((artworks) => {
              if (artworks[0].artist_notes)
                return resolve(artworks[0].artist_notes);
              return reject();
            })
            .catch(() => {
              return reject();
            });
        } else {
          return reject();
        }
      });
    },
  },
};
