<template>
  <div
    class="m_oeuvre"
    @mouseenter.self="is_hovered = true"
    @mouseleave.self="is_hovered = false"
  >
    <div class="m_oeuvre--content">
      <div class="m_oeuvre--content--image">
        <transition name="fade">
          <div v-if="is_loading || !artwork" class="_loader" key="loader">
            Chargement…
          </div>
          <div v-else key="qa" class="_content">
            <!-- {{ artwork }} -->
            <QuickOeuvreActions
              v-if="show_quick_actions"
              class="m_oeuvre--content--quickActions"
              :id="id"
              :class="{
                'is--visible': is_hovered,
              }"
              :artwork="artwork"
            />
            <!-- :srcset="srcset" -->
            <img
              v-if="small_thumb"
              :src="small_thumb"
              loading="lazy"
              :class="{
                'is--hidden': !image_is_loaded,
              }"
              @load="imageIsLoaded"
            />
            <span v-else class="noimage">
              aucune image à afficher ;-(
            </span>
          </div>
        </transition>
      </div>
      <div class="m_oeuvre--content--caption" v-if="show_title">
        <div v-if="is_loading || !artwork" class="_textloader"></div>
        <template v-else>
          <div
            class="_title to-ellipsis"
            v-html="$root.highlightSearchedTerm(artwork.title)"
          />
          <div
            class="_authors to-ellipsis"
            v-if="artwork.authors && show_authors"
          >
            <span v-for="({ name }, index) in artwork.authors" :key="index">
              <!-- {{ `/oeuvre/${author._id}` }} -->
              <template v-if="index > 0">,&#32;</template>
              <template v-if="name && name.notice">{{ name.notice }}</template>
            </span>
          </div>
          <!-- {{ artwork }} -->
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import QuickOeuvreActions from "./QuickOeuvreActions.vue";

export default {
  name: "PreviewOeuvre",
  props: {
    id: String,
    oeuvre: Object,
    thumb_resolution: {
      type: Number,
      default: 800,
    },
    show_quick_actions: {
      type: Boolean,
      default: true,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
    show_authors: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    QuickOeuvreActions,
  },
  data() {
    return {
      artwork: undefined,
      is_loading: false,
      is_hovered: false,
      image_is_loaded: false,
    };
  },
  created() {
    if (this.oeuvre) {
      this.artwork = this.oeuvre;
      // this.is_loading = true;
      // setTimeout(() => {
      //   this.is_loading = false;
      // }, Math.random() * 200);
    } else {
      this.is_loading = true;
      this.fetchArtworks({ id: this.id }).then((artworks) => {
        this.artwork = artworks[0];
        this.is_loading = false;
      });
    }
  },
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    srcset() {
      if (!this.artwork.cover) return false;

      const srcset_arr = Object.entries(this.artwork.cover).reduce(
        (acc, [res, url]) => {
          acc.push(`${url} ${res}w`);
          return acc;
        },
        []
      );
      return srcset_arr.join(",\n");
    },

    small_thumb() {
      if (!this.artwork.cover) return false;
      const thumbs = this.artwork.cover;
      return thumbs[this.thumb_resolution];
    },
  },
  methods: {
    imageIsLoaded() {
      this.image_is_loaded = true;
      this.$emit("imageLoaded");
    },
  },
};
</script>
<style lang="scss" scoped>
.m_oeuvre {
  position: relative;
  z-index: 0;

  &:hover {
    // .m_oeuvre--content--image {
    //   background: white;

    //   img {
    //     &:hover {
    //       // opacity: 0.9;
    //     }
    //   }
    // }
  }
}
.m_oeuvre--content {
  position: relative;
}

.m_oeuvre--content--caption {
  text-align: left;
  margin-top: calc(var(--spacing) / 2);

  > * {
    margin-bottom: 0.15rem;
  }
}

.m_oeuvre--content--image {
  position: relative;
  overflow: hidden;
  img {
    width: auto;
    opacity: 1;
    transition: 1s opacity cubic-bezier(0.19, 1, 0.22, 1);

    &.is--hidden {
      opacity: 0.01;
    }
    // height: 100%;
  }
}

.m_oeuvre--content--quickActions {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  margin: 1rem;

  transform: translate(0.5rem, -0.5rem) scale(0.9);
  opacity: 0;
  transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);

  &.is--visible {
    transform: translate(0, 0%) scale(1);
    opacity: 1;
  }
}

._loader {
  position: absolute;
  inset: 0;
}
._content {
  height: 100%;
  // background: var(--gray-background);
  color: var(--text-gray);
}
</style>
