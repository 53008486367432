<template>
  <div class="m_home">
    <component :is="`style`" v-html="inject_text_color_for_hero" />
    <div
      class="m_home--hero"
      :class="{
        'is--black': text_color_in_hero_is_black,
        'is--loading': is_loading,
      }"
    >
      <figure
        v-if="cover_image.path"
        class="m_home--hero--caption"
        @click="show_caption = !show_caption"
      >
        <transition name="appear">
          <img
            v-show="cover_image.show"
            :src="cover_image.path"
            alt=""
            @load="coverImageIsLoaded"
          />
        </transition>
        <figcaption v-if="cover_image.caption">
          <span
            v-if="show_caption"
            @click="show_caption = !show_caption"
            v-html="cover_image.caption"
          />
        </figcaption>
      </figure>

      <div class="_callToArms">
        <div class="_callToArms--content">
          <div class="_callToArms--content--title">
            <h1>
              <SDTRLogo
                class="_logo"
                v-if="show_logo"
                :staggered_animation="true"
              />
            </h1>
            <h2
              class="_willReveal"
              :class="{
                'is--hidden': !show_text,
              }"
            >
              Choisis l’œuvre que tu veux exposer !
            </h2>
            <router-link
              to="/participer"
              class="showMoreInfos _willReveal"
              :class="{
                'is--hidden': !show_text,
              }"
            >
              règles du jeu
            </router-link>
          </div>

          <SearchBar
            class="_searchBar _willReveal"
            :class="{
              'is--hidden': !show_text,
            }"
          />

          <div
            class="_callToArms--content--explore _willReveal"
            :class="{
              'is--hidden': !show_text,
            }"
          >
            ou
            <router-link to="/explorer">explorer la collection</router-link>
            par dates, medium, ou artistes&nbsp;!
          </div>

          <transition name="slideup">
            <button
              type="button"
              class="scrollButton"
              @click="$root.scrollABit"
              v-if="!has_scrolled && show_scroll_button"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="30px"
                height="24px"
                viewBox="0 0 42 32.1"
                style="overflow:visible;enable-background:new 0 0 42 32.1;transform: rotate(270deg);"
                xml:space="preserve"
              >
                <polygon
                  class="st0"
                  style="fill: currentColor;"
                  points="42,19.4 42,12.7 13.8,12.7 11.1,13 13.6,11.3 20.9,4.8 20.9,0 16,0 0,16.1 16,32.1 20.7,32.1
  	20.8,27.2 13.6,20.7 11.1,18.9 13.8,19.4 "
                />
              </svg>
            </button>
          </transition>
        </div>
      </div>
    </div>

    <div class="sectionSeparator" />

    <div class="m_accrochage">
      <AccrochageEnCours />
    </div>

    <div class="sectionSeparator" />

    <template v-if="has_scrolled">
      <template v-if="frac_albums_to_list.length > 0">
        <h2 class="sectionTitle">Les albums du frac</h2>
        <p class="sectionDesc">
          <router-link
            :to="`/explorer?mode=frac_albums`"
            class="_showAllAlbums"
          >
            Afficher tous les albums du frac
          </router-link>
        </p>

        <AlbumsList
          class="ta-center"
          :from_frac="true"
          :albums_uid="frac_albums_to_list"
          :reverse_order="false"
        />
      </template>

      <div
        v-if="frac_albums_to_list.length > 0 && user_albums_to_list.length > 0"
        class="sectionSeparator"
      />

      <template v-if="user_albums_to_list.length > 0">
        <h2 class="sectionTitle">Albums des visiteurs</h2>
        <p class="sectionDesc">
          <router-link
            :to="`/explorer?mode=user_albums`"
            class="_showAllAlbums"
          >
            Afficher tous les albums
          </router-link>
        </p>
        <AlbumsList
          :from_frac="false"
          :albums_uid="user_albums_to_list"
          :reverse_order="false"
        />
      </template>
    </template>
  </div>
</template>
<script>
import SearchBar from "../components/subcomponents/SearchBar.vue";
import AlbumsList from "../components/subcomponents/AlbumsList.vue";
import AccrochageEnCours from "../components/subcomponents/AccrochageEnCours.vue";

export default {
  props: {},
  components: {
    SearchBar,
    AlbumsList,
    AccrochageEnCours,
  },
  data() {
    return {
      cover_image: {
        path: false,
        caption: false,
        show: false,
      },

      show_logo: false,
      show_text: false,
      show_caption: false,

      show_scroll_button: false,
      has_scrolled: false,

      frac_albums_to_list: [],

      text_color_in_hero_is_black: false,

      is_loading: true,
    };
  },
  created() {},
  mounted() {
    if (!this.has_seen_home_animation) {
      this.show_logo = true;
      this.show_text = true;
      this.has_seen_home_animation = true;
    }

    this.fetchHomeData().then(() => {
      // this.is_loading = false;
      setTimeout(() => (this.show_scroll_button = true), 5000);
    });
  },
  beforeDestroy() {},
  watch: {
    "$root.scroll_value": {
      handler() {
        if (this.$root.scroll_value > 100 && !this.has_scrolled) {
          this.has_scrolled = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    inject_text_color_for_hero() {
      if (this.text_color_in_hero_is_black)
        return `
            body {
              --text-color-for-hero: black;
              --topbar-color-when-scrolled: white;
            }
      `;

      return `
            body {
              --text-color-for-hero: white;
              --topbar-color-when-scrolled: black;
            }
      `;
    },
  },
  methods: {
    fetchHomeData() {
      return new Promise((resolve, reject) => {
        const path = `/`;

        if (this.$root.is_debug) {
          // this.$toast(`fetching home infos`);
        }
        this.fetchPath({
          path,
        })
          .then((response) => {
            if (response.content?.cover?.yaml)
              // response.content.cover formatted as list such as "- 2g09615.jpg"
              this.cover_image.path =
                this.$root.url_to_sdtr_api +
                "/home/" +
                response.content?.cover?.yaml;
            if (response.content?.caption?.kirbytext)
              this.cover_image.caption = response.content?.caption?.kirbytext;

            if (
              response.content?.text_color_in_hero_is_black?.boolean === "true"
            )
              this.text_color_in_hero_is_black = true;

            if (response.content?.frac_albums_to_display?.yaml)
              this.frac_albums_to_list = response.content.frac_albums_to_display.yaml.map(
                (a) => a.replace("albums-du-frac/", "")
              );

            if (response.content?.user_albums_to_display?.yaml) {
              let user_albums_to_list = response.content.user_albums_to_display.yaml.map(
                (a) => a.replace("albums-des-visiteurs/", "")
              );

              const shuffleArray = (array) => {
                for (let i = array.length - 1; i > 0; i--) {
                  const j = Math.floor(Math.random() * (i + 1));
                  const temp = array[i];
                  array[i] = array[j];
                  array[j] = temp;
                }
                return array;
              };

              this.user_albums_to_list = shuffleArray(
                user_albums_to_list
              ).slice(-10);
            }
            return resolve();
          })
          .catch(() => {
            return reject();
          });
      });
    },
    coverImageIsLoaded() {
      this.cover_image.show = true;
      setTimeout(() => {
        this.show_logo = true;
        setTimeout(() => {
          this.show_text = true;
        }, 2000);
      }, 400);
    },
  },
};
</script>
<style lang="scss" scoped>
.m_home {
  // background: black;
}

.m_home--hero {
  position: relative;
  // margin-bottom: 3rem;
  // pointer-events: none;
  background: var(--topbar-color-when-scrolled);
  color: var(--text-color-for-hero);

  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  // &.is--loading {
  //   opacity: 0;
  // }

  // height: 80vh;

  ::v-deep a {
    text-decoration: underline;
  }

  img {
    pointer-events: none;
  }

  .m_home--hero--caption {
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    user-select: none;

    figcaption {
      position: absolute;
      bottom: 0%;
      right: 0;
      width: 80%;
      pointer-events: auto;
      text-align: right;
      z-index: 1;
      padding-right: calc(var(--spacing) / 2);

      ::v-deep p {
        margin: 0;
      }

      ._showCaptionButton {
        margin-left: auto;
        background: transparent;
        // color: var(--dark-gray);
        // border: 1px solid currentColor;
        width: 1rem;
        height: 1rem;
        // padding: calc(var(--spacing) / 2);
      }
    }
  }

  img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

._callToArms {
  position: relative;
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  // background: blue;

  pointer-events: none;

  .m_home--hero.is--black & {
    background: rgba(255, 255, 255, 0.4);
  }
  .m_home--hero:not(.is--black) & {
    background: rgba(0, 0, 0, 0.4);
  }
}
._callToArms--content {
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 700px;
  padding: calc(var(--spacing) * 1);
  width: 100%;
  color: currentColor;

  pointer-events: auto;
}

._callToArms--content--title {
  text-align: center;
  margin: auto;
  margin-bottom: calc(var(--spacing) * 1);
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);

  ._logo {
    max-width: 680px;
    max-height: 40vh;
  }

  h1 {
  }
  h2 {
    margin-top: 5vh;
    font-size: var(--fs-larger);
    font-weight: normal;
  }
}

.showMoreInfos {
  font-size: 0.8rem;
  // color: var(--text-gray);
}

._callToArms--content--explore {
  padding: 1em;
  font-size: 1.25rem;
  text-align: center;
}

._willReveal {
  transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1);

  &.is--hidden {
    opacity: 0;
  }
}
._searchBar {
  max-width: 420px;
  margin: 0 auto;
}

.scrollButton {
  color: white;
}

._showAllAlbums {
}
</style>
