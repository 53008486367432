<template>
  <div class="m_notice">
    <template v-if="notes || main_text">
      <button
        type="button"
        v-if="collapsed_by_default"
        @click="show_notice = !show_notice"
        :class="{
          'is--active': show_notice,
        }"
        v-html="'notice'"
      />
      <div
        :class="{
          moreInfos: collapsed_by_default,
        }"
        v-if="show_notice"
      >
        <div v-if="main_text" v-html="main_text" class="mainText" />
        <div
          v-else
          v-for="(note, index) in notes"
          :key="index"
          class="mainText fromNavigart"
        >
          <h3 v-html="note.title" />
          <transition name="fade-fast" mode="out-in">
            <div
              v-if="!(note.full_text && note.full_text.content)"
              key="extract"
            >
              <span v-html="extract(note)" />
              <button
                type="button"
                class="_readFullNotice"
                @click="readFullNotice(note.id)"
              >
                lire la suite
              </button>
            </div>
            <div v-else key="full">
              <span v-html="full_note(note)" />

              <template v-if="note.full_text && note.full_text.note_properties">
                <br />
                <small>
                  <template v-if="note.full_text.note_properties.author">
                    {{ note.full_text.note_properties.author }}
                  </template>
                  <template v-if="note.full_text.note_properties.date">
                    <br />
                    {{ note.full_text.note_properties.date }}
                  </template>
                </small>
              </template>
            </div>
          </transition>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- <small>pas de notice pour le moment.</small> -->
    </template>
  </div>
</template>
<script>
import { marked } from "marked";

export default {
  props: {
    notes: Array,
    main_text: String,
    collapsed_by_default: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      show_notice: !this.collapsed_by_default,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    extract(note) {
      return marked.parse(note.extract);
    },

    full_note(note) {
      return marked.parse(note.full_text.content);
    },

    readFullNotice(id) {
      // /navigart/notes/4659
      if (this.$root.is_debug) {
        // this.$toast(`fetching notice ${id}`);
      }

      this.fetchPath({ path: `/navigart/notes/${id}` }).then((response) => {
        if (response.content.startsWith("\n"))
          response.content = response.content.substring(1);
        this.$set(
          this.notes.find((n) => n.id === id),
          "full_text",
          response
        );
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
