<template>
  <div class="m_albumpreview">
    <div
      class="m_albumpreview--images"
      :class="{
        'has--noImage': !album.artworks || album.artworks.length === 0,
      }"
    >
      <div v-for="(a, index) in new Array(4).fill(undefined)" :key="index">
        <PreviewOeuvre
          v-if="previews[index]"
          :oeuvre="previews[index]"
          :show_quick_actions="false"
          :thumb_resolution="200"
          :show_title="false"
        />
      </div>
    </div>
    <div class="m_albumpreview--texts">
      <div
        class="_albumName"
        v-html="$root.highlightSearchedTerm(album.title)"
      />
      <div
        class="_albumAuthor"
        v-if="album.username && show_author"
        v-html="`par ` + album.username"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    album: Object,
    show_author: Boolean,
  },
  components: {},
  data() {
    return {
      previews: [],
    };
  },
  created() {},
  mounted() {
    const first_four_artworks_ids = this.album.artworks.slice(0, 4);
    this.fetchArtworks({
      ids: first_four_artworks_ids,
      content: ["cover"],
    }).then((artworks) => {
      this.previews = artworks;
    });
    first_four_artworks_ids;
  },
  beforeDestroy() {},
  watch: {},
  computed: {
    // previews() {
    //   let _previews = new Array(4).fill(undefined);
    //   if (this.album.artworks) {
    //     _previews.map((p, index) => {
    //       if (this.album.artworks.length >= index) {
    //         let artworks_id = this.album.artworks[index];
    //         if (artworks_id.startsWith("artworks"))
    //           artworks_id = artworks_id.replace("artworks/", "");
    //         _previews[index] = artworks_id;
    //       }
    //     });
    //   }
    //   return _previews;
    // },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_albumpreview {
  // display: flex;
  // flex-flow: row wrap;
  // padding: calc(var(--spacing));

  // margin-right: 2em;
}
.m_albumpreview--images {
  position: relative;
  width: 16rem;
  height: 16rem;
  aspect-ratio: 1;

  overflow: visible;
  // border: 1px solid grey;
  // flex: 0 0 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: calc(var(--spacing) / 8);

  pointer-events: none;

  &.has--noImage {
    background: var(--gray-background);
  }

  > * {
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 4px;
    background-color: var(--gray-background);

    ::v-deep {
      ._loader {
        height: auto !important;
      }
      * {
        height: 100%;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  ::v-deep {
    ._loader {
      height: 4em !important;
      margin: 33.33%;
    }
  }
}

.m_albumpreview--texts {
  text-align: left;
  margin: 0.5em 0;
  // margin-left: 3em;
}

._albumName {
  text-decoration: none;
}
</style>
