<template>
  <Modal @close="closeVerify">
    <template v-slot:header>
      <h3>Vérification du compte</h3>
    </template>
    <template v-slot:body>
      <div v-if="is_loading" class="_loader" key="loader">
        Chargement…
      </div>

      <div v-else>
        <!-- {{ user_id }}
        <br />
        {{ verification_token }} -->
        {{ message }}<br />

        <div v-if="response">
          <br />
          Information sur l’erreur à nous communiquer :
          <span v-html="response" />
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "../components/subcomponents/Modal.vue";

export default {
  props: {},
  components: { Modal },
  data() {
    return {
      is_loading: true,

      user_id: this.$route.params.userID,
      verification_token: this.$route.params.token,

      message: "",
      response: false,
    };
  },
  created() {},
  mounted() {
    this.submitVerificationToken();
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    submitVerificationToken() {
      const path = `/users/verify/${this.user_id}/${this.verification_token}`;

      this.fetchPath({
        path,
      }).then((response) => {
        if (response === "verified") {
          this.message = `Votre compte a été vérifié ! Tu peux maintenant choisir une œuvre pour le prochain accrochage.`;
        } else {
          this.response = response;
          this.message = `
            Votre compte n’a pas pu être vérifié, merci 
            de nous contacter pour résoudre ce soucis 
            <a href="mailto:reserves@fraciledefrance.com">reserves@fraciledefrance.com</a>
          `;
        }

        this.is_loading = false;
      });
    },
    closeVerify() {
      this.$router.replace({
        path: `/mon-compte`,
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
