<template>
  <Teleport to="#app">
    <transition name="fade-fast">
      <div class="modal" :class="[type]" role="dialog">
        <div class="modal-overlay" @click="$emit('close')" />
        <div class="modal-inner">
          <div class="modal-header" v-if="$slots.header">
            <div>
              <slot name="header"></slot>
            </div>
          </div>
          <button
            class="modal-close"
            @click="$emit('close')"
            aria-label="Close"
          >
            &times;
          </button>

          <div class="_body">
            <slot name="body"></slot>
          </div>

          <div class="_buttons" v-if="$slots.buttons">
            <slot name="buttons"></slot>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
<script>
import Teleport from "vue2-teleport";

export default {
  name: "Modal",
  props: {
    type: String,
  },
  components: {
    Teleport,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    document.addEventListener("keydown", this.escClose);
    this.$root.modal_is_open = true;
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.escClose);
    this.$root.modal_is_open = false;
  },
  watch: {},
  computed: {},
  methods: {
    escClose(e) {
      if (e.keyCode == 27) this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  align-items: center;
  justify-content: center;
  position: fixed;
  display: flex;

  overflow: auto;

  inset: 0;
  z-index: 1001;
  padding: 20px;
}
.modal-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 0;
  background-color: white;
  // background-color: black;
  background-color: rgba(241, 241, 241, 1);
  opacity: 0.95;
}

.modal-inner {
  position: relative;
  background: white;

  width: var(--modal-maxwidth);
  max-width: 100%;
  margin: auto;

  > * {
    margin: calc(var(--spacing) * 1) calc(var(--spacing) * 1);
  }

  .modal.full_screen & {
    width: auto;
  }
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing) / 2);
  }
  // border-bottom: 2px solid black;
}

._body {
  ::v-deep > * {
    margin: calc(var(--spacing) * 1) 0;
  }
}

._buttons {
  // padding: calc(var(--spacing) / 2);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: calc(var(--spacing) / 1);
}

.modal-close {
  position: absolute;
  left: 100%;
  top: 0;
  width: 1.5em;
  height: 1.5em;
  font-size: 2em;
  border-radius: 50%;
  margin: 0;
  background: transparent;

  .modal.full_screen & {
    right: 0;
    left: auto;
  }
}
</style>
