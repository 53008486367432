<template>
  <Modal @close="$emit('close')">
    <template v-slot:body>
      <div v-if="$root.is_logged_in === false">
        <p
          v-html="
            `
        Pour choisir une œuvre que tu souhaites voir exposée, tu dois créer un
        compte pour conserver ta sélection et pour qu’on te tienne informé.e dès
        que l’œuvre sera présentée. Ça&nbsp;te permet aussi de conserver le ou les
        albums que tu as créés&nbsp;!`
          "
        />

        <CreateOrLogin />
      </div>
      <div v-else>
        <template v-if="!$root.user_login.content.artwork_id">
          <div class="oeuvreCard">
            <PreviewOeuvre
              :id="artwork.slug"
              :oeuvre="artwork"
              :show_quick_actions="false"
              :thumb_resolution="400"
            />
          </div>
          <p
            v-html="
              `
        Veux-tu choisir cette œuvre pour qu’elle ai une chance de faire partie du prochain accrochage ?
        `
            "
          />
        </template>
        <template v-else>
          <template v-if="$root.user_login.content.artwork_id === artwork.slug">
            <div class="oeuvreCard">
              <PreviewOeuvre
                :id="artwork.slug"
                :oeuvre="artwork"
                :show_quick_actions="false"
                :thumb_resolution="400"
              />
            </div>
            <p>
              Tu as déjà choisi cette œuvre !
            </p>
          </template>
          <template v-else>
            <p>
              Tu as déjà choisi une oeuvre mais tu as changé d’avis ?
            </p>

            <div class="_replaceArtwork">
              <div class="oeuvreCard">
                <StatusPastille
                  class="_presentation--status"
                  :status="'nouveau'"
                />
                <PreviewOeuvre
                  :id="artwork.slug"
                  :oeuvre="artwork"
                  :show_quick_actions="false"
                  :thumb_resolution="200"
                />
              </div>
              <div class="_mention">
                <span>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="15.75px"
                    height="12px"
                    viewBox="0 0 42 32.1"
                    style="overflow:visible;enable-background:new 0 0 42 32.1;transform: rotate(270deg);"
                    xml:space="preserve"
                  >
                    <polygon
                      class="st0"
                      style="fill: currentColor;"
                      points="42,19.4 42,12.7 13.8,12.7 11.1,13 13.6,11.3 20.9,4.8 20.9,0 16,0 0,16.1 16,32.1 20.7,32.1
  	20.8,27.2 13.6,20.7 11.1,18.9 13.8,19.4 "
                    />
                  </svg>
                  &nbsp;
                  <strong>remplacera</strong>
                  &nbsp;
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="15.75px"
                    height="12px"
                    viewBox="0 0 42 32.1"
                    style="overflow:visible;enable-background:new 0 0 42 32.1;transform: rotate(270deg);"
                    xml:space="preserve"
                  >
                    <polygon
                      class="st0"
                      style="fill: currentColor;"
                      points="42,19.4 42,12.7 13.8,12.7 11.1,13 13.6,11.3 20.9,4.8 20.9,0 16,0 0,16.1 16,32.1 20.7,32.1
  	20.8,27.2 13.6,20.7 11.1,18.9 13.8,19.4 "
                    />
                  </svg>
                </span>
              </div>
              <div class="oeuvreCard _old">
                <StatusPastille
                  class="_presentation--status"
                  :status="'ancien'"
                />
                <PreviewOeuvre
                  :id="$root.user_login.content.artwork_id"
                  :show_quick_actions="false"
                  :thumb_resolution="200"
                />
              </div>
            </div>
          </template>
        </template>

        <div class="fillField">
          <label for="ARTWORK_MSG">
            Les raisons de ton choix
          </label>
          <textarea
            id="ARTWORK_MSG"
            name="ARTWORK_MSG"
            class=""
            v-model="new_artwork_message"
          />
          <small class=""
            >Si tu veux, tu peux nous dire ici pourquoi tu choisis cette œuvre !
            Et si ton œuvre est choisie, ton message sera peut-être affiché aux
            autres visiteurs.
          </small>
        </div>

        <div class="fillField">
          Un mail te sera envoyé après le tirage au sort pour te dire si cette
          œuvre est retenue ou pas.
        </div>

        <!-- <div class="fillField">
          <label for="NEWSLETTER">
            <input
              type="checkbox"
              id="NEWSLETTER"
              name="NEWSLETTER"
              v-model="new_artwork_newsletter"
            />
            M’envoyer un mail pour me dire si mon œuvre est retenue
          </label>
        </div> -->
      </div>
    </template>
    <template v-slot:buttons v-if="$root.is_logged_in">
      <button type="button" @click="$emit('close')">
        Annuler
      </button>
      <button
        type="button"
        v-if="
          $root.user_login.content &&
            $root.user_login.content.artwork_id === artwork.slug
        "
        class="redBtn"
        :class="{
          'is--saving': is_saving,
        }"
        :disabled="is_saving"
        @click="unselectArtwork"
      >
        Retirer mon choix
      </button>

      <button
        type="button"
        v-else
        class="greenBtn"
        :class="{
          'is--saving': is_saving,
        }"
        :disabled="is_saving"
        @click="selectArtwork"
      >
        <div v-if="is_saving" class="_loader" key="loader">
          Chargement…
        </div>
        Valider
      </button>
    </template>
  </Modal>
</template>
<script>
import Modal from "../subcomponents/Modal.vue";
import CreateOrLogin from "./CreateOrLogin.vue";

export default {
  props: {
    artwork: Object,
  },
  components: {
    CreateOrLogin,
    Modal,
  },
  data() {
    return {
      is_saving: false,
      new_artwork_message: this.$root.user_login.content.artwork_message,
      // new_artwork_newsletter: this.$root.user_login.content.artwork_newsletter,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    selectArtwork() {
      this.is_saving = true;

      const data = {
        artwork_id: this.artwork.slug,
        artwork_message: this.new_artwork_message,
        // artwork_newsletter: this.new_artwork_newsletter,
      };
      this.updateCurrentUserInfos({ data })
        .then(() => {
          this.is_saving = false;
          // redirect to my account page
          this.$router.push({
            path: `/mon-compte`,
          });
        })
        .catch((err) => console.log(err));
    },
    unselectArtwork() {
      this.is_saving = true;

      const data = {
        artwork_id: "",
        artwork_message: "",
      };
      this.updateCurrentUserInfos({ data })
        .then(() => {
          this.is_saving = false;
          this.$emit("close");

          this.$toast.success(`L’œuvre a été retiré de votre choix.`);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss" scoped>
._replaceArtwork {
  // display: flex;
  // text-align: center;
  // justify-content: center;

  > * {
    flex: 1 1 0;
  }

  // ::v-deep .m_oeuvre--content--image {
  //   flex: 0 0 100%;
  // }
  // border: 2px solid var(--dark-gray);
  // padding: calc(var(--spacing) * 1);
}
._mention {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: calc(var(--spacing) / 2);

  > span {
    // background: var(--dark-gray);
    // color: white;

    border-radius: 1em;
    // padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);
  }

  svg {
    // margin-left: calc(var(--spacing) / 2);
  }
}

._old {
  // filter: grayscale(50%);
}
</style>
