<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="is_loading">
        <div class="_loader" key="loader">
          Chargement…
        </div>
      </div>
      <div v-else>
        <div class="contentBloc limitedWidth">
          <h1 class="sectionTitle">
            {{ content.title }}
          </h1>
          <h2 class="sectionSubtitle" v-if="content.subtitle">
            {{ content.subtitle }}
          </h2>
          <div
            class="_selectionPeriod"
            v-if="$root.selection_start_date && $root.selection_end_date"
          >
            Période de sélection : <br />
            du
            <time
              :datetime="
                $moment($root.selection_start_date).format(
                  'YYYY-MM-DDThh:mm:ssTZD'
                )
              "
              v-html="
                $moment($root.selection_start_date)
                  .format('LLLL')
                  .toLowerCase()
              "
            />
            <br />
            au
            <time
              :datetime="
                $moment($root.selection_end_date).format(
                  'YYYY-MM-DDThh:mm:ssTZD'
                )
              "
              v-html="
                $moment($root.selection_end_date)
                  .format('LLLL')
                  .toLowerCase()
              "
            />
          </div>

          <div v-html="content.text" class="mainText" />
        </div>

        <div class="sectionSeparator" />

        <section class="_accrochages">
          <h1 class="sectionTitle">
            Derniers accrochages
          </h1>

          <template v-for="accrochage in past_accrochages">
            <AccrochagePreview
              :ref="`accrochage-${accrochage.slug}`"
              :key="accrochage.slug"
              :accrochage="accrochage"
            />
            <div
              class="sectionSeparator"
              :key="`separator-${accrochage.slug}`"
            />
          </template>
        </section>
      </div>
    </transition>
  </div>
</template>
<script>
import AccrochagePreview from "../components/AccrochagePreview.vue";

export default {
  props: {},
  components: {
    AccrochagePreview,
  },
  data() {
    return {
      content: {
        title: "",
        subtitle: "",
        text: "",
      },
      accrochages: [],
      scroll_to_accrochage: false,
      is_loading: true,
    };
  },
  created() {},
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.accrochage &&
      this.$route.query.accrochage !== "false"
    ) {
      this.scroll_to_accrochage = this.$route.query.accrochage;
    }

    this.fetchAllExhibitions().then(({ content, exhibitions }) => {
      this.is_loading = false;

      this.content.title = content.title;
      this.content.subtitle = content.subtitle;
      this.content.text = content.text;

      this.accrochages = exhibitions;

      if (this.scroll_to_accrochage) {
        // waiting for individual accrochages to load
        setTimeout(() => {
          this.scrollToAccrochage(this.scroll_to_accrochage);
        }, 500);
      }
    });
  },
  beforeDestroy() {},
  watch: {},
  computed: {
    past_accrochages() {
      return this.accrochages;
    },
  },
  methods: {
    scrollToAccrochage(name) {
      const $name = this.$refs[`accrochage-${name}`];
      if ($name) {
        const ot = $name[0].$el.offsetTop;
        // const oh = $yr[0].offsetHeight;
        // const scroll_window_height = 0;

        const scroll_value = ot + 1;
        console.log(
          `ACCROCHAGES / methods • scrollToAccrochage will scroll to = ${scroll_value}`
        );

        window.scrollTo(0, scroll_value);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
._accrochages {
  // margin-top: calc(var(--spacing) * 5);

  ::v-deep .m_accrochagePreview {
    margin-bottom: calc(var(--spacing) * 4);
  }
}

._selectionPeriod {
  margin: calc(var(--spacing) * 2) 0;
  text-align: center;

  time {
    font-weight: bold;
    padding: 0 4px;
  }
}
</style>
