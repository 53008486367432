<template>
  <div class="m_compte">
    <transition-page>
      <router-view class="_pageContent" :key="$route.path" />
    </transition-page>
    <div class="contentBloc limitedWidth">
      <template v-if="$root.is_logged_in === false">
        <h1 class="sectionTitle">
          Mon compte
        </h1>
        <h2 class="sectionSubtitle">
          crée un compte pour participer au prochain accrochage !
        </h2>

        <div class="sectionSeparator" />

        <template v-if="$root.is_login_as">
          <div class="_loader" key="loader">…</div>
        </template>
        <CreateOrLogin v-else />
      </template>
      <UserPage v-else />
    </div>
  </div>
</template>
<script>
import CreateOrLogin from "../components/user/CreateOrLogin.vue";
import UserPage from "../components/user/UserPage.vue";
import TransitionPage from "../transitions/TransitionPage.vue";

export default {
  props: {},
  components: {
    CreateOrLogin,
    UserPage,
    TransitionPage,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
