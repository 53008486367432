export default {
  computed: {},
  methods: {
    fetchArtworks({
      size = 8,
      shuffle = false,
      from = 0,
      only_with_images = false,
      extend = "children",
      content = [],
      id = false,
      ids = false,
    } = {}) {
      return new Promise((resolve, reject) => {
        //   size = 6,
        //   random = false,
        //   has_image = false,
        //   extended = false

        let path = `/artworks`;
        let params = new URLSearchParams();

        if (id) {
          path += `/${id}`;
        } else if (ids) {
          path += `/${ids.join(",")}`;
        } else {
          if (size) params.set("size", size);
          if (shuffle) params.set("shuffle", shuffle);
          if (only_with_images)
            params.set("only_with_images", only_with_images);
          if (from > 0) params.set("from", from);
        }

        if (extend) params.set("extend", extend);
        if (content.length > 0) params.set("content", content.join(","));

        path += `?${params.toString()}`;

        console.log(
          `ROOT / fetchArtworks • fetching = ${JSON.stringify(path, null, 4)}`
        );

        if (this.$root.is_debug) {
          if (id || ids.length === 1) {
            // this.$toast(`fetching one artwork`);
          } else {
            // this.$toast(`fetching artworks`);
          }
        }

        // fetch(`https://api.stg.navigart.fr/39/artworks/${this.id}`, {
        this.fetchPath({
          path,
        })
          .then((response) => {
            // single AW
            if (id || ids.length === 1) {
              let artwork = this.prepareArtwork({ artwork: response });
              return resolve([artwork]);
            } else {
              const aw =
                ids.length > 1 ? response : Object.values(response.children);
              let artworks = aw.map((artwork) =>
                this.prepareArtwork({ artwork })
              );
              return resolve(artworks);
            }
          })
          .catch((err) => {
            console.log(
              `ROOT / fetchArtworks • fetching failed with error ${err}`
            );
            return reject(err);
          });
      });
    },
    prepareArtwork({ artwork }) {
      // artwork._images = artwork.navigartData._source.ua.artwork.medias;
      // artwork._cover = artwork._images[0];
      // artwork._year = artwork.navigartData._source.ua.artwork.date_creation;

      // let authors =
      //   artwork.authors && artwork.authors.length > 0
      //     ? artwork.authors.map((a) => a.name.list)
      //     : [];
      // if (authors.length > 0) artwork._authors = authors;

      const _artwork = {};
      _artwork.ogData = artwork;

      if (artwork.data?.title) _artwork.title = artwork.data.title;
      if (artwork.files) _artwork.files = Object.values(artwork.files);

      if (artwork.content?.cover) _artwork.cover = artwork.content.cover.value;

      if (artwork.content?.navigart?._source?.ua?.artwork?.date_creation)
        _artwork.year =
          artwork.content.navigart._source.ua.artwork.date_creation;
      if (artwork.data?.slug) _artwork.slug = artwork.data.slug;

      if (artwork.content?.accrochages && artwork.content.accrochages.value)
        _artwork.accrochages = artwork.content.accrochages.value;
      if (
        artwork.content?.preventselection &&
        artwork.content.preventselection.boolean
      )
        _artwork.preventselection =
          artwork.content.preventselection.boolean === "true";

      if (artwork.content?.reason && artwork.content.reason.value)
        _artwork.reason = artwork.content.reason.value;

      if (artwork.content?.date_creation)
        _artwork.date_creation = artwork.content.date_creation.value;
      if (artwork.content?.acquisition_year)
        _artwork.acquisition_year = artwork.content.acquisition_year.value;

      if (artwork.content?.recap_description)
        _artwork.recap_description = artwork.content.recap_description.value;

      if (artwork.content?.artwork_type)
        _artwork.artwork_type = artwork.content.artwork_type.value;
      if (artwork.content?.ensemble_artworks)
        _artwork.ensemble_artworks = artwork.content.ensemble_artworks.value;

      if (artwork.content?.recap_title)
        _artwork.recap_title = artwork.content.recap_title.value;

      if (artwork.content?.tirage_photo)
        _artwork.tirage_photo = artwork.content.tirage_photo.value;
      if (artwork.content?.tirage)
        _artwork.tirage = artwork.content.tirage.value;

      if (artwork.content?.medium)
        _artwork.medium = artwork.content.medium.value;
      if (artwork.content?.domain_description_mst)
        _artwork.domain_description_mst = artwork.content.domain_description_mst.value.replace(
          /(?:\r\n|\r|\n)/g,
          "<br />"
        );

      if (artwork.content?.dimensions)
        _artwork.dimensions = artwork.content.dimensions.value;

      if (artwork.content?.dimensions_additional)
        _artwork.dimensions_additional = artwork.content.dimensions_additional.value.replace(
          /(?:\r\n|\r|\n)/g,
          "<br />"
        );

      if (artwork.content?.authors)
        _artwork.authors = artwork.content.authors.value;

      if (artwork.content?.main_text?.blocksToHtml)
        _artwork.main_text = artwork.content.main_text.blocksToHtml;

      if (artwork.content?.text_notes?.value?.artwork)
        _artwork.oeuvres_notes = artwork.content.text_notes.value.artwork;
      else if (artwork.content?.text_notes?.value?.header_artwork)
        _artwork.oeuvres_notes =
          artwork.content.text_notes.value.header_artwork;

      if (artwork.content?.text_notes?.value?.artist)
        _artwork.artist_notes = artwork.content.text_notes.value.artist;

      // if (artwork.content?.related)
      //   _artwork.albums = artwork.content.related.yaml.reduce((acc, r) => {
      //     if (r.startsWith("albums"))
      //       acc.push(r.replace("albums/", ""));
      //     return acc;
      //   }, []);

      if (artwork.content?.navigarturl)
        _artwork.navigarturl = artwork.content.navigarturl.value;

      return _artwork;
    },
  },
};
